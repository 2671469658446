import {Component, Input} from '@angular/core';
import {SkillConfigEntry, SkillStats} from '@game/stats/stat-data';

@Component({
  selector: 'wg-skill-stat',
  styleUrls: ['./stats.less', './skill-stat.component.less'],
  templateUrl: './skill-stat.component.html',
})
export class SkillStatComponent {
  @Input() stats: null | SkillStats = null;
  @Input() source: null | SkillConfigEntry = null;
  @Input() type: string = 'all skills';
  @Input() suffix: string = '';
  @Input() italic: boolean = false;

  // Hides the levels info if its does not make any sense.
  @Input() showLevels: boolean = true;
}
