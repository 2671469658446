import * as PIXI from 'pixi.js';
import {getNearestTierTexture, Ship} from '@game/ships/ship';
import {HullSize, ShipGameObject} from '@game/ships/ship.game-object';
import {ExhaustGameObject} from '@game/ships/parts/exhaust.game-object';
import {ShipCreateOptions} from '@game/ships/ship.factory';
import {gsap, Power2} from 'gsap';

export class CruiserOcelotGameObject extends ShipGameObject {

  constructor(ship: Ship, opts: ShipCreateOptions) {
    super(ship, opts);

    this.loadStructure({
      type: 'body',
      config: {
        asset: 'body_01_variant_01',
        x: 0,
        y: -2,
      },
      children: [{
        type: 'exhaust',
        config: {
          track: this.ship,
          placement: 0,
          x: 2,
          y: 40,
          scale: 2.5,
        }
      }, {
        type: 'drive',
        config: {
          asset: 'drive_11_r',
          x: 0,
          y: 25,
          scale: 1.2,
        }
      },{
        type: 'wing',
        config: {
          asset: 'wing_r_07',
          x: 5,
          y: 11,
        },
      }, {
        type: 'wing',
        config: {
          asset: 'wing_r_07',
          x: -5,
          y: 11,
          scaleX: -1,
        }
      }]
    });

    // const shipTexture = getNearestTierTexture(ship.getHighestModuleTier(), 'ships', 'cruiser_ocelot_t%.png')
    // if (shipTexture) {
    //   const sprite = new PIXI.Sprite(shipTexture);
    //   sprite.x -= 100;
    //   this.addChild(sprite);
    // }
  }

  getSize(): number {
    return HullSize.Small;
  }
}
