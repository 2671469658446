import {Component} from '@angular/core';
import {InteractionQuestData, InteractionService, LoreData} from './interaction.service';
import {MapService} from '../../../services/map.service';

@Component({
  selector: 'wg-interaction-container',
  styleUrls: ['interaction-container.component.less'],
  templateUrl: 'interaction-container.component.html',
})
export class InteractionContainerComponent {

  constructor(public svc: InteractionService,
              public mapService: MapService) {
  }

  showQuest(quest: InteractionQuestData): void {
    this.svc.showQuestIntro(quest.questId);
  }

  showQuestSubmit(quest: InteractionQuestData): void {
    this.svc.showQuestOutro(quest.questId);
  }

  close(): void {
    this.svc.stopInteraction();
  }

  returnToMain(): void {
    this.svc.returnToMainPage();
  }

  submitQuest(): void {
    if (!this.svc.questReceiverData) {
      throw new Error('cannot submit: no quest receiver active');
    }
    this.svc.submitQuest(this.svc.questReceiverData.questId);
  }

  showLore(lore: LoreData): void {
    throw new Error('not implemented');
  }

  loadItemTrader(): void {
    this.svc.loadItemTrader();
  }

  loadHullTrader(): void {
    this.svc.loadHullTrader();
  }

  loadHangar(): void {
    this.svc.loadHangar();
  }

  acceptQuest(): void {
    if (!this.svc.activeQuestId) {
      throw new Error('cannot accept quest: no selected quest');
    }
    this.svc.acceptQuest(this.svc.activeQuestId);
  }

  useBridge(): void {
    this.svc.useBridge();
  }
}
