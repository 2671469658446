import {Component, Input} from '@angular/core';
import {StatData, StatGenerator} from '@game/stats/stat-data';

@Component({
  selector: 'wg-stat',
  styleUrls: ['./stats.less'],
  templateUrl: './stat.component.html',
})
export class StatComponent {
  @Input() stats: null | StatData = null;
  @Input() source: null | StatGenerator = null;
  @Input() suffix: string = '';
  @Input() showLocal: boolean = true;
}
