import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class ConfigService implements HttpInterceptor {

  // FIXME - Use service discovery or external configuration
  idmUrl: string = (window as any).__config.idmUrl;
  galaxyUrl: string = (window as any).__config.galaxyUrl;


  /**
   * Rewrite all request with the correct gateway address
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let newUrl = req.url;

    // Replace placeholders.

    newUrl = newUrl.replace('{idm}', this.idmUrl);
    newUrl = newUrl.replace('{galaxy}', this.galaxyUrl);


    // Prefix with galaxy url if none is given.
    if (!newUrl.startsWith('http') && !newUrl.startsWith('ws')) {
      newUrl = this.galaxyUrl + newUrl;
    }

    console.log('Intercept URL: ', req.url, newUrl);

    req = req.clone({
      url: newUrl,
    });

    return next.handle(req);
  }

}
