import * as PIXI from 'pixi.js';
import {Ship} from '@game/ships/ship';
import {HullSize, ShipGameObject} from '@game/ships/ship.game-object';
import {ExhaustGameObject} from '@game/ships/parts/exhaust.game-object';
import {ShipCreateOptions} from '@game/ships/ship.factory';

export class CutterBumblebeeGameObject extends ShipGameObject {

  constructor(ship: Ship, opts: ShipCreateOptions) {
    super(ship, opts);

    this.loadStructure({
      type: 'body',
      config: {
        asset: 'body_07',
        x: 0,
        y: -10,
        scale: 1,
      },
      children: [{
        type: 'wing',
        config: {
          asset: 'wing_r_14',
          x: 2,
          y: 0,
        },
      }, {
        type: 'wing',
        config: {
          asset: 'wing_r_14',
          x: -2,
          y: 0,
          flipX: true,
        }
      }, {
        type: 'drive',
        config: {
          asset: 'drive_12',
          x: 0,
          y: 15,
        },
        children: [{
          type: 'exhaust',
          config: {
            track: this.ship,
            placement: -1,
            x: -7,
            y: 37,
            scale: 1.5,
          }
        }, {
          type: 'exhaust',
          config: {
            track: this.ship,
            placement: 1,
            x: 7,
            y: 37,
            scale: 1.5,
          }
        }]
      }]
    });

    // const res = PIXI.Loader.shared.resources;
    // // @ts-ignore
    // const sprite = new PIXI.Sprite(res.ships.textures['cutter_bumblebee.png']);
    // sprite.x = -100;
    // this.addChild(sprite);
  }

  getSize(): number {
    return HullSize.Tiny;
  }
}
