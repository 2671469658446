import {Component, Input} from '@angular/core';
import {StatModifier} from '@game/ships/ship';

@Component({
  selector: 'wg-stat-modifier',
  styleUrls: ['./stats.less'],
  templateUrl: './stat-modifier.component.html',
})
export class StatModifierComponent {
  @Input() statModifier: null | StatModifier = null;
}
