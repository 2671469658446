import {Ship} from '@game/ships/ship';
import * as PIXI from 'pixi.js';
import {Back, gsap, Power2, Power4} from 'gsap';
import {OutlineFilter} from '@pixi/filter-outline';
import {Rarity} from '../../../projects/game/src/app/hud/shared/stats/rarity';
import {EffectManager} from '@game/vfx/effect.manager';
import {ExhaustGameObject} from '@game/ships/parts/exhaust.game-object';
import {Vector2da} from '@shared/utils/vector2da';
import {ShipCreateOptions} from '@game/ships/ship.factory';
import BlurFilter = PIXI.filters.BlurFilter;
import {EntityLayer} from '@game/entities/map';
import ColorMatrixFilter = PIXI.filters.ColorMatrixFilter;

export enum HullSize {
 Tiny = 1,
 Small = 1.2,
 Normal = 1.4,
 Large = 1.8,
 VeryLarge = 2.6,
 Huge = 3.5,
}

export interface ShipStructure {
  type: string;
  config: any;
  children?: ShipStructure[];
}

export abstract class ShipGameObject extends PIXI.Container {

  protected seed: number;
  private time: number = 0;
  private hitAnim: null | gsap.core.Tween = null;
  private hitAnimRev: null | gsap.core.Tween = null;
  private rescaleAnim: null | gsap.core.Tween = null;

  private exhausts: ExhaustGameObject[] = [];
  private trackedEntity: PIXI.DisplayObject | null = null;
  private lastEntityPosition!: Vector2da;
  private baseScale: number = 1;
  private dieTimeline: gsap.core.Timeline | null = null;

  constructor(public ship: Ship, protected opts: ShipCreateOptions) {
    super();

    this.seed = Math.random();

    if (this.ship) {
      this.ship.afterDestroyed().subscribe(() => {
        this.die();
      });
    }

    if (this.ship.rarity > Rarity.Common) {

      let color = 0x3F82FF;
      if (this.ship.rarity === Rarity.Legendary) {
        color = 0xFFB814;
      }

      const filters = new OutlineFilter(4, color);
      this.filters = [filters];
    }

    // this.filters = [filterForFaction(ship.faction)];

    if (this.ship.shipId) {
      EffectManager.instance.registerEntity(this.ship.shipId, this);
    }
  }

  /**
   * Update the ships state
   */
  public update(delta: number): void {
    this.time += delta;
    if (!this.rescaleAnim) {
      this.scale.set(this.baseScale + Math.sin(this.seed + (this.time / 800)) * 0.04);
    }

    this.updateExhausts(delta);
  }

  updateExhausts(delta: number): void {
    // Track the entity movement.
    if (!this.trackedEntity) {
      return;
    }

    const diff: Vector2da = {
      x: this.lastEntityPosition?.x - this.trackedEntity.position.x,
      y: this.lastEntityPosition?.y - this.trackedEntity.position.y,
      angle: this.lastEntityPosition?.angle - this.trackedEntity.angle % 360,
    };

    if (!diff.x && !diff.y && !diff.angle) {
      this.exhausts.forEach(e => e.reducePower(delta));
      return;
    }

    // Calculate the movement orientation to interact with the exhausts.
    let orientation = 0;

    if (diff.angle < 0) {
      orientation = -1;
    } else if (diff.angle > 0) {
      orientation = 1;
    }

    if (Math.abs(diff.x) + Math.abs(diff.y) > 0) {
      orientation = orientation * 0.5;
    }

    this.exhausts.forEach(e => e.enhancePower(orientation));
    // SoundManager.instance.playSound('sfx_gunmech_scope_activate_09');

    this.lastEntityPosition = {
      x: this.trackedEntity.position.x,
      y: this.trackedEntity.position.y,
      angle: this.trackedEntity.angle,
    };

  }

  destroy(options?: { children?: boolean; texture?: boolean; baseTexture?: boolean }): void {
    if (this.ship.shipId) {
      EffectManager.instance.removeEntity(this.ship.shipId);
    }

    if (this.dieTimeline) {
      this.dieTimeline.kill();
      this.dieTimeline = null;
    }

    if (this.hitAnim) {
      this.hitAnim.kill();
      this.hitAnim = null;
    }

    if (this.hitAnimRev) {
      this.hitAnimRev.kill();
      this.hitAnimRev = null;
    }

    if (this.rescaleAnim) {
      this.rescaleAnim.kill();
      this.rescaleAnim = null;
    }

    super.destroy(options);
  }

  public playHit(): void {
    if (this.hitAnim) {
      return;
    }

    const oldX = this.x;
    const oldY = this.y;

    const recoilAngle = this.angle + 180;
    const offsetX = Math.sin((recoilAngle / 180) * Math.PI);
    const offsetY = Math.cos((recoilAngle / 180) * Math.PI);

    this.hitAnim = gsap.to(this, {
      x: oldX + 5 * offsetX,
      y: oldY + 5 * offsetY,
      ease: Power4.easeInOut,
      duration: 0.2,
      onComplete: () => {
        this.hitAnimRev = gsap.to(this, {
          x: oldX,
          y: oldY,
          ease: Back.easeOut,
          duration: 0.5,
          onComplete: () => {
            // Release the animation and allow another recoil.
            this.hitAnim = null;
          }
        });
      }
    });
  }

  loadStructure(structure: ShipStructure): void {
    if (structure.children) {
      structure.children.forEach((child) => {
        this.loadStructure(child);
      });
    }

    let textures;
    let sprite;
    let obj;
    switch(structure.type) {
      case 'body':
        textures = PIXI.Loader.shared.resources['body-ehe-0'].textures || {};
        break;

      case 'drive':
        textures = PIXI.Loader.shared.resources['drive-ehe-0'].textures || {};
        break;

      case 'weapon':
        textures = PIXI.Loader.shared.resources['drive-ehe-0'].textures || {};
        break;

      case 'wing':
        textures = PIXI.Loader.shared.resources['wing-ehe-0'].textures || {};
        break;

      case 'exhaust':
        obj = new ExhaustGameObject(structure.config);
        this.addExhaust(obj);
        return;
    }

    if (textures) {
      sprite = new PIXI.Sprite(textures[structure.config.asset]);
      sprite.x = structure.config.x || 0;
      sprite.y = structure.config.y || 0;
      sprite.rotation = structure.config.rotation || 0;
      sprite.scale.x = structure.config.scaleX || structure.config.scale || 1;
      if (structure.config.flipX) {
        sprite.scale.x *= -1;
      }

      sprite.scale.y = structure.config.scaleY || structure.config.scale || 1;
      if (structure.config.flipY) {
        sprite.scale.y *= -1;
      }

      this.addChild(sprite);
    }
  }

  addExhaust(ex: ExhaustGameObject): void {
    this.exhausts.push(ex);
    this.addChild(ex);
  }

  track(entity: PIXI.DisplayObject): void {
    this.trackedEntity = entity;
    this.lastEntityPosition = {
      x: this.trackedEntity.position.x,
      y: this.trackedEntity.position.y,
      angle: this.trackedEntity.angle,
    };
  }

  updateScale(shipSizeRef: number, animDuration: number = 0): void {
    if (!this.ship) {
      return;
    }

    const targetScale = this.getSize() / shipSizeRef;

    if (!animDuration) {
      this.scale.set(targetScale);
      this.baseScale = targetScale;
    } else {
      if (this.rescaleAnim) {
        this.rescaleAnim.kill();
        this.rescaleAnim = null;
      }
      this.rescaleAnim = gsap.to(this.scale, {
        x: targetScale,
        y: targetScale,
        duration: animDuration,
        ease: Power2.easeInOut,
        onComplete: () => {
          this.baseScale = targetScale;
        }
      });
    }
  }

  getSize(): number {
    return HullSize.Normal;
  }

  die(): void {

    if (this._destroyed) {
      return;
    }

    for (let exhaust of this.exhausts) {
      exhaust.destroy({
        children: true,
      });
    }

    this.dieTimeline = gsap.timeline({
      onComplete: () => {
        this.dieTimeline?.kill();
        this.dieTimeline = null;
      }
    });

    for (const child of this.children) {

      if (!(child instanceof ExhaustGameObject)) {

        const targetX = child.x * Math.random() * 2.5;
        const targetY = child.y * Math.random() * 2.5;

        const scaleMod = (0.9 + Math.random() * 0.1);
        const targetScaleX = child.scale.x * scaleMod;
        const targetScaleY = child.scale.y * scaleMod;

        const targetRot = child.rotation + (Math.PI / 8 * Math.random() - Math.PI / 16);

        const childRef = child;

        EffectManager.instance.spawnAnimation(childRef,
          'explosions',
          'explosion_c',
          3 + 3 * Math.random(), -2 + Math.random() * 4, -2 * Math.random() * 4, EntityLayer.BACKGROUND, 0, 1 + Math.random() * 0.2);

        setTimeout(() => {
          EffectManager.instance.spawnAnimation(childRef,
            'explosions',
            'explosion_a',
            3 + 3 * Math.random(), -2 + Math.random() * 4, -2 * Math.random() * 4, EntityLayer.BACKGROUND, 0, 1 + Math.random() * 0.2);

          EffectManager.instance.spawnAnimation(childRef,
            'explosions',
            'explosion_f',
            3 + 3 * Math.random(), -5 + Math.random() * 10, -5 * Math.random() * 10, EntityLayer.FOREGROUND, 0, 1 + Math.random() * 0.2);
        }, 250 +Math.random() * 300);

        setTimeout(() => {
          EffectManager.instance.spawnAnimation(childRef,
            'explosions',
            'explosion_f',
            3 + 3 * Math.random(), 0, 0, EntityLayer.FOREGROUND, 0, 0.5 + Math.random() * 0.2);
        }, 750 + Math.random() * 600);

        this.dieTimeline.to(child, {
          x: targetX,
          y: targetY,
          rotation: targetRot,
          duration: 1,
          delay: 0.1,
          ease: Power4.easeOut,
        }, 0);

        this.dieTimeline.to(child.scale, {
          x: targetScaleX,
          y: targetScaleY,
          duration: 1,
          delay: 0.4,
          ease: Power2.easeOut,
        }, 0);

        this.dieTimeline.to(child, {
          alpha: 0,
          duration: 2,
          ease: Power2.easeIn,
        }, 0);
      }
    }
  }
}
