import * as PIXI from 'pixi.js';
import {default as PIXIsound} from 'pixi-sound';
import 'pixi-sound';

export enum Mood {
  Calm,
  Angry,
}

export class SoundManager {

  public static RequiredAssets = [
    {
      name: 'mus_calm_core',
      src: 'music/2021-03-07_-_Haunted_Memories_-_David_Fesliyan.mp3'
    },
    {
      name: 'mus_calm_background_noise',
      src: 'music/Space wind.mp3'
    },
    {
      name: 'mus_angry',
      src: 'music/2019-10-30_-_Action_Rhythms_-_David_Fesliyan.mp3'
    },
    {
      name: 'mus_menu_fadein',
      src: 'music/ONE_SHOT_CINEMATIC_EFFECT_01.mp3'
    },
    {
      name: 'sfx_beep_targeting_beep_02',
      src: 'sfx/BEEP_Targeting Beep_02_SFRMS_SCIWPNS.mp3',
    },
    {
      name: 'sfx_gunmech_scope_activate_09',
      src: 'sfx/GUNMech_Scope Activate_09_SFRMS_SCIWPNS.mp3',
    },
    {
      name: 'sfx_lasrbeam_plasma_loop_01',
      src: 'sfx/LASRBeam_Plasma Loop_01_SFRMS_SCIWPNS.mp3',
    },
    {
      name: 'sfx_scimisc_arm_weapon_medium_02',
      src: 'sfx/SCIMisc_Arm Weapon Medium_02_SFRMS_SCIWPNS.mp3',
    }
  ];

  // @ts-ignore
  private static _instance: SoundManager;

  private gameVolume: number = 1;
  private soundVolume: number = 1;
  private musicVolume: number = 0.7;

  public static get instance(): SoundManager {
    if (!SoundManager._instance) {
      SoundManager._instance = new SoundManager();
    }
    return SoundManager._instance;
  }

  private activeSounds = new Map<number, PIXI.sound.Sound>();
  private soundKeyCounter: number = 0;
  private camera: null | PIXI.DisplayObject = null;

  private mus: PIXIsound.Sound[] = [];

  constructor() {
    // TODO Restore the global music and sound volume.
  }

  trackCamera(obj: PIXI.DisplayObject): void {
    this.camera = obj;
  }

  setMood(mood: Mood): void {
    switch (mood) {
      case Mood.Calm:
        this.fadeToCalmMusic();
        break;
      case Mood.Angry:
        this.fadeToAngryMusic();
        break;
    }
  }

  private fadeoutMusic(): void {
    if (this.mus) {
      this.mus.forEach(mus => {
        mus.stop();
      });
    }
    this.mus = [];
  }

  private fadeToCalmMusic(): void {
    this.fadeoutMusic();
    const mus = PIXI.Loader.shared.resources['mus_calm_core'].sound;

    mus.loop = true;
    mus.volume = this.gameVolume * this.musicVolume;
    mus.speed = 0.5;
    mus.play();

    this.mus.push(mus);

    const mus2 = PIXI.Loader.shared.resources['mus_calm_background_noise'].sound;

    mus2.loop = true;
    mus2.volume = this.gameVolume * this.musicVolume * 0.1;
    mus2.play();

    this.mus.push(mus2);
  }

  private fadeToAngryMusic(): void {
    this.fadeoutMusic();
    const mus = PIXI.Loader.shared.resources['mus_angry'].sound;

    mus.loop = true;
    mus.volume = this.gameVolume * this.musicVolume;
    mus.play();

    this.mus.push(mus);
  }

  playSound(name: string, position?: PIXI.Point): void {
    const asset = PIXI.Loader.shared.resources[name];
    if (!asset) {
      console.warn(`unable to play sound '${name}': not found`);
      return;
    }
    const snd = asset.sound;

    const filter = new PIXIsound.filters.StereoFilter(0);
    snd.filters = [filter];
    snd.volume = this.gameVolume * this.soundVolume;

    const rndKey = ++this.soundKeyCounter + Math.random();
    this.activeSounds.set(rndKey, snd);
    snd.play(void 0, () => {
      this.activeSounds.delete(rndKey);
    });
  }
}
