import {Component, OnDestroy, OnInit} from '@angular/core';
import {MapService} from '../../../services/map.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {EntityConfig} from '@game/entities/entity';
import {GalaxyService} from '../../../services/galaxy.service';

@Component({
  selector: 'wg-entity-hud',
  templateUrl: './entity-hud.component.html',
  styleUrls: ['./entity-hud.component.less']
})
export class EntityHudComponent implements OnInit, OnDestroy {

  entity: null | EntityConfig = null;
  private destroyed$ = new Subject();

  constructor(private mapService: MapService,
              private galaxyService: GalaxyService) {
  }

  ngOnInit(): void {
    this.mapService.afterSelectedEntityChanged().pipe(
      takeUntil(this.destroyed$)
    ).subscribe((cfg) => {
      this.entity = cfg;
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  inviteToParty(): void {
    if (!this.entity) {
      return;
    }
    this.galaxyService.inviteToParty(this.entity.characterRef);
  }
}

