import {Injectable} from '@angular/core';
import {WebsocketService} from '@shared/services/websocket.service';
import {Observable, Subject} from 'rxjs';
import {PartyService} from './party.service';

export interface InitWarpEvent {
  mapName: string;
}

export interface CharacterInfo {
  characterId: string;
  name: string;
  gearScore: number;
  mapName: string;
  biome: string;
  environment: string;
}

export interface PartyInvite {
  characterId: string;
  name: string;
}

export interface PartyMember {
  status: 'online' | 'offline';
  characterId: string;
  leader: boolean;
  name: string;
  mapId: string;
  mapName: string;
}

export interface PartyStatus {
  members: PartyMember[];
}

export interface GetCharactersResponse {
  characters: CharacterInfo[];
}

export interface CharacterCreatedResponse {
  character: CharacterInfo;
}

@Injectable({
  providedIn: 'root'
})
export class GalaxyService extends WebsocketService {

  private afterCharacterSelected$ = new Subject<void>();

  afterConnectTo(): Observable<any> {
    return this.sub('ConnectTo');
  }

  afterInitWarp(): Observable<InitWarpEvent> {
    return this.sub('InitWarp');
  }

  createCharacter(name: string): Promise<CharacterCreatedResponse> {
    return this.rpc({
      method: 'CreateCharacter',
      params: {
        name: name,
      }
    });
  }

  getCharacters(): Promise<GetCharactersResponse> {
    return this.rpc({
      method: 'GetCharacters',
      params: null,
    });
  }

  selectCharacter(characterId: string): void {
    this.rpc({
      method: 'SelectCharacter',
      params: {
        characterId: characterId,
      },
    }).then(() => {
      this.afterCharacterSelected$.next();
    }).catch(err => {
      // TOOD Handle error - disconnect from page and reload everything
      this.afterCharacterSelected$.error(err);
    });
  }

  afterCharacterSelected(): Observable<void> {
    return this.afterCharacterSelected$.asObservable();
  }

  inviteToParty(characterId: string): void {
    this.rpc({
      method: 'InviteToParty',
      params: {
        characterId: characterId,
      },
    }).then(() => {
      console.log('[Galaxy] Invite send');
    }).catch(err => {
      // FIXME Handle error
      console.error('Unable to send invite');
    });
  }

  afterPartyInviteReceived(): Observable<PartyInvite> {
    return this.sub('party.invite.received');
  }

  afterPartyFormed(): Observable<PartyStatus> {
    return this.sub('party.formed');
  }

  afterCharacterJoined(): Observable<PartyStatus> {
    return this.sub('party.character.joined');
  }

  afterJoinedParty(): Observable<PartyStatus> {
    return this.sub('party.joined');
  }

  acceptPartyInvite(characterId: string): Promise<any> {
    return this.rpc({
      method: 'AcceptPartyInvite',
      params: {
        characterId: characterId,
      },
    });
  }

  denyPartyInvite(characterId: string): Promise<any> {
    return this.rpc({
      method: 'DenyPartyInvite',
      params: {
        characterId: characterId,
      },
    });
  }

  afterPartyStatusRefreshed(): Observable<PartyStatus> {
    return this.sub('party.status.refreshed');
  }
}
