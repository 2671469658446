import {BattleGameObject} from '@game/battles/battle.game-object';
import {StarSystemService} from '../../../services/star-system.service';
import * as PIXI from 'pixi.js';
import {InteractionEvent} from 'pixi.js';
import {BiomeGameObject} from '@game/biomes/biome.game-object';
import {Scene} from '../../app.component';
import {EffectManager} from '@game/vfx/effect.manager';
import {BattleService} from '../../../services/battle.service';
import {BiomeFactory} from '@game/biomes/biome.factory';
import {EnvironmentFactory} from '@game/environments/environment.factory';
import {EnvironmentGameObject} from '@game/environments/environment.game-object';
import {screenHeight, screenWidth} from '../../screen';
import {fromEvent} from 'rxjs';

const MAX_ZOOM = 2.2;
const MIN_ZOOM = 0.6;
const MOUSE_WHEEL_ZOOM_FACTOR = -0.001;

export class BattleScene extends PIXI.Container implements Scene {

  private viewport: PIXI.Container;

  private battleObject: BattleGameObject;
  private biome: BiomeGameObject | null = null;
  private environment: EnvironmentGameObject | null = null;

  private dragging: any;
  private data: any;
  private draggingStartPos: PIXI.ObservablePoint | null = null;

  constructor(private starSystemService: StarSystemService,
              private battleService: BattleService) {
    super();

    this.viewport = new PIXI.Container();
    this.viewport.sortableChildren = true

    this.addChild(this.viewport);

    const b = this.battleService.getBattle();
    if (!b) {
      throw new Error('battle not initialised');
    }

    const biome = battleService.getBiome();
    if (biome) {
      this.biome = BiomeFactory.create(biome);
      this.biome?.attachTo(this.viewport);
    }

    const environment = battleService.getEnvironment();
    if (environment) {
      this.environment = EnvironmentFactory.create(environment.identifier);
      this.environment?.attachTo(this.viewport);
    }

    this.battleObject = new BattleGameObject(b);
    this.battleObject.attachTo(this.viewport);
    this.centerToScreen();

    this.viewport.interactive = true;
    this.viewport
      .on('pointerdown', this.onDragStart.bind(this))
      .on('pointerup', this.onDragEnd.bind(this))
      .on('pointerupoutside', this.onDragEnd.bind(this))
      .on('pointermove', this.onDragMove.bind(this));

    EffectManager.instance.setParent(this);

    fromEvent(window, 'wheel').pipe(
      // takeUntil(this.destroyed$),
    ).subscribe((evt) => this.handleWheel(evt as WheelEvent));
  }

  // Centers this component to the screen using the current and the 'default' screen height.
  centerToScreen(): void {
    const c = this.battleObject.getCenter();
    this.viewport.x = screenWidth() / 2 - c.x;
    this.viewport.y = screenHeight() / 2 - c.y;

    console.log('CENTER TO ', this.viewport.x, this.viewport.y);
  }

  onDragStart(event: InteractionEvent): void {

    if (event.target !== this.viewport) {
      return;
    }

    // store a reference to the data
    // the reason for this is because of multitouch
    // we want to track the movement of this particular touch
    this.data = event.data;
    this.draggingStartPos = this.viewport.position.clone();
    this.dragging = this.data.getLocalPosition(this.parent).clone();
  }

  onDragEnd(): void {
    this.dragging = false;
    // set the interaction data to null
    this.data = null;
  }

  onDragMove(): void {
    if (this.dragging && this.draggingStartPos) {
      const pos = this.data.getLocalPosition(this.parent);
      this.viewport.x = this.draggingStartPos.x - (this.dragging.x - pos.x);
      this.viewport.y = this.draggingStartPos.y - (this.dragging.y - pos.y);
    }
  }

  destroy(options?: { children?: boolean; texture?: boolean; baseTexture?: boolean }): void {
    if (this.biome) {
      this.biome.destroyAfter(250);
    }
    if (this.environment) {
      this.environment.destroyAfter(250);
    }

    setTimeout(() => {
      super.destroy(options);
    }, 500);
  }

  update(delta: number): void {
    if (this.biome) {
      this.biome.update(delta);
    }
    if (this.environment) {
      this.environment.update(delta);
    }
  }

  private handleWheel(evt: WheelEvent): void {
    let oldScale = this.viewport.scale.x;
    let scale = this.viewport.scale.x + evt.deltaY * MOUSE_WHEEL_ZOOM_FACTOR;
    scale = Math.min(Math.max(scale, MIN_ZOOM), MAX_ZOOM);

    console.log('Zoom battle', oldScale, scale, this.position);

    const posX = (this.viewport.position.x - (screenWidth() / 2)) / oldScale;
    const posY = (this.viewport.position.y - (screenHeight() / 2)) / oldScale;

    this.viewport.scale.set(scale);
    this.viewport.position.x = (posX * scale) + (screenWidth() / 2);
    this.viewport.position.y = (posY * scale) + (screenHeight() / 2);
  };
}
