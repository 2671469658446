import {Component, Input, OnInit} from '@angular/core';
import {HullData, HullTraderUpgradeOptions, InteractionService} from '../interaction.service';
import {StarSystemService} from '../../../../services/star-system.service';
import {Ship} from '@game/ships/ship';
import {HudService} from '../../hud.service';
import {MapService} from '../../../../services/map.service';

@Component({
  selector: 'wg-menu-hull-trader',
  templateUrl: 'hull-trader.component.html',
  styleUrls: ['hull-trader.component.less']
})
export class HullTraderComponent implements OnInit {

  @Input() hulls: null | HullData[] = null;

  public selectedShip: null | Ship = null;
  public upgradePaths: null | { [hullId: string]: HullTraderUpgradeOptions } = null;

  constructor(private starSystemService: StarSystemService,
              private interactionService: InteractionService,
              private hudService: HudService,
              private mapService: MapService) {
  }

  ngOnInit(): void {
    this.hudService.watchSelectedShip().subscribe(ship => {
      this.selectedShip = ship;
      this.updateUpgradePaths();
    });
  }

  buyHull(pos: number): void {
    if (!this.hulls) {
      throw new Error('no hulls');
    }

    if (!this.mapService.lockedOn) {
      throw new Error('not locked to an entity');
    }

    const hull = this.hulls[pos];

    this.starSystemService.rpc({
      method: 'BuyHull',
      params: {
        entityId: this.mapService.lockedOn.entityId,
        hullId: hull.identifier,
      }
    });
  }

  upgradeHull(hullId: string): void {
    if (!this.selectedShip) {
      throw new Error('no ship selected');
    }

    if (!this.mapService.lockedOn) {
      throw new Error('not locked to an entity');
    }

    this.starSystemService.rpc({
      method: 'UpgradeHull',
      params: {
        entityId: this.mapService.lockedOn.entityId,
        shipId: this.selectedShip.shipId,
        hullId: hullId,
      }
    });
  }

  private updateUpgradePaths(): void {
    if (!this.selectedShip) {
      this.upgradePaths = null;
      return;
    }

    if (!this.interactionService.hullTraderData) {
      throw new Error('cannot update upgrade paths: no hull trader active');
    }

    // Load the paths from the trader interaction data.
    const shipType = this.selectedShip.hullType;
    this.upgradePaths = this.interactionService.hullTraderData.upgradeOptions[shipType];
  }
}
