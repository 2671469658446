import {Injectable} from '@angular/core';
import {StarSystemService} from '../../../services/star-system.service';
import {ItemStackData} from '@game/characters/character';
import {Subscription} from 'rxjs';
import {EntityConfig} from '@game/entities/entity';
import {Vector2da} from '@shared/utils/vector2da';
import {CharacterService} from '../../../services/character.service';
import {ShipData} from '@game/ships/ship';
import {MapService} from '../../../services/map.service';
import {HangarMovedEvent, ShipsExchangedEvent} from '../../../services/inventory.service';


export interface QuestRewardData {
  scrap: number;
  crystals: number;
  items: {[key: number]: ItemStackData};
}

export interface QuestObjectiveData {
  // FIXME
}

export interface QuestGiverData {
  questId: string;
  name: string;
  text: string;
  objectives: { [key: string]: QuestObjectiveData };
  rewards: QuestRewardData;
}

export interface QuestReceiverData {
  questId: string;
  name: string;
  text: string;
  objectives: { [key: string]: QuestObjectiveData };
  rewards: QuestRewardData;
}

export interface InteractionQuestData {
  questId: string;
  name: string;
  ready: boolean;
}

export interface LoreData {
  loreId: string;
  alreadyRead: boolean;
  name: string;
}

export interface InteractionData {
  questGiver: InteractionQuestData[];
  questReceiver: InteractionQuestData[];
  lore: LoreData[];
  isItemTrader: boolean;
  isHullTrader: boolean;
  isBridgePillar: boolean;
  isHangarMechanic: boolean;
  isDepotManager: boolean;
}

export interface ItemTraderData {
  items: ItemStackData[];
}

export interface HullData {
  identifier: string;
  name: string;
  // FIXME stats?
  price: string;
}

export interface HullTraderUpgradeOptions {
  scrapCosts: number;
  itemCosts?: {
    [itemId: string]: {
      data: any;
      amount: number;
      available: boolean;
    };
  };
  available: boolean;
}

export interface HullTraderData {
  hulls: HullData[];
  upgradeOptions: { [hullId: string]: { [hullId: string]: HullTraderUpgradeOptions } };
}

export interface HangarData {
  canRepair: boolean;
  canExchange: boolean;
  repairPrices: { [shipId: string]: number };
  revivePrices: { [shipId: string]: number };
  hangarSize: number;
  hangar: { [shipId: string]: ShipData };
}

export const MAX_INTERACTION_DISTANCE: number = 300;

@Injectable()
export class InteractionService {

  state: 'none' | 'root' | 'questGiver' | 'questReceiver' | 'lore' | 'itemTrader' | 'hullTrader' | 'hangarMechanic' | 'depotManager';
  rootData: null | InteractionData = null;
  questReceiverData: null | QuestReceiverData = null;
  loreData: null | LoreData = null;
  itemTraderData: null | ItemTraderData = null;
  hullTraderData: null | HullTraderData = null;
  hangarData: null | HangarData = null;

  loading: boolean = false;
  isSingleAction: null | boolean = null;
  activeQuestId: null | string = null;
  private distanceSub: null | Subscription = null;

  constructor(private starSystemService: StarSystemService,
              private characterService: CharacterService,
              private mapService: MapService) {
    this.state = 'none';

    this.starSystemService.sub('ships.exchanged').subscribe(this.handleShipsExchanged.bind(this));
    this.starSystemService.sub('hangar.moved').subscribe(this.handleHangarMoved.bind(this));

  }

  private handleShipsExchanged(evt: ShipsExchangedEvent): void {
    if (!this.hangarData) {
      return;
    }

    // Change "immutable" data structure to force change handling.
    const clone = JSON.parse(JSON.stringify(this.hangarData));
    clone.hangar = evt.hangar;
    this.hangarData = clone;
  }

  private handleHangarMoved(evt: HangarMovedEvent): void {
    if (!this.hangarData) {
      return;
    }

    // Change "immutable" data structure to force change handling.
    const clone = JSON.parse(JSON.stringify(this.hangarData));
    clone.hangar = evt.hangar;
    this.hangarData = clone;
  }

  startInteractionWith(entity: EntityConfig): void {
    if (this.loading) {
      console.log('[Interaction] Cannot start interaction: Currently loading');
      return;
    }

    if (this.mapService.lockedOn === entity) {
      console.log('[Interaction] Cannot start interaction: Already locked to this entity');
      return;
    }

    this.loading = true;

    this.stopInteraction();

    this.starSystemService.rpc({
      method: 'Interact',
      params: {
        entityId: entity.entityId,
      },
    }).then((resp: InteractionData) => {
      this.state = 'root';
      this.rootData = resp;

      this.trackEntity(entity);

      this.loading = false;
      this.redirectIfSingleAction();

    }).catch((err: any) => {
      console.error('[Interaction] error: ', err);
    }).finally(() => {
      this.loading = false;
    });
  }

  stopInteraction(): void {
    console.log('[Interaction] stopped');

    this.state = 'none';
    this.rootData = null;
    this.questReceiverData = null;
    this.mapService.lockedOn = null;

    if (this.distanceSub) {
      this.distanceSub.unsubscribe();
      this.distanceSub = null;
    }
  }

  returnToMainPage(): void {
    if (this.state === 'none' || this.state === 'root') {
      return;
    }

    if (this.isSingleAction) {
      return;
    }

    this.state = 'root';
  }

  showQuestIntro(questId: string): void {
    if (this.loading) {
      return;
    }

    this.state = 'questGiver';
    this.activeQuestId = questId;
  }

  showQuestOutro(questId: string): void {
    if (this.state !== 'root' || !this.mapService.lockedOn) {
      return;
    }

    if (this.loading) {
      return;
    }
    this.loading = true;

    const entityId = this.mapService.lockedOn.entityId;
    this.starSystemService.getQuestOutro(questId, entityId).then((resp: QuestGiverData) => {
      this.state = 'questReceiver';
      this.questReceiverData = resp;
    }).catch((err: any) => {
      console.error('[Interaction] error: ', err);
    }).finally(() => {
      this.loading = false;
    });
  }

  acceptQuest(questId: string): void {
    if (this.state !== 'questGiver' || !this.mapService.lockedOn) {
      return;
    }

    if (this.loading) {
      return;
    }
    this.loading = true;

    const entityId = this.mapService.lockedOn.entityId;
    this.starSystemService.acceptQuest(questId, entityId).then(() => {
      this.stopInteraction();
    }).catch((err: any) => {
      console.error('[Interaction] error: ', err);
    }).finally(() => {
      this.loading = false;
    });
  }

  submitQuest(questId: string): void {
    if (this.state !== 'questReceiver' || !this.mapService.lockedOn) {
      return;
    }

    if (this.loading) {
      return;
    }
    this.loading = true;

    const entityId = this.mapService.lockedOn.entityId;
    this.starSystemService.submitQuest(questId, entityId).then(() => {
      this.stopInteraction();
    }).catch((err: any) => {
      console.error('[Interaction] error: ', err);
    }).finally(() => {
      this.loading = false;
    });
  }

  loadItemTrader(): void {
    if (this.state !== 'root' || !this.mapService.lockedOn) {
      return;
    }

    if (this.loading) {
      return;
    }
    this.loading = true;

    const entityId = this.mapService.lockedOn.entityId;
    this.starSystemService.loadItemTrader(entityId).then((data: ItemTraderData) => {
      this.itemTraderData = data;
      this.state = 'itemTrader';
    }).catch((err: any) => {
      console.error('[Interaction] error: ', err);
    }).finally(() => {
      this.loading = false;
    });
  }

  loadHullTrader(): void {
    if (this.state !== 'root' || !this.mapService.lockedOn) {
      return;
    }

    if (this.loading) {
      return;
    }
    this.loading = true;

    const entityId = this.mapService.lockedOn.entityId;
    this.starSystemService.loadHullTrader(entityId).then(data => {
      this.hullTraderData = data;
      this.state = 'hullTrader';
    }).catch((err) => {
      console.error('[Interaction] error: ', err);
    }).finally(() => {
      this.loading = false;
    });
  }

  loadHangar(): void {
    if (this.state !== 'root' || !this.mapService.lockedOn) {
      return;
    }

    if (this.loading) {
      return;
    }
    this.loading = true;

    const entityId = this.mapService.lockedOn.entityId;
    this.starSystemService.loadHangar(entityId).then(data => {
      this.hangarData = data;
      this.state = 'hangarMechanic';
    }).catch((err) => {
      console.error('[Interaction] error: ', err);
    }).finally(() => {
      this.loading = false;
    });
  }


  isItemTraderActive(): boolean {
    return this.state === 'itemTrader';
  }

  sellItemAt(pos: any): void {
    if (!this.isItemTraderActive() || !this.mapService.lockedOn) {
      return;
    }

    const entityId = this.mapService.lockedOn.entityId;
    this.starSystemService.sellItem(pos, entityId);
  }

  private trackEntity(entity: EntityConfig): void {
    const char = this.characterService.getCharacter();

    this.mapService.lockedOn = entity;

    this.distanceSub = char.afterPositionChanged().subscribe((pos: Vector2da) => {

      const dist: number = Math.sqrt(Math.pow(pos.x - entity.position.x, 2) + Math.pow(pos.y - entity.position.y, 2));
      if (dist >= MAX_INTERACTION_DISTANCE) {
        this.stopInteraction();
      }
    });
  }

  /**
   * Check if there is only a single available trait and no banter and immediately
   * continue to this action instead of showing the main menu.
   */
  private redirectIfSingleAction(): void {
    let count = 0;
    const d = this.rootData;

    if (!d) {
      return;
    }

    if (d.isItemTrader) {
      count++;
    }
    if (d.isHullTrader) {
      count++;
    }
    if (d.isBridgePillar) {
      count++;
    }
    if (d.isHangarMechanic) {
      count++;
    }
    if (d.isDepotManager) {
      count++;
    }
    if (d.lore && d.lore.length > 0) {
      count += d.lore.length;
    }
    if (d.questGiver && d.questGiver.length > 0) {
      count += d.questGiver.length;
    }
    if (d.questReceiver && d.questReceiver.length > 0) {
      count += d.questReceiver.length;
    }

    if (count > 1) {
      this.isSingleAction = false;
      return;
    }

    this.isSingleAction = true;

    if (d.isHullTrader) {
      this.loadHullTrader();
    } else if (d.isItemTrader) {
      this.loadItemTrader();
    } else if (d.questReceiver) {
      const q = d.questReceiver[0];
      if (!q.ready) {
        this.stopInteraction();
        return;
      }
      this.showQuestOutro(q.questId);
    } else if (d.questGiver) {
      this.showQuestIntro(d.questGiver[0].questId);
    } else if (d.lore) {
      throw new Error('not implemented');
    }
  }

  useBridge(): void {
    if (!this.mapService.lockedOn) {
      return;
    }
    this.starSystemService.useBridge(this.mapService.lockedOn.entityId);
  }
}
