import {ShipFactory} from '@game/ships/ship.factory';
import {Ship, ShipData} from '@game/ships/ship';
import {EntityGameObject} from '@game/entities/entity.game-object';
import {ShipGameObject} from '@game/ships/ship.game-object';
import {EntityConfig} from '@game/entities/entity';
import {EntityOptions} from '@game/entities/entity.factory';

const SHIP_ENTITY_DEATH_DESTROY_DELAY = 2000;

export class ShipEntityGameObject extends EntityGameObject {

  private child: ShipGameObject;
  private ship: Ship;

  set angle(x: number) {
    this.child.angle = x;
  }

  constructor(entity: EntityConfig, options: EntityOptions) {
    super(entity, options);

    const segments = entity.asset.split('/');
    let asset = segments[segments.length - 1];

    this.ship = new Ship({
      asset: asset,
      faction: entity.faction,
      moduleTier: entity.tier,
      structure: 1,
    } as ShipData);

    this.child = ShipFactory.createShipGameObject(this.ship, {track: this});

    this.child.updateScale(1);

    this.addChild(this.child);
  }

  get angle(): number {
    return this.child?.angle || 0;
  }

  update(delta: number): void {
    super.update(delta);

    if (this.child && this.child.update) {
      this.child.update(delta);
    }
  }

  die(): void {
    this.ship.updateStats({ structure: 0 });
    setTimeout(() => {
      this.destroy();
    }, SHIP_ENTITY_DEATH_DESTROY_DELAY);
  }

  destroy(options?: { children?: boolean; texture?: boolean; baseTexture?: boolean }): void {
    super.destroy(options);
  }
}
