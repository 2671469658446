import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'prefixSign',
})
export class PrefixSignPipe implements PipeTransform {
  transform(value: string): string {
    if (value.startsWith('-')) {
      return value;
    }
    return '+' + value;
  }
}
