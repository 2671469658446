import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {MapService} from '../../../services/map.service';
import {Environment} from '@game/environments/environment';
import {HudService} from '../hud.service';
import {BattleService} from '../../../services/battle.service';

@Component({
  selector: 'wg-hud-status-effects',
  templateUrl: './status-effect-hud.component.html',
  styleUrls: ['./status-effect-hud.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusEffectHudComponent implements OnInit {

  @Input() source: 'map' | 'battle' = 'map';

  env: null | Environment = null;

  constructor(private mapService: MapService,
              private battleService: BattleService,
              private hudService: HudService,
              private cdr: ChangeDetectorRef) {
    // Empty
  }

  ngOnInit(): void {
    if (this.source === 'map') {
      this.env = this.mapService.getEnvironment();

      this.mapService.afterEnvironmentChanged().subscribe(env => {
        this.env = env;
        this.cdr.detectChanges();
      });
    } else {
      this.env = this.battleService.getEnvironment();

      this.battleService.afterEnvironmentChanged().subscribe(env => {
        this.env = env;
        this.cdr.detectChanges();
      });
    }
  }

  showEnvTooltip(): void {
    if (!this.env) {
      return;
    }

    this.hudService.showEnvironmentTooltip(this.env);
  }

  hideTooltip(): void {
    this.hudService.hideTooltip();
  }
}
