import {ShipGameObject} from '@game/ships/ship.game-object';
import {Ship} from '@game/ships/ship';
import {FallbackShipComponent} from '@game/ships/debug/fallback-ship.component';
import {CruiserOcelotGameObject} from '@game/ships/ehe/cruiser/ocelot.game-object';
import {CruiserCougarGameObject} from '@game/ships/ehe/cruiser/cougar.game-object';
import {CruiserCheetahGameObject} from '@game/ships/ehe/cruiser/cheetah.game-object';
import {DestroyerSharkGameObject} from '@game/ships/ehe/destroyer/shark.game-object';
import {BulwarkSlothGameObject} from '@game/ships/ehe/bulwark/sloth.game-object';
import {CorvetteBuzzardGameObject} from '@game/ships/ehe/corvette/buzzard.game-object';
import {CorvetteHarrierGameObject} from '@game/ships/ehe/corvette/harrier.game-object';
import {CorvetteFalconGameObject} from '@game/ships/ehe/corvette/falcon.game-object';
import {CutterBumblebeeGameObject} from '@game/ships/ehe/cutter/bumblebee.game-object';
import {DestroyerVelociraptorGameObject} from '@game/ships/ehe/destroyer/velociraptor.game-object';
import {DestroyerWolfGameObject} from '@game/ships/ehe/destroyer/wolf.game-object';
import {TransporterBullockGameObject} from '@game/ships/ehe/transporter/bullock.game-object';
import {FrigateArmadilloGameObject} from '@game/ships/ehe/frigate/armadillo.game-object';
import {FrigateHippopotamusGameObject} from '@game/ships/ehe/frigate/hippopotamus.game-object';
import {FrigateTurtleGameObject} from '@game/ships/ehe/frigate/turtle.game-object';
import {DisplayObject} from 'pixi.js';

export interface ShipCreateOptions {
  parentFaction?: string;
  track?: DisplayObject;
}

export abstract class ShipFactory {

  public static RequiredAssets = [{
    name: 'ships',
    src: 'ships/ships@2x.json'
  }, {
    name: 'exhaust',
    src: 'ships/exhaust.json'
  }, {
    name: 'body-ehe-0',
    src: 'ships/body-ehe-0@2x.json'
  }, {
    name: 'wing-ehe-0',
    src: 'ships/wing-ehe-0@2x.json'
  }, {
    name: 'drive-ehe-0',
    src: 'ships/drive-ehe-0@2x.json'
  }, {
    name: 'weap-ehe-0',
    src: 'ships/weap-ehe-0@2x.json'
  }];

  public static shipFactories: { [key: string]: (cfg: Ship, opt: ShipCreateOptions) => ShipGameObject } = {
    bulwark_sloth: (ship: Ship, opt: ShipCreateOptions) => new BulwarkSlothGameObject(ship, opt),
    corvette_buzzard: (ship: Ship, opt: ShipCreateOptions) => new CorvetteBuzzardGameObject(ship, opt),
    corvette_falcon: (ship: Ship, opt: ShipCreateOptions) => new CorvetteFalconGameObject(ship, opt),
    corvette_harrier: (ship: Ship, opt: ShipCreateOptions) => new CorvetteHarrierGameObject(ship, opt),
    cruiser_ocelot: (ship: Ship, opt: ShipCreateOptions) => new CruiserOcelotGameObject(ship, opt),
    cruiser_cougar: (ship: Ship, opt: ShipCreateOptions) => new CruiserCougarGameObject(ship, opt),
    cruiser_cheetah: (ship: Ship, opt: ShipCreateOptions) => new CruiserCheetahGameObject(ship, opt),
    cutter_bumblebee: (ship: Ship, opt: ShipCreateOptions) => new CutterBumblebeeGameObject(ship, opt),
    destroyer_shark: (ship: Ship, opt: ShipCreateOptions) => new DestroyerSharkGameObject(ship, opt),
    destroyer_velociraptor: (ship: Ship, opt: ShipCreateOptions) => new DestroyerVelociraptorGameObject(ship, opt),
    destroyer_wolf: (ship: Ship, opt: ShipCreateOptions) => new DestroyerWolfGameObject(ship, opt),
    frigate_armadillo: (ship: Ship, opt: ShipCreateOptions) => new FrigateArmadilloGameObject(ship, opt),
    frigate_hippopotamus: (ship: Ship, opt: ShipCreateOptions) => new FrigateHippopotamusGameObject(ship, opt),
    frigate_turtle: (ship: Ship, opt: ShipCreateOptions) => new FrigateTurtleGameObject(ship, opt),
    transporter_bullock: (ship: Ship, opt: ShipCreateOptions) => new TransporterBullockGameObject(ship, opt),
  };

  static createShipGameObject(ship: Ship, options: ShipCreateOptions): ShipGameObject {

    let asset = ship.asset;
    if (asset.startsWith('ships/')) {
      asset = asset.substr('ships/'.length);
    }

    const factory = ShipFactory.shipFactories[asset];
    if (!factory) {
      console.warn('undefined ship type: ' + ship.asset);
      return new FallbackShipComponent(ship, options);
    }

    const obj = factory(ship, options);

    if (options && options.track) {
      obj.track(options.track);
    }

    return obj;
  }
}
