import * as PIXI from 'pixi.js';
import {getNearestTierTexture, Ship} from '@game/ships/ship';
import {HullSize, ShipGameObject} from '@game/ships/ship.game-object';
import {ExhaustGameObject} from '@game/ships/parts/exhaust.game-object';
import {ShipCreateOptions} from '@game/ships/ship.factory';

export class CorvetteBuzzardGameObject extends ShipGameObject {

  constructor(ship: Ship, opts: ShipCreateOptions) {
    super(ship, opts);

    const res = PIXI.Loader.shared.resources;

    this.addExhaust(new ExhaustGameObject({
      track: this.ship,
      placement: 0,
      x: 2,
      y: 42,
      scale: 2.5,
    }));

    const shipTexture = getNearestTierTexture(ship.getHighestModuleTier(), 'ships', 'corvette_buzzard_t%.png')
    if (shipTexture) {
      const sprite = new PIXI.Sprite(shipTexture);
      this.addChild(sprite);
    }
  }

  getSize(): number {
    return HullSize.Normal;
  }
}
