import {ShipGameObject} from '../ship.game-object';
import * as PIXI from 'pixi.js';
import {Ship} from '@game/ships/ship';
import {ShipCreateOptions} from '@game/ships/ship.factory';

export class FallbackShipComponent extends ShipGameObject {

  constructor(public ship: Ship, opts: ShipCreateOptions) {
    super(ship, opts);

    const debug = new PIXI.Graphics();
    debug.beginFill(0x00FFFF);
    debug.drawRect(-30, -30, 60, 60);
    debug.beginFill(0xFF00FF);
    debug.drawRect(-5, -30, 10, 15);
    this.addChild(debug);
  }
}
