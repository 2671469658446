import * as PIXI from 'pixi.js';
import {EntityGameObject} from '@game/entities/entity.game-object';
import {EntityConfig} from '@game/entities/entity';
import {EntityOptions} from '@game/entities/entity.factory';

export class FallbackEntity extends EntityGameObject {

  constructor(cfg: EntityConfig, options: EntityOptions) {
    super(cfg, options);

    const graphics = new PIXI.Graphics();
    graphics.beginFill(0x00FFFF);
    graphics.drawRect(-5, -5, 10, 10);
    this.addChild(graphics);
  }

  get angle(): number {
    return 0;
  }

  set angle(a: number) {
    // Nothing to do...
  }
}
