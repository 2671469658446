import * as PIXI from 'pixi.js';
import {screenHeight, screenWidth} from '../../../projects/game/src/app/screen';
import {BiomeGameObject} from '@game/biomes/biome.game-object';
import {gsap} from 'gsap';
import {EntityLayer, parallaxLayerByZIndex, parallaxLayerFactor} from '@game/entities/map';
import {EnvironmentStyling} from '@game/environments/environment';

export class NebulaEnvironmentGameObject implements BiomeGameObject {
  private lyrBackground: null | PIXI.TilingSprite = null;
  private lyrForeground: null | PIXI.TilingSprite = null;
  private lyrMiddle: null | PIXI.TilingSprite = null;
  private texture: null | PIXI.Texture = null;

  private lyrBackgroundAnim: null | gsap.core.Tween = null;
  private lyrForegroundAnim: null | gsap.core.Tween = null;
  private lyrMiddleAnim: null | gsap.core.Tween = null;
  private foregroundOffset: PIXI.Point;

  constructor(private cfg?: EnvironmentStyling) {

    this.foregroundOffset = new PIXI.Point();

    this.load();
  }

  load(): void {
    console.log('[NebulaEnvironment] loading...');

    const loader = PIXI.Loader.shared;
    this.texture = loader.resources['environments/nebula'].texture;
    this.prepare();
  }

  prepare(): void {
    console.log('[NebulaEnvironment] preparing...');

    if (!this.texture) {
      console.error('missing texture');
      return;
    }

    const intensity = this.getIntensity();

    this.lyrBackground = new PIXI.TilingSprite(this.texture, 2 * screenWidth(), 2 * screenHeight());
    this.lyrBackground.zIndex = EntityLayer.BACKGROUND + 1;
    this.lyrBackground.anchor.set(0, 0);
    this.lyrBackground.alpha = 0.4 * intensity;
    this.lyrBackground.tileScale.set(2);

    this.lyrMiddle = new PIXI.TilingSprite(this.texture, 2 * screenWidth(), 2 * screenHeight());
    this.lyrMiddle.zIndex = EntityLayer.DISTANT + 1;
    this.lyrMiddle.anchor.set(0, 0);
    this.lyrMiddle.alpha = 0.2 * intensity;
    this.lyrMiddle.tileScale.set(3);

    this.lyrForeground = new PIXI.TilingSprite(this.texture, 2 * screenWidth(), 2 * screenHeight());
    this.lyrForeground.zIndex = EntityLayer.NEAR_FOREGROUND + 1;
    this.lyrForeground.x = screenWidth() / 2;
    this.lyrForeground.y = screenHeight() / 2;
    this.lyrForeground.alpha = 0.3 * intensity;
    this.lyrForeground.filters = [new PIXI.filters.BlurFilter()];
    this.lyrForeground.tileScale.set(4);

    gsap.from(this.lyrForeground, {
      alpha: 0,
      duration: 0.5,
      onComplete: () => {
        this.lyrForegroundAnim = gsap.fromTo(this.lyrForeground, {
          alpha: 0.3 * intensity
        }, {
          alpha: 0.4 * intensity,
          duration: 7,
          repeat: -1,
          yoyo: true,
        });
      }
    });

    gsap.from(this.lyrMiddle, {
      alpha: 0,
      duration: 0.5,
      onComplete: () => {
        this.lyrMiddleAnim = gsap.fromTo(this.lyrMiddle, {
          alpha: 0.2 * intensity
        }, {
          alpha: 0.3 * intensity,
          duration: 13,
          repeat: -1,
          yoyo: true,
        });
      }
    });

    gsap.from(this.lyrBackground, {
      alpha: 0,
      duration: 0.5,
      onComplete: () => {
        this.lyrBackgroundAnim = gsap.fromTo(this.lyrBackground, {
          alpha: 0.4 * intensity
        }, {
          alpha: 0.6 * intensity,
          duration: 11,
          repeat: -1,
          yoyo: true,
        });
      }
    });
  }

  update(delta: number): void {
    if (this.lyrBackground && this.lyrBackground.parent) {
      const deltaMovementX = this.lyrBackground.parent.x - this.lyrBackground.x - (screenWidth() / 2);
      const deltaMovementY = this.lyrBackground.parent.y - this.lyrBackground.y - (screenHeight() / 2);

      const pl = parallaxLayerByZIndex(this.lyrBackground.zIndex);
      const f = 1 - parallaxLayerFactor(pl);

      this.lyrBackground.tilePosition.x = deltaMovementX * f;
      this.lyrBackground.tilePosition.y = deltaMovementY * f;

      this.lyrBackground.x = -this.lyrBackground.parent.x - (screenWidth() / 2);
      this.lyrBackground.y = -this.lyrBackground.parent.y - (screenHeight() / 2);
    }

    if (this.lyrMiddle && this.lyrMiddle.parent) {
      const deltaMovementX = this.lyrMiddle.parent.x - this.lyrMiddle.x - (screenWidth() / 2);
      const deltaMovementY = this.lyrMiddle.parent.y - this.lyrMiddle.y - (screenHeight() / 2);

      const pl = parallaxLayerByZIndex(this.lyrMiddle.zIndex);
      const f = 1 - parallaxLayerFactor(pl);

      this.lyrMiddle.tilePosition.x = 400 + deltaMovementX * f;
      this.lyrMiddle.tilePosition.y = 400 + deltaMovementY * f;

      this.lyrMiddle.x = -this.lyrMiddle.parent.x - (screenWidth() / 2);
      this.lyrMiddle.y = -this.lyrMiddle.parent.y - (screenHeight() / 2);
    }

    if (this.lyrForeground && this.lyrForeground.parent) {
      const deltaMovementX = this.lyrForeground.parent.x - this.lyrForeground.x - (screenWidth() / 2);
      const deltaMovementY = this.lyrForeground.parent.y - this.lyrForeground.y - (screenHeight() / 2);

      this.foregroundOffset.x += 10 * delta;
      this.foregroundOffset.y -= 10 * delta;

      const pl = parallaxLayerByZIndex(this.lyrForeground.zIndex);
      const f = 1 - parallaxLayerFactor(pl);

      this.lyrForeground.tilePosition.x = this.foregroundOffset.x + (deltaMovementX * f);
      this.lyrForeground.tilePosition.y = this.foregroundOffset.x + (deltaMovementY * f);

      // Center the layer on the parent
      this.lyrForeground.x = -this.lyrForeground.parent.x - (screenWidth() / 2);
      this.lyrForeground.y = -this.lyrForeground.parent.y - (screenHeight() / 2);
    }
  }

  destroyAfter(ms: number = 0): void {
    console.log('[Biome:Nebula] destroying...');

    if (this.lyrForegroundAnim) {
      this.lyrForegroundAnim.kill();
      this.lyrForegroundAnim = null;
    }
    if (this.lyrMiddleAnim) {
      this.lyrMiddleAnim.kill();
      this.lyrMiddleAnim = null;
    }
    if (this.lyrBackgroundAnim) {
      this.lyrBackgroundAnim.kill();
      this.lyrBackgroundAnim = null;
    }

    gsap.to(this.lyrForeground, {
      alpha: 0,
      onComplete: () => {
        if (this.lyrForeground) {
          this.lyrForeground.destroy();
          this.lyrForeground = null;
        }
      },
      duration: ms / 1000,
    });

    gsap.to(this.lyrMiddle, {
      alpha: 0,
      onComplete: () => {
        if (this.lyrMiddle) {
          this.lyrMiddle.destroy();
          this.lyrMiddle = null;
        }
      },
      duration: ms / 1000,
    });

    gsap.to(this.lyrBackground, {
      alpha: 0,
      onComplete: () => {
        if (this.lyrBackground) {
          this.lyrBackground.destroy();
          this.lyrBackground = null;
        }
      },
      duration: ms / 1000,
    });
  }

  attachTo(parent: PIXI.Container): void {

    if (this.lyrBackground) {
      parent.addChild(this.lyrBackground);
    }

    if (this.lyrMiddle) {
      parent.addChild(this.lyrMiddle);
    }

    if (this.lyrForeground) {
      parent.addChild(this.lyrForeground);
    }
  }

  getIntensity(): number {
    if (this.cfg && this.cfg.intensity) {
      return this.cfg.intensity;
    }
    return 1;
  }
}
