import * as PIXI from 'pixi.js';
import {Container} from 'pixi.js';
import {EntityLayer} from '@game/entities/map';
import {EffectManager} from '@game/vfx/effect.manager';
import Timeline = gsap.core.Timeline;
import ColorMatrixFilter = PIXI.filters.ColorMatrixFilter;

const negationFilter = new ColorMatrixFilter();
negationFilter.negative(true);


export function triggerMyFirstExplosion(t: Timeline, obj: Container, when: number): void {

  t.call(() =>
      EffectManager.instance.spawnAnimation(obj,
        'explosions',
        'explosion_i',
        1, 0, 0, EntityLayer.FOREGROUND, 0, 0.6)
    , undefined, when);

  t.call(() =>
      EffectManager.instance.spawnAnimation(obj,
        'explosions',
        'explosion_d',
        3, 0, 0, EntityLayer.BACKGROUND, 0, 1.2)
    , undefined, when + 0.2);

  t.call(() =>
      EffectManager.instance.spawnAnimation(obj,
        'explosions',
        'explosion_b',
        3, 0, 0, EntityLayer.FOREGROUND, 0, 0.7)
    , undefined, when + 0.3);
}

export function vfxGodRay(t: Timeline, obj: Container, when: number): void {

  vfxNegate(t, obj, when, 0.1);

  t.call(() =>
      EffectManager.instance.spawnAnimation(obj,
        'vfx',
        'F501',
        1, 0, 0, EntityLayer.FOREGROUND, 0, 1)
    , undefined, when);

  vfxNegate(t, obj, when + 0.2, 0.2);
}

export function vfxNegate(t: Timeline, obj: Container, when: number, duration: number): void {
  let prevFilters: PIXI.Filter[];
  t.call(() => {
    prevFilters = obj.filters;
    obj.filters = [negationFilter];
  }, undefined, when);

  t.call(() => {
    obj.filters = prevFilters;
  }, undefined, when + duration);
}
