import * as PIXI from 'pixi.js';
import {Ship} from '@game/ships/ship';

export interface ExhaustConfig {
  track: Ship,
  speed?: number;
  placement: number;

  x: number;
  y: number;
  scale?: number;
}

export class ExhaustGameObject extends PIXI.AnimatedSprite {

  private cfg: ExhaustConfig;

  constructor(cfg: ExhaustConfig) {
    const res = PIXI.Loader.shared.resources;
    super(res.exhaust.spritesheet?.animations.exhaust);

    this.position.set(cfg.x, cfg.y);
    this.scale.set(cfg.scale || 1);

    this.cfg = cfg;

    this.animationSpeed = cfg.speed || 5;
    this.alpha = 0.2;

    // Start on a random frame.
    this.gotoAndPlay(Math.floor(this.totalFrames * Math.random()));
  }

  reducePower(delta: number): void {
    this.alpha = Math.max(0.2, this.alpha * (0.001 * delta));
  }

  enhancePower(orientation: number = 0): void {
    if (this.cfg.placement === 0) {
      this.alpha = 1 - 0.5 * Math.abs(orientation);
    } else if (this.cfg.placement === -1) {
      if (orientation <= 0) {
        this.alpha = 1 - 0.5 * Math.abs(1 + orientation);
      } else {
        this.alpha = 0.3;
      }
    } else if (this.cfg.placement === 1) {
      if (orientation >= 0) {
        this.alpha = 1 - 0.5 * Math.abs(1 - orientation);
      } else {
        this.alpha = 0.3;
      }
    }
  }
}
