import * as PIXI from 'pixi.js';
import {Ship} from '@game/ships/ship';
import {HullSize, ShipGameObject} from '@game/ships/ship.game-object';
import {ExhaustGameObject} from '@game/ships/parts/exhaust.game-object';
import {ShipCreateOptions} from '@game/ships/ship.factory';

const EXHAUST_OFFSET = {x: 4, y: 35, s: 3};

export class BulwarkSlothGameObject extends ShipGameObject {

  constructor(ship: Ship, opts: ShipCreateOptions) {
    super(ship, opts);

    const res = PIXI.Loader.shared.resources;

    this.addExhaust(new ExhaustGameObject({
      track: this.ship,
      placement: 0,
      x: 3,
      y: 33,
      scale: 3,
    }));

    // @ts-ignore
    const sprite = new PIXI.Sprite(res.ships.textures['bulwark_sloth.png']);
    this.addChild(sprite);
  }

  getSize(): number {
    return HullSize.Large;
  }
}
