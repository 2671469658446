import {Ship, ShipData} from '@game/ships/ship';
import {SquareCellGameObject} from '@game/teams/square-cell.game-object';
import {CombatantGameObject} from '@game/battles/combatant.game-object';
import {Team3x3GameObject} from '@game/teams/team3x3.game-object';
import {ShipChanges} from '@game/battles/events';
import {Observable, Subject} from 'rxjs';

export interface GridEvent {
  source: SquareCellGameObject;
  team?: Team3x3GameObject;
  combatant?: CombatantGameObject;
  ship: Ship;
}

export enum GridOrientation {
  TOP = 0,
  LEFT = 270,
  DOWN = 180,
  RIGHT = 90,
}

export class Team3x3 {

  private shipsChanged$: Subject<void> = new Subject<void>();

  constructor(public readonly teamId: string, private ships: Ship[], private teamLevel: number) {
  }

  destroy(): void {
    this.shipsChanged$.complete();

    this.ships.forEach(ship => {
      ship.destroy();
    })
    this.ships = [];
  }

  getShips(): Ship[] {
    return this.ships;
  }

  getFrontRow(): number[] {

    const front = [];

    for (let i = 0; i < 3; i++) {
      for (let j = i; j < 9; j += 3) {
        if (this.ships[i]) {
          front.push(i);
          break;
        }
      }
    }

    return front;
  }

  getPosition(shipId: string): number {
    for (let i = 0 ; i < this.ships.length; i++) {
      if (this.ships[i] && this.ships[i].shipId === shipId) {
        return i + 1;
      }
    }
    return -1;
  }

  hasShip(shipId: string): boolean {
    return this.ships.findIndex(s => s && s.shipId === shipId) >= 0;
  }

  getShipAt(pos: number): Ship {
    return this.ships[pos - 1];
  }

  getTeamLevel(): number {
    return this.teamLevel;
  }

  updateShip(shipId: string, changes: ShipChanges): void {
    const index = this.ships.findIndex(s => s && s.shipId === shipId);
    if (index === -1) {
      throw new Error('unable to update ship: not found');
    }

    this.ships[index].updateStats(changes);
    this.shipsChanged$.next();
  }

  /**
   * replaceShip replaces the given information about an ship.
   */
  replaceShipAt(index: number, ship: ShipData): void {
    this.ships[index] = new Ship(ship);
    this.shipsChanged$.next();
  }

  /**
   * updateShip replaces the given information about an ship.
   */
  replaceShip(shipId: string, ship: ShipData): void {
    const index = this.ships.findIndex(s => s && s.shipId === shipId);
    if (index === -1) {
      throw new Error('unable to update ship: not found');
    }

    this.ships[index] = new Ship(ship);
    this.shipsChanged$.next();
  }

  moveShip(from: number, to: number): void {
    const fromShip = this.ships[from - 1];
    const toShip = this.ships[to - 1];

    if (!!fromShip) {
      this.ships[to - 1] = fromShip;
    } else {
      delete this.ships[to - 1];
    }

    if (toShip) {
      this.ships[from - 1] = toShip;
    } else {
      delete this.ships[from - 1];
    }

    this.shipsChanged$.next();
  }

  afterShipsChanged(): Observable<void> {
    return this.shipsChanged$.asObservable();
  }

  getHighestShipLevel(): number {
    return this.ships.map((ship) => ship ? ship.level : 0).reduce((p, c) => Math.max(p, c), 0);
  }
}
