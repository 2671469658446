import {Combatant} from '@game/battles/combatant';
import {Team3x3GameObject} from '@game/teams/team3x3.game-object';
import {Observable} from 'rxjs';
import {CellStatus, SquareCellGameObject} from '@game/teams/square-cell.game-object';
import {map} from 'rxjs/operators';
import {GridEvent, GridOrientation} from '@game/teams/team3x3';
import * as PIXI from 'pixi.js';
import {gsap, Power2} from 'gsap';
import {GlowFilter} from '@pixi/filter-glow';
import {Rarity} from '../../../projects/game/src/app/hud/shared/stats/rarity';
import {colorForRarity, ColorUncommon} from '@game/colors/colors';

export class CombatantGameObject extends PIXI.Container {

  private teamGameObject: Team3x3GameObject;

  constructor(private combatant: Combatant, orientation: GridOrientation, shipSize: number) {
    super();
    this.teamGameObject = new Team3x3GameObject(this.combatant.getTeam(), orientation, shipSize);
    this.addChild(this.teamGameObject);

    if (this.combatant.getDisplayName()) {
      const txtName = new PIXI.Text(this.combatant.getDisplayName(), {
        fontSize: '18px',
        fill: 0xEEEEEE,
        weight: 300,
      });
      txtName.position = this.getCenter().clone();
      txtName.position.y = SquareCellGameObject.SQUARE_HEIGHT + 10;
      txtName.anchor.set(0.5, 0);
      //this.addChild(txtName);
    }

    if (combatant.getRarity() != Rarity.Common) {
      let color = colorForRarity(combatant.getRarity());
      this.filters = [new GlowFilter({color: color, innerStrength: 1, outerStrength: 3})];
    }
  }

  getLargestShipSize(): number {
    if (this.teamGameObject) {
      return this.teamGameObject.getLargestShipSize();
    }
    return 0;
  }

  updateShipSizes(refSize: number, animDuration: number): void {
    if (this.teamGameObject) {
      const largestOfTeam = this.getLargestShipSize();

      // Reduce the size of the whole team object based on the reference. The default would be the largest ship
      // of the battle group.
      const f = largestOfTeam / refSize;
      if (animDuration) {
        gsap.to(this.scale, {
          x: f,
          y: f,
          duration: animDuration,
          ease: Power2.easeInOut,
        });
      } else {
        this.scale.set(f);
      }

      // Update the team cells and ships based on the largest ship in the team.
      this.teamGameObject.updateShipSizes(f, largestOfTeam, animDuration);
    }
  }

  update(delta: number): void {
    // TODO Update all entities
  }

  afterFieldLeft(): Observable<GridEvent> {
    return this.teamGameObject.cellLeft$.pipe(
      map(evt => {
        evt.combatant = this;
        return evt;
      })
    );
  }

  afterFieldEntered(): Observable<GridEvent> {
    return this.teamGameObject.cellEntered$.pipe(
      map(evt => {
        evt.combatant = this;
        return evt;
      })
    );
  }

  afterFieldLeftClicked(): Observable<GridEvent> {
    return this.teamGameObject.cellLeftClicked$.pipe(
      map(evt => {
        evt.combatant = this;
        return evt;
      })
    );
  }

  afterFieldRightClicked(): Observable<GridEvent> {
    return this.teamGameObject.cellRightClicked$.pipe(
      map(evt => {
        evt.combatant = this;
        return evt;
      })
    );
  }

  highlight(shipId: string, type: CellStatus): void {
    this.teamGameObject.highlight(shipId, type);
  }

  clearHighlights(type: CellStatus): void {
    this.teamGameObject.clearStyling(type);
  }

  getCombatant(): Combatant {
    return this.combatant;
  }

  getCenter(): PIXI.Point {
    let p = this.position.clone();
    p.x -= SquareCellGameObject.SQUARE_WIDTH / 2;
    p.y -= SquareCellGameObject.SQUARE_HEIGHT / 2;
    return p;
  }

  getGlobalCenter(): PIXI.Point {
    let p = this.getGlobalPosition().clone();
    p.x -= SquareCellGameObject.SQUARE_WIDTH / 2;
    p.y -= SquareCellGameObject.SQUARE_HEIGHT / 2;
    return p;
  }
}
