import {AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {StarSystemService} from '../services/star-system.service';
import {screen$} from './screen';
import {GalaxyService} from '../services/galaxy.service';
import {AppService} from './app.service';
import {SceneManagerService} from './scenes/scene-manager.service';
import {PartyService} from '../services/party.service';

export interface Scene {
  update(delta: number): void;
}

@Component({
  selector: 'wg-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent implements OnInit, AfterViewInit {

  @ViewChild('pixic') pixiContainer!: ElementRef;

  constructor(public appService: AppService,
              public galaxyService: GalaxyService,
              public partyService: PartyService,
              public starSystemService: StarSystemService,
              public sceneManger: SceneManagerService) {
    // Empty
  }

  ngOnInit(): void {
    window.addEventListener('resize', () => {
      this.handleResize();
    });
    this.handleResize();

    this.appService.init();
    this.sceneManger.init();
  }

  ngAfterViewInit(): void {
    if (this.pixiContainer) {
      this.pixiContainer.nativeElement.appendChild(this.appService.engine.view);
    } else {
      throw new Error('No container');
    }
  }

  @HostListener('contextmenu', ['$event'])
  stopRightClick(evt: Event): boolean {
    evt.preventDefault();
    evt.stopImmediatePropagation();
    return false;
  }

  private handleResize(): void {
    console.log('[Resize] to ', window.innerWidth, window.innerHeight);

    // Wait a second for the changed engine size to be applied.
    setTimeout(() => {
      screen$.next({
        width: this.appService.engine.view.width,
        height: this.appService.engine.view.height,
      });
    }, 100);
  }

}
