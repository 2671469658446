import * as PIXI from 'pixi.js';
import {Scene} from '../../app.component';
import {AppService} from '../../app.service';
import {ShipDebugGameObject} from './ship-debug.game-object';
import {BattleDebugGameObject} from './battle-debug.game-object';
import {NebulaBiomeGameObject} from '@game/biomes/nebula-biome.game-object';
import {debugOutputAstAsTypeScript} from '@angular/compiler';
import {EffectManager} from '@game/vfx/effect.manager';

const SHIP_DISTANCE = 200;

export class DebugScene extends PIXI.Container implements Scene {

  activeView: PIXI.Container | null = null;
  private biome: NebulaBiomeGameObject;
  private viewport: PIXI.Container;

  constructor(private appService: AppService) {
    super();

    this.viewport = new PIXI.Container();

    // Restore the viewport position from sessionStorage for faster debuggin.
    const oldX = sessionStorage.getItem('WG_DEBUG_CAMERA_POS_X');
    const oldY = sessionStorage.getItem('WG_DEBUG_CAMERA_POS_Y');

    this.viewport.position.set(
      oldX ? +oldX : 0,
      oldY ? +oldY : 0,
    );

    console.log('[Scene:Debug] created');

    this.biome = new NebulaBiomeGameObject();
    this.biome.attachTo(this);

    const txt = new PIXI.Text('DEBUG', {fontSize: '20px', fill: 'red'});
    txt.position.x = 20;
    txt.position.y = 20;
    txt.anchor.set(0, 0);
    this.addChild(txt);

    this.addChild(this.viewport);

    this.showView('ships');

    EffectManager.instance.setParent(this);

    window.addEventListener('keydown', this.handleKeys.bind(this));
  }

  handleKeys(evt: KeyboardEvent): void {
    let keepDefault = false;
    if (evt.key === 'F1') {
      this.showView('ships');
    } else if (evt.key === 'F2') {
      this.showView('battle');
    } else {
      keepDefault = true;
    }

    if (!keepDefault) {
      evt.preventDefault();
      evt.stopImmediatePropagation();
    }

    let posChanged = false;
    if (evt.key === 'ArrowUp') {
      posChanged = true;
      this.viewport.position.y += 20;
    } else if (evt.key === 'ArrowDown') {
      posChanged = true;
      this.viewport.position.y -= 20;
    }

    if (evt.key === 'ArrowLeft') {
      posChanged = true;
      this.viewport.position.x += 20;
    } else if (evt.key === 'ArrowRight') {
      posChanged = true;
      this.viewport.position.x -= 20;
    }

    if (evt.key === '-') {
      this.viewport.scale.set(this.viewport.scale.x - 0.1);
    } else if (evt.key === '+') {
      this.viewport.scale.set(this.viewport.scale.x + 0.1);
    }

    if (posChanged) {
      // Store for faster reload
      sessionStorage.setItem('WG_DEBUG_CAMERA_POS_X', '' + this.viewport.x);
      sessionStorage.setItem('WG_DEBUG_CAMERA_POS_Y', '' + this.viewport.y);
    }

    if (!!(this.activeView as any).handleKeys) {
      (this.activeView as any).handleKeys(evt);
    }
  }

  showView(view: string): void {
    if (this.activeView) {
      this.activeView.destroy({
        children: true,
      });
    }

    switch (view) {
      case 'ships':
        this.activeView = new ShipDebugGameObject(this.appService);
        this.viewport.addChild(this.activeView);
        break;

      case 'battle':
        this.activeView = new BattleDebugGameObject(this.appService);
        this.viewport.addChild(this.activeView);
        break;
    }
  }

  update(delta: number): void {
    if ((this.activeView as any).update) {
      (this.activeView as any).update(delta);
    }

    this.biome.update(delta);
  }
}
