import {Injectable} from '@angular/core';
import {GalaxyService} from './galaxy.service';
import {QuestCompletedEvent, StarSystemService} from './star-system.service';
import {CharacterData, InventoryData, ItemStackData, TeamData} from '@game/characters/character';
import {BehaviorSubject} from 'rxjs';
import {ShipBoughtEvent, ShipUpgradedEvent} from './character.service';
import {ShipData} from '@game/ships/ship';
import {ShipChanges} from '@game/battles/events';
import {Environment} from '@game/environments/environment';

export interface LootReceivedEvent {
  scrap: number;
  crystals: number;
  received: ItemStackData[];
  lost: ItemStackData[];

  changes: InventoryChange[];
}

export interface ItemSoldEvent {
  position: number;
  price: number;
  item: ItemStackData;
}

export interface InventoryChange {
  position: number;
  mode: 'r' | 'm';
  change: number;
  stack?: ItemStackData;
}

export interface ItemBoughtEvent {
  changes: InventoryChange[];
  price: number;
  boughtStack: ItemStackData;
}

export interface ItemMovedEvent {
  inventoryChanges: InventoryChange[];
  team?: TeamData;
}

export interface ModuleEquippedEvent {
  inventoryChanges: InventoryChange[];
  team?: TeamData;
}

export interface ModuleMovedEvent {
  team?: TeamData;
}

export interface EnvironmentChangedEvent {
  environment: Environment;
  character: CharacterData;
  team: TeamData;
}

export interface HangarMovedEvent {
  hangar: { [pos: number]: ShipData };
}

export interface ShipsExchangedEvent {
  hangar: { [pos: number]: ShipData };
  team: TeamData;
}

export interface ShipsRepairedEvent {
  price: number;
  shipChanges: { [shipId: string]: ShipChanges }
}

export interface ShipsRevivedEvent {
  price: number;
  shipChanges: { [shipId: string]: ShipChanges }
}

export interface TeamExperienceEarnedEvent {
  xp: number;
  shipChanges: { [shipId: string]: ShipChanges };
  team?: TeamData;
}

export interface ModuleEnhanceEvent {
  changes: InventoryChange[];
  module: ItemStackData;
  wasCritical: boolean;
  success: boolean;
}

export interface ModuleCombineEvent {
  changes: InventoryChange[];
  module: ItemStackData;
  wasCritical: boolean;
  success: boolean;
}

@Injectable()
export class InventoryService {

  private inventory: InventoryData | null = null;
  private scrap$ = new BehaviorSubject<number>(-1);
  private crystals$ = new BehaviorSubject<number>(-1);

  constructor(private galaxyService: GalaxyService,
              private starSystem: StarSystemService) {

    console.log('[InventoryService] started...');

    this.starSystem.afterSessionConnected().subscribe((evt) => {
      console.log('After session connected')
      this.inventory = evt.character.inventory;
      this.scrap = evt.character.scrap;
      this.crystals = evt.character.crystals;
    });

    this.starSystem.sub('character.died').subscribe(this.handleDeath.bind(this));

    this.starSystem.afterQuestAccepted().subscribe((evt) => {
      // EXTEND: No actions neccessary until quest items arive.
    });

    this.starSystem.afterQuestAborted().subscribe((evt) => {
      // FIXME
    });

    this.starSystem.afterQuestCompleted().subscribe(this.handleQuestCompleted.bind(this));

    this.starSystem.afterLootReceived().subscribe(this.handleLoot.bind(this));
    this.starSystem.sub('item.bought').subscribe(this.handleItemBought.bind(this));
    this.starSystem.sub('item.sold').subscribe(this.handleItemSold.bind(this));
    this.starSystem.sub('item.moved').subscribe(this.handleItemMoved.bind(this));
    this.starSystem.sub('module.equipped').subscribe(this.handleModuleEquipped.bind(this));
    this.starSystem.sub('character.died').subscribe(this.handleDeath.bind(this));
    this.starSystem.sub('module.enhanced').subscribe(this.handleModuleEnhanced.bind(this));
    this.starSystem.sub('module.combined').subscribe(this.handleModuleCombined.bind(this));

    this.starSystem.sub('ship.bought').subscribe(this.handleShipBought.bind(this));
    this.starSystem.sub('ship.upgraded').subscribe(this.handleShipUpgraded.bind(this));
    this.starSystem.sub('ships.repaired').subscribe(this.handleShipsRepaired.bind(this));
    this.starSystem.sub('ships.revived').subscribe(this.handleShipsRevived.bind(this));
  }

  get scrap(): number {
    return this.scrap$.getValue();
  }

  set scrap(n: number) {
    this.scrap$.next(n);
  }

  get crystals(): number {
    return this.crystals$.getValue();
  }

  set crystals(n: number) {
    this.crystals$.next(n);
  }

  moveItem(from: number, to: number): Promise<any> {
    return this.starSystem.rpc({
      method: 'MoveItem',
      params: {
        from: from,
        to: to,
      },
    });
  }

  equipModule(shipPos: number, slotName: string, invPosition: number): Promise<any> {
    return this.starSystem.rpc({
      method: 'EquipModule',
      params: {
        shipPos: shipPos,
        slotName: slotName,
        invPos: invPosition,
      },
    });
  }

  moveModule(fromShip: number, fromSlot: string, toShip: number, toSlot: string): Promise<any> {
    return this.starSystem.rpc({
      method: 'MoveModule',
      params: {
        fromShip: fromShip,
        fromSlot: fromSlot,
        toShip: toShip,
        toSlot: toSlot,
      },
    });
  }

  watchScrap(): BehaviorSubject<number> {
    return this.scrap$;
  }

  watchCrystals(): BehaviorSubject<number> {
    return this.crystals$;
  }

  private handleDeath(evt: any): void {
    this.scrap -= evt.lostScrap;
  }

  private handleQuestCompleted(evt: QuestCompletedEvent): void {
    this.scrap += evt.scrap;
    this.crystals += evt.crystals;
    this.applyInventoryChanges(evt.changes);
  }

  private handleLoot(evt: LootReceivedEvent): void {
    console.log('[Loot] Got loot', evt);

    if (evt.scrap > 0) {
      this.scrap += evt.scrap;
    }

    if (evt.crystals > 0) {
      this.crystals += evt.crystals;
    }

    this.applyInventoryChanges(evt.changes);

    // TODO Show earned and lost items.
  }

  private handleItemBought(evt: ItemBoughtEvent): void {
    if (evt.price > 0) {
      this.scrap -= evt.price;
    }
    this.applyInventoryChanges(evt.changes);
  }

  private applyInventoryChanges(changes: InventoryChange[]): void {
    // Increase the stack size or place the item to the slot.
    const inv = this.inventory;
    if (!inv) {
      throw new Error('cannot apply changes to inventory. inventory not loaded.')
    }

    changes.forEach(change => {

      if (change.mode === 'r') {
        if (change.stack === null) {
          delete (inv.items[change.position]);
        } else {
          inv.items[change.position] = JSON.parse(JSON.stringify(change.stack));
        }
      } else if (change.mode === 'm') {
        inv.items[change.position].num += change.change;
      }
    });
  }

  private handleItemSold(evt: ItemSoldEvent): void {
    if (!this.inventory) {
      throw new Error('inventory not loaded');
    }

    this.scrap += evt.price;

    delete this.inventory.items[evt.position];
  }

  private handleItemMoved(evt: ItemMovedEvent): void {
    if (evt.inventoryChanges) {
      this.applyInventoryChanges(evt.inventoryChanges);
    }
  }

  private handleModuleEquipped(evt: ModuleEquippedEvent): void {
    if (evt.inventoryChanges) {
      this.applyInventoryChanges(evt.inventoryChanges);
    }
  }

  private handleModuleEnhanced(evt: ModuleEnhanceEvent): void {
    if (evt.changes) {
      this.applyInventoryChanges(evt.changes);
    }

    // TODO Show some sort of message
  }

  getSize(): number {
    if (!this.inventory) {
      throw new Error('Inventory not loaded');
    }
    return this.inventory.size;
  }

  getSpace(): number {
    if (!this.inventory) {
      throw new Error('Inventory not loaded');
    }
    return this.inventory.size - Object.keys(this.inventory.items).length;
  }

  getInventory(): InventoryData {
    if (!this.inventory) {
      throw new Error('Inventory not loaded');
    }
    return this.inventory;
  }

  lockSlot(pos: any): boolean {
    if (!this.inventory) {
      throw new Error('Inventory not loaded');
    }

    if (this.inventory.items[pos].locked) {
      return false;
    }
    this.inventory.items[pos].locked = true;
    return true;
  }

  unlockSlot(pos: any): void {
    if (!this.inventory) {
      throw new Error('Inventory not loaded');
    }

    this.inventory.items[pos].locked = false;
  }

  private handleModuleCombined(evt: ModuleCombineEvent): void {
    if (evt.changes) {
      this.applyInventoryChanges(evt.changes);
    }

    // TODO Show some sort of message
  }

  private handleShipBought(evt: ShipBoughtEvent): void {
    // this.applyInventoryChanges(evt.changes);
    this.scrap -= evt.price;
  }

  private handleShipUpgraded(evt: ShipUpgradedEvent): void {
    this.applyInventoryChanges(evt.changes);
    this.scrap -= evt.price;
  }

  private handleShipsRepaired(evt: ShipsRepairedEvent): void {
    this.scrap -= evt.price;
  }

  private handleShipsRevived(evt: ShipsRevivedEvent): void {
    this.scrap -= evt.price;
  }
}
