import {StarSystemService} from '../../../services/star-system.service';
import {Subject} from 'rxjs';
import {CharacterGameObject} from './character.game-object';
import {takeUntil} from 'rxjs/operators';
import {BiomeFactory} from '@game/biomes/biome.factory';
import {BiomeGameObject} from '@game/biomes/biome.game-object';

import gsap from 'gsap';
import * as PIXI from 'pixi.js';
import {screenHeight, screenWidth} from '../../screen';
import {EntityFactory} from '@game/entities/entity.factory';
import {EntityGameObject} from '@game/entities/entity.game-object';
import {Scene} from '../../app.component';
import {EntityLayer, parallaxLayerByZIndex, parallaxLayerFactor} from '@game/entities/map';
import {InteractionService} from '../../hud/interactions/interaction.service';
import {CharacterService} from '../../../services/character.service';
import {EntityConfig} from '@game/entities/entity';
import {HudService} from '../../hud/hud.service';
import {MapGameObject} from '../map/map.game-object';
import {QuestService} from '../../../services/quest.service';
import {EffectManager} from '@game/vfx/effect.manager';
import {EntityRemovedData, MapService} from '../../../services/map.service';
import {EnvironmentFactory} from '@game/environments/environment.factory';

export class StarsystemScene extends PIXI.Container implements Scene {

  private character!: CharacterGameObject;

  private entityComponents: Map<number, EntityGameObject> = new Map<number, EntityGameObject>();

  private biomes: BiomeGameObject[] = [];
  private environments: BiomeGameObject[] = [];

  private destroyed$ = new Subject<void>();

  private viewport: PIXI.Container;
  private parallaxLayer: PIXI.Container[] = [];
  private map: MapGameObject | null = null;

  private inputEnabled: boolean = true;
  private lastSelection: null | EntityGameObject = null;

  /**
   * Initialize this scene by providing the central game service a
   * and activation this scene immediately.
   */
  constructor(private starSystemService: StarSystemService,
              private mapService: MapService,
              private characterService: CharacterService,
              private app: PIXI.Application,
              private hudService: HudService,
              private questService: QuestService,
              private interactionService: InteractionService) {
    super();

    console.log('[SystemViewScene] init');

    // create viewport
    this.viewport = new PIXI.Container();
    this.viewport.sortableChildren = true;
    this.viewport.zIndex = 0;

    // add the viewport to the stage
    this.addChild(this.viewport);

    // Create all parallax layers.
    for (let i = 0; i < 10; i++) {
      const l = new PIXI.Container();

      // Set the z-indizes to 5, 15, 25..
      l.zIndex = (i * 10) + 5;

      this.parallaxLayer[i] = l;
      this.viewport.sortableChildren = true;
      this.viewport.addChild(l);
    }

    // Prepare the initial state to ensure preloading
    this.prepareBiome();
    this.prepareEnvironment();
    this.createEntities();

    this.watchMapState();

    this.create();

    this.prepareCharacter();

    window.addEventListener('wheel', this.handleZoom.bind(this));
    window.addEventListener('keyup', this.handleKeys.bind(this));

    EffectManager.instance.setParent(this);
  }

  handleKeys(evt: KeyboardEvent): void {
    if (evt.code === 'KeyM') {
      this.toggleMap();
    }
  }

  handleZoom(evt: WheelEvent): void {
    if (evt.deltaY > 10) {
      this.showMap();
    } else if (evt.deltaY < 10 && (this.map && this.map.scale.x >= 0.3)) {
      this.hideMap();
    }
  }

  update(delta: number): void {

    this.character.update(delta);

    this.entityComponents.forEach(cmp => {
      cmp.update(delta);
    });

    const target = {
      x: -this.character.x + screenWidth() / 2,
      y: -this.character.y + screenHeight() / 2,
    };

    const diff = {
      x: target.x - this.viewport.x,
      y: target.y - this.viewport.y,
    };

    const mult = 1;
    // if (Math.abs(diff.x) > 100 || Math.abs(diff.y) > 100) {
    //   mult = 0.1;
    // }

    this.viewport.x += diff.x * mult;
    this.viewport.y += diff.y * mult;

    this.parallaxLayer.forEach((layer, i) => {
      // Update the parallax layer based on the position of user of the center of the
      // map. Scale the difference by 20%.
      const parallaxOffset = {
        x: (this.mapService.getMapWidth() / 2) - this.character.x,
        y: (this.mapService.getMapHeight() / 2) - this.character.y,
      }

      const f = parallaxLayerFactor(i);
      layer.x = -1 * parallaxOffset.x * f;
      layer.y = -1 * parallaxOffset.y * f;
    });

    // Update last to track keep in touch with the movement.
    if (this.biomes) {
      for (const biome of this.biomes) {
        biome.update(delta);
      }
    }

    if (this.environments) {
      for (const env of this.environments) {
        env.update(delta);
      }
    }
  }

  create(): void {
    if (this.biomes && this.biomes.length > 0) {
      this.biomes[0].attachTo(this.viewport);
    }
    if (this.environments && this.environments.length > 0) {
      this.environments.forEach(env => {
        env.attachTo(this.viewport);
      });
    }
  }

  destroy(options?: {
    children?: boolean;
    texture?: boolean;
    baseTexture?: boolean;
  }): void {
    console.log('[SystemView] destroying...');

    this.destroyed$.next();
    this.destroyed$.complete();

    if (this.biomes) {
      this.biomes.forEach(biome => biome.destroyAfter(0));
    }

    if (this.environments) {
      this.environments.forEach(env => env.destroyAfter(0));
    }

    // Destroy the nested components
    super.destroy(options);

    // Destroy listeners
    window.removeEventListener('wheel', this.handleZoom.bind(this));
    window.removeEventListener('keyup', this.handleKeys.bind(this));
  }

  private switchBiome(biomeType: string): void {
    console.log('Biome changed to ', biomeType);

    if (!biomeType) {
      this.biomes.forEach(biome => biome.destroyAfter(500));
      return;
    }

    const newBiome = BiomeFactory.create(biomeType);
    const numBiomes = this.biomes.length;

    if (!newBiome) {
      console.error('biome switch aborted: no new biome created');
      return;
    }

    if (numBiomes > 0) {

      // Only destroy the last / newest one.
      this.biomes[numBiomes - 1].destroyAfter(500);

      // Remove one biome from the stack. It is important that we remove the first entry.
      setTimeout(() => {
        this.biomes.splice(0, 1);
      }, 500);
    }

    // Always add the new biome to the stack
    newBiome.attachTo(this.viewport); // TODO after 1s
    this.biomes.push(newBiome);
  }

  private prepareBiome(): void {
    const biomeType = this.mapService.getBiome();
    console.log('[Biome] prepare ', biomeType);
    if (!biomeType) {
      return;
    }

    const newBiome = BiomeFactory.create(biomeType);
    if (!newBiome) {
      console.error('biome switch aborted: no new biome created');
      return;
    }

    this.biomes.push(newBiome);
  }

  private switchEnvironment(environmentType: string): void {
    console.log('Environment changed to ', environmentType);

    this.environments.forEach(environment => environment.destroyAfter(500));
    this.environments = [];

    const newEnvironment = EnvironmentFactory.create(environmentType);
    const numEnvironments = this.environments.length;

    if (!newEnvironment) {
      console.error('environment switch aborted: no new environment created');
      return;
    }

    if (numEnvironments > 0) {
      // Only destroy the last / newest one.
      this.environments[numEnvironments - 1].destroyAfter(500);

      // Remove one environment from the stack. It is important that we remove the first entry.
      setTimeout(() => {
        this.environments.splice(0, 1);
      }, 500);
    }

    // Always add the new environment to the stack
    newEnvironment.attachTo(this.viewport); // TODO after 1s
    this.environments.push(newEnvironment);
  }

  private prepareEnvironment(): void {
    const environmentType = this.mapService.getEnvironment()
    console.log('[Environment] prepare ', environmentType);
    if (!environmentType) {
      return;
    }

    const styles = environmentType.styles;

    if (styles) {
      styles.forEach(entry => {
        const newEnvironment = EnvironmentFactory.create(entry.type, entry.config);
        if (!newEnvironment) {
          console.error(`[Env] unable to load environment style: ${entry.type}`);
          return;
        }

        this.environments.push(newEnvironment);
      });
    }
  }

  private prepareCharacter(): void {
    this.characterService.watchCharacter().subscribe((char) => {
      this.character = new CharacterGameObject(char, this.characterService);
      this.character.zIndex = EntityLayer.CHARACTER;
      this.character.interactive = true;

      this.viewport.addChild(this.character);
    });
  }

  private createEntities(): void {
    console.log('[SystemViewScene] prepare entities');
    this.entityComponents.clear();

    // Load all visibile entities*
    const entities = this.mapService.getEntities();

    entities.forEach((entity: EntityConfig) => {
      // Create the entities with a slower fadein to have a nicer transition
      // on startup.
      this.createEntity(entity, 2);
    });
  }

  private watchMapState(): void {
    this.mapService.afterBiomeChanged().pipe(
      takeUntil(this.destroyed$),
    ).subscribe(b => {
      console.log('Change Biome to:', b);
      this.switchBiome(b);
    });

    this.mapService.afterEnvironmentChanged().pipe(takeUntil(this.destroyed$)).subscribe(env => {
      console.log('Environment changed to ', env);
      this.switchEnvironment(env.identifier);
    });

    this.mapService.afterDangerLevelChanged().pipe(takeUntil(this.destroyed$)).subscribe(dl => {
      console.log('DangerLevel changed to ', dl);
    });

    this.mapService.afterEntityAdded().pipe(takeUntil(this.destroyed$)).subscribe((entity) => {
      console.log('Entity added', entity);
      this.createEntity(entity);
    });

    this.mapService.afterEntityRemoved().pipe(takeUntil(this.destroyed$)).subscribe((evt: EntityRemovedData) => {
      console.log('Entity removed', evt.index, evt.destroyed);

      const cmp = this.entityComponents.get(evt.index);
      if (cmp) {
        if ((cmp as any).die && evt.destroyed) {
          (cmp as any).die();
        } else {
          cmp.destroy();
        }
        this.entityComponents.delete(evt.index);
      }
    });

    this.mapService.afterSelectedEntityChanged().pipe(takeUntil(this.destroyed$)).subscribe((selEn) => {
      if (!!this.lastSelection) {
        this.lastSelection.removeSelected();
        this.lastSelection = null;
      }

      if (selEn) {
        const newSelection = this.entityComponents.get(selEn.entityId);
        if (newSelection) {
          newSelection.markSelected();
          this.lastSelection = newSelection;
        }
      }
    });
  }

  private createEntity(entity: EntityConfig, fadeIn: number = 0.3): void {

    const cmp = EntityFactory.create(entity, {
      charRef: this.characterService.getCharacter(),
      onSelect: () => {
        this.mapService.setSelectedEntity(entity);
      },
      onInteraction: () => {
        if (entity.faction === this.characterService.getCharacter().getFaction()) {
          if (cmp.hasTraits()) {
            console.log('[StarSystem] Start interaction with ', entity.entityId);
            this.interactionService.startInteractionWith(entity);
          }
        } else if (entity.isCommander) {
          console.log('[StarSystem] Start battle with ', entity.entityId);
          this.starSystemService.startBattleWith(entity);
        }
      },
    });

    this.entityComponents.set(entity.entityId, cmp);

    // Animate a fade-in
    gsap.from(cmp, {alpha: 0, duration: fadeIn});

    const pl = parallaxLayerByZIndex(cmp.zIndex);
    const f = parallaxLayerFactor(pl);
    cmp.parallaxFactor = f;
    cmp.scale.set(1 - Math.pow(f, 2));
    cmp.parallaxCenter = {
      x: this.mapService.getMapWidth() / 2,
      y: this.mapService.getMapHeight() / 2,
    };
    if (this.parallaxLayer[pl]) {
      this.parallaxLayer[pl].addChild(cmp);
    } else {
      console.error('unable to add child - parallax layer not found', entity);
    }
  }

  private toggleMap(): void {
    if (this.map) {
      this.hideMap();
    } else {
      this.showMap();
    }
  }

  private showMap(): void {
    if (this.map) {
      return;
    }

    this.character.interactive = false;

    // Prepare the map and the map handling.
    this.map = new MapGameObject(this.starSystemService, this.mapService, this.characterService, this.questService);
    this.map.alpha = 0;
    this.addChild(this.map);

    gsap.to(this.viewport, {
      alpha: 0,
      duration: 0.5,
    });

    gsap.to(this.map, {
      alpha: 1,
      duration: 0.7,
    });
  }

  private hideMap(): void {
    if (!this.map) {
      return;
    }

    this.character.interactive = true;

    gsap.to(this.viewport, {
      alpha: 1,
      duration: 0.5,
    });

    gsap.to(this.map, {
      alpha: 0,
      duration: 0.7,
      onComplete: () => {
        if (this.map) {
          this.map.destroy({
            children: true,
          });
        }
        this.map = null;
      }
    });
  }

}
