import {Subject} from 'rxjs';
import {GridEvent, GridOrientation, Team3x3} from '@game/teams/team3x3';
import {CellStatus, SquareCellGameObject} from '@game/teams/square-cell.game-object';
import * as PIXI from 'pixi.js';
import {EffectManager} from '@game/vfx/effect.manager';
import {HitPattern} from '@game/battles/skills';

export const GRID_DISTANCE_X = SquareCellGameObject.SQUARE_WIDTH + 1;
export const GRID_DISTANCE_Y = SquareCellGameObject.SQUARE_HEIGHT + 1;

export class Team3x3GameObject extends PIXI.Container {

  public cellLeftClicked$ = new Subject<GridEvent>();
  public cellRightClicked$ = new Subject<GridEvent>();
  public cellLeft$ = new Subject<GridEvent>();
  public cellEntered$ = new Subject<GridEvent>();
  /** The sprite container for this grid */

  private cmpCells: SquareCellGameObject[] = [];

  constructor(private team: Team3x3, private orientation: GridOrientation, shipSize: number) {
    super();
    this.createCells(shipSize);
    EffectManager.instance.registerEntity(this.team.teamId, this);
  }

  destroy(options?: { children?: boolean; texture?: boolean; baseTexture?: boolean }): void {
    EffectManager.instance.removeEntity(this.team.teamId);
    super.destroy(options);
  }

  getPositionForShip(shipId: string): number {
    return this.team.getPosition(shipId);
  }

  highlightPattern(shipId: string, pattern: HitPattern): void {
    console.log('Highlight Pattern', pattern);

    const pos = this.getPositionForShip(shipId) - 1;
    const row = pos % 3;
    const col = Math.floor(pos / 3);

    let targets: number[] = [];

    switch (pattern) {
      case HitPattern.Single:
        return;
      case HitPattern.All:
        targets = [0, 1, 2, 3, 4, 5, 6, 7, 8];
        break;
      case HitPattern.Pierce3:
        switch (col) {
          case 0:
            targets = [pos, pos + 3, pos + 6];
            break;
          case 1:
            targets = [pos, pos + 3];
            break;
          case 2:
            targets = [pos];
            break;
        }
        break;
      case HitPattern.Cross:
        switch (col) {
          case 0:
            targets = [pos, pos + 3];
            break;
          case 1:
            targets = [pos - 3, pos, pos + 3];
            break;
          case 2:
            targets = [pos - 3, pos];
            break;
        }

        switch (row) {
          case 0:
            targets.push(pos + 1);
            break;
          case 1:
            targets.push(pos - 1, pos + 1);
            break;
          case 2:
            targets.push(pos - 1);
            break;
        }

        break;
      case HitPattern.Pierce2:
        targets = [pos, pos + 3];
        break;
      case HitPattern.Wide3:
        switch (row) {
          case 0:
            targets = [pos, pos + 1];
            break;
          case 1:
            targets = [pos - 1, pos, pos + 1];
            break;
          case 2:
            targets = [pos - 1, pos];
            break;
        }

        break;
    }

    targets.forEach(i => {

      // Skip faulty values
      if (i < 0 || i > 8) {
        return;
      }

      // Convert back from index to position to match the internal system.
      const p = i + 1;
      this.cmpCells[p].addStatus(CellStatus.Pattern);
    });

  }

  /**
   * Visually activate a field to show that it is this ships turn. Ignore if the ship
   * does not belong to this team.
   */
  highlight(shipId: string, style: CellStatus): void {
    const pos = this.getPositionForShip(shipId);
    if (pos >= 0) {
      this.cmpCells[pos].addStatus(style);
    }
  }

  clearStyling(type: CellStatus): void {
    this.cmpCells.forEach(cell => {
      cell.removeStatus(type);
    });
  }

  getLargestShipSize(): number {
    return this.cmpCells.map(cell => {
      return cell.getShipSize();
    }).reduce((p, c) => Math.max(p,c), 0);
  }

  // Update the internal ship sizes base on the largest ship
  // in this team.
  updateShipSizes(globalScale: number, refSize: number, animDuration: number): void {
    if (!this.cmpCells) {
      return;
    }

    this.cmpCells.forEach(cell => {
      cell.updateShipSize(globalScale, refSize, animDuration);
    });
  }

  /**
   * Create the grid fields and attempt them to the scene.
   */
  private createCells(shipSize: number): void {

    let grid = [];

    switch (this.orientation) {
      case GridOrientation.TOP:
        grid = [
          1, 2, 3,
          4, 5, 6,
          7, 8, 9];
        break;
      case GridOrientation.LEFT:
        grid = [
          3, 6, 9,
          2, 5, 8,
          1, 4, 7];
        break;
      case GridOrientation.RIGHT:
        grid = [
          7, 4, 1,
          8, 5, 2,
          9, 6, 3];
        break;
      case GridOrientation.DOWN:
        grid = [
          9, 8, 7,
          6, 5, 4,
          3, 2, 1];
        break;
      default:
        throw new Error('unknown orientation');
    }

    // Change the order based on the orientation
    for (let i = 0; i < 9; i++) {

      const entry = grid[i];
      const x = i % 3;
      const y = Math.floor(i / 3);

      const posX = GRID_DISTANCE_X * x - (GRID_DISTANCE_X * 3 / 2);
      const posY = GRID_DISTANCE_Y * y - (GRID_DISTANCE_Y * 3 / 2);

      const cmp = new SquareCellGameObject(this.team, this.orientation, entry, shipSize);
      cmp.x = posX;
      cmp.y = posY;
      this.addChild(cmp);
      this.cmpCells[entry] = cmp;

      // Make that field hoverable
      cmp.enableInteraction();

      cmp.pointerOver$.subscribe(evt => this.onPointerOver(evt));
      cmp.pointerOut$.subscribe(evt => this.onPointerOut(evt));
      cmp.leftDown$.subscribe(evt => this.onLeftDown(evt));
      cmp.rightDown$.subscribe(evt => this.onRightDown(evt));
    }
  }

  private onPointerOver(evt: GridEvent): void {
    evt.team = this;
    this.cellEntered$.next(evt);
  }

  private onPointerOut(evt: GridEvent): void {
    evt.team = this;
    this.cellLeft$.next(evt);
  }

  private onLeftDown(evt: GridEvent): void {
    evt.team = this;
    this.cellLeftClicked$.next(evt);
  }

  private onRightDown(evt: GridEvent): void {
    evt.team = this;
    this.cellRightClicked$.next(evt);
  }
}
