import * as PIXI from 'pixi.js';
import {Rarity} from '../../../projects/game/src/app/hud/shared/stats/rarity';
import ColorMatrixFilter = PIXI.filters.ColorMatrixFilter;

export const ColorFallback: number = 0x757575;
export const ColorCommon: number = 0xFFFFFF;
export const ColorUncommon: number = 0x1A57AD;
export const ColorRare: number = 0xffeb3b;
export const ColorLegendary: number = 0x7B1FA2;

// export const ColorEpic: number = 0x4CAF50;

export function colorForRarity(r: Rarity): number {
  switch (r) {
    case Rarity.Common:
      return ColorCommon;
    case Rarity.Uncommon:
      return ColorUncommon;
    case Rarity.Rare:
      return ColorRare;
    case Rarity.Legendary:
      return ColorLegendary;
  }
}

export function filterForFaction(faction: string): ColorMatrixFilter {
  const filter = new ColorMatrixFilter();

  let hue = 0;
  switch (faction) {
    case 'ehe':
      filter.hue(205, false);
      // filter.saturate(-1, true);
      break;
    case 'pirates':
      filter.hue(0, false);
      filter.saturate(-0.2, true);
      break;
    default:
      filter.hue(130, false);
      break;
  }

  return filter;
}
