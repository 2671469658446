import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {StarSystemService} from '../../../services/star-system.service';
import {Battle} from '@game/battles/battle';
import {BattleService} from '../../../services/battle.service';

@Component({
  selector: 'wg-battle-round-time-hud',
  styleUrls: ['./battle-round-time-hud.component.less'],
  templateUrl: './battle-round-time-hud.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BattleRoundTimeHudComponent implements OnInit, OnDestroy {

  roundTime: number = 0;
  initialRoundTime: number = 0;
  private roundTimeDecr: null | any = null;

  constructor(private starSystemService: StarSystemService,
              private battleService: BattleService,
              private cdr: ChangeDetectorRef) {
  }

  get battle(): Battle {
    const b = this.battleService.getBattle();

    if (!b) {
      throw new Error('battle not started');
    }

    return b;
  }

  ngOnInit(): void {

    this.battle.afterActiveGroupChanged().subscribe(() => {
      this.initialRoundTime = this.roundTime = this.battle.roundTime;
      this.cdr.detectChanges();
    });

    this.battle.afterGroupEnded().subscribe(() => {
      this.roundTime = 0;
      this.cdr.detectChanges();
    });

    this.roundTimeDecr = setInterval(() => {
      this.roundTime -= 1000;
      if (this.roundTime < 0) {
        this.roundTime = 0;
      }
      this.cdr.detectChanges();
    }, 1000);

    // Get the initial time.
    this.initialRoundTime = this.roundTime = this.battle.roundTime;
  }

  ngOnDestroy(): void {
    if (this.roundTimeDecr) {
      clearInterval(this.roundTimeDecr);
    }
  }

}
