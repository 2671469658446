import {Component, OnInit} from '@angular/core';
import {InventoryData} from '@game/characters/character';
import {InventoryService} from '../../../services/inventory.service';
import {HudService, ItemType} from '../hud.service';
import {InteractionService} from '../interactions/interaction.service';
import {StarSystemService} from '../../../services/star-system.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'wg-menu-inventory',
  templateUrl: 'inventory-menu.component.html',
  styleUrls: ['inventory-menu.component.less']
})
export class InventoryMenuComponent implements OnInit {

  get inventory(): InventoryData {
    return this.inventoryService.getInventory();
  }

  scrap$: null | Observable<number> = null;
  crystals$: null | Observable<number> = null;

  constructor(private inventoryService: InventoryService,
              private interactionService: InteractionService,
              private hudService: HudService,
              private starSystemService: StarSystemService) {
  }

  ngOnInit(): void {
    this.scrap$ = this.inventoryService.watchScrap();
    this.crystals$ = this.inventoryService.watchCrystals();
  }

  sellItemAt(pos: number): void {
    this.interactionService.sellItemAt(pos);
  }

  onClick(pos: number): void {
    switch (this.inventory.items[pos].type) {
      case ItemType.CombinationCatalyst:
        this.hudService.toggleCombinationMenu();
        break;
      case ItemType.EnhancementCatalyst:
        this.hudService.toggleEnhancementMenu();
        break;
    }

  }
}
