import * as PIXI from 'pixi.js';
import {screenHeight, screenWidth} from '../../../projects/game/src/app/screen';
import {BiomeGameObject} from '@game/biomes/biome.game-object';
import {gsap} from 'gsap';
import {EntityLayer, parallaxLayerByZIndex, parallaxLayerFactor} from '@game/entities/map';

export class NebulaBiomeGameObject implements BiomeGameObject {
  private lyrBackground: null | PIXI.TilingSprite = null;
  private texture: null | PIXI.Texture = null;

  private lyrBackgroundAnim: null | gsap.core.Tween = null;
  private parallaxFactor: number = 0;

  constructor() {
    console.log('[NebulaBiome] loading...');

    const loader = PIXI.Loader.shared;
    this.texture = loader.resources['biomes/nebula'].texture;

    const pl = parallaxLayerByZIndex(EntityLayer.BACKGROUND - 1);
    this.parallaxFactor = (1 - parallaxLayerFactor(pl)) / 50;

    this.prepare();
  }

  prepare(): void {
    console.log('[NebulaBiome] preparing...');

    if (!this.texture) {
      console.error('missing texture');
      return;
    }

    this.lyrBackground = new PIXI.TilingSprite(this.texture, 2 * screenWidth(), 2 * screenHeight());
    this.lyrBackground.zIndex = EntityLayer.BACKGROUND - 1;
    this.lyrBackground.anchor.set(0, 0);
    this.lyrBackground.alpha = 0.01;
    this.lyrBackground.scale.set(1);
    this.lyrBackground.tileScale.set(0.5);

    gsap.from(this.lyrBackground, {
      alpha: 0,
      duration: 0.5,
      onComplete: () => {
        this.lyrBackgroundAnim = gsap.fromTo(this.lyrBackground, {
          alpha: 0.1
        }, {
          alpha: 0.2,
          duration: 11,
          repeat: -1,
          yoyo: true,
        });
      }
    });
  }

  update(delta: number): void {
    if (this.lyrBackground && this.lyrBackground.parent) {
      const deltaMovementX = this.lyrBackground.parent.x - this.lyrBackground.x - (screenWidth() / 2);
      const deltaMovementY = this.lyrBackground.parent.y - this.lyrBackground.y - (screenHeight() / 2);

      this.lyrBackground.tilePosition.x = deltaMovementX * this.parallaxFactor;
      this.lyrBackground.tilePosition.y = deltaMovementY * this.parallaxFactor;

      this.lyrBackground.x = -this.lyrBackground.parent.x - (screenWidth() / 2);
      this.lyrBackground.y = -this.lyrBackground.parent.y - (screenHeight() / 2);
    }
  }

  destroyAfter(ms: number = 0): void {
    console.log('[Biome:Nebula] destroying...');
    if (this.lyrBackgroundAnim) {
      this.lyrBackgroundAnim.kill();
      this.lyrBackgroundAnim = null;
    }

    gsap.to(this.lyrBackground, {
      alpha: 0,
      onComplete: () => {
        if (this.lyrBackground) {
          this.lyrBackground.destroy();
          this.lyrBackground = null;
        }
      },
      duration: ms / 1000,
    });
  }

  attachTo(parent: PIXI.Container): void {

    if (this.lyrBackground) {
      parent.addChild(this.lyrBackground);
    }

  }
}
