import {Component, Input} from '@angular/core';
import {FlatModRange, FlatModValue, SplitFlatModRange, SplitFlatModValue} from '@game/stats/stat-data';

@Component({
  selector: 'wg-flat-mod-stats',
  templateUrl: './flat-mod-stats.component.html',
})
export class FlatModStatsComponent {
  @Input() name: string = '';
  @Input() stats: null | FlatModValue | SplitFlatModValue = null;
  @Input() source: null | FlatModRange | SplitFlatModRange = null;
  @Input() suffix: string = '';
  @Input() showLocal: boolean = true;
}
