import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {StarSystemService} from '../../../services/star-system.service';
import {CharacterService} from '../../../services/character.service';
import {CharacterInfo, GalaxyService} from '../../../services/galaxy.service';
import {AppService} from '../../app.service';
import {AuthService} from '@shared/services/auth.service';
import {first} from 'rxjs/operators';

enum MenuState {
  AuthCheck = 'authCheck',
  Login = 'login',
  Connecting = 'connecting',
  CharSelect = 'charSelect',
}

@Component({
  selector: 'wg-mainmenu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainMenuComponent implements OnInit {

  MenuState = MenuState;

  characters: CharacterInfo[] = [];
  creatorVisible: boolean = false;
  loading: boolean = true;

  stage!: MenuState;

  name: string = '';
  password: string = '';
  private preselectedCharacter: CharacterInfo | null = null;

  constructor(private authService: AuthService,
              private appService: AppService,
              private galaxyService: GalaxyService,
              private starSystemService: StarSystemService,
              private characterService: CharacterService,
              private cdr: ChangeDetectorRef) {
    // Empty
  }

  ngOnInit(): void {
    console.log('[MainMenuComponent] init');

    this.startAuthCheck();
  }

  private startAuthCheck(): void {
    this.stage = MenuState.AuthCheck;

    this.authService.isAuthenticated().subscribe((result) => {
      if (result) {
        console.log('[MainMenu] Continue to character select..');
        this.startCharacterSelect();
      } else {
        console.log('[MainMenu] Continue to login screen..');
        this.startLogin();
      }
    }, (err) => {
      throw new Error('unable to check:' + err);
    });
  }

  private startLogin(): void {
    this.stage = MenuState.Login;
    this.loading = false;
    this.cdr.detectChanges();
  }

  login(): void {
    this.authService.login(this.name, this.password).subscribe(() => {
      this.startCharacterSelect();
    });
  }

  private startCharacterSelect(): void {
    this.stage = MenuState.Connecting;

    this.galaxyService.sub('welcome').pipe(
      first(),
    ).subscribe(() => {
      this.galaxyService.getCharacters().then(result => {
        const chars = result.characters;
        this.loading = false;
        this.characters = chars;

        this.stage = MenuState.CharSelect;
        this.cdr.detectChanges();
      });
    });

    this.appService.connectToGalaxy();
  }

  showNewCharacterView(): void {
    this.creatorVisible = true;
  }

  create(): void {
    this.galaxyService.createCharacter(this.name).then(result => {

      const char = result.character;

      this.name = '';
      this.creatorVisible = false;
      this.characters.push(char);
      this.cdr.detectChanges();

      this.preselectedCharacter = char;
      this.selectCharacter();

    }, err => {
      console.log(err);
    });
  }

  selectCharacter(): void {
    if (!this.preselectedCharacter) {
      return;
    }
    this.galaxyService.selectCharacter(this.preselectedCharacter.characterId);
  }

  preselectCharacter(char: CharacterInfo): void {
    this.preselectedCharacter = char;
  }
}
