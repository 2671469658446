import {ShipGameObject} from '@game/ships/ship.game-object';
import {Character} from '@game/characters/character';
import {ShipFactory} from '@game/ships/ship.factory';
import * as PIXI from 'pixi.js';
import {CharacterService} from '../../../services/character.service';

const NAME_TAG_OFFSET = {
  x: 0,
  y: 45
};

export class CharacterGameObject extends PIXI.Container {
  private movement = {
    up: false,
    down: false,
    left: false,
    right: false,
  };

  private readonly txtNameTag: PIXI.Text;
  private readonly shipGameObject: null | ShipGameObject;

  // Override is wanted to forward the angle information.

  constructor(private char: Character,
              private characterService: CharacterService) {
    super();

    const visShip = this.char.getVisibleShip();
    if (visShip) {
      this.shipGameObject = ShipFactory.createShipGameObject(visShip, {});
      this.shipGameObject.track(this);

      this.shipGameObject.updateScale(1);

      this.addChild(this.shipGameObject);
    } else {
      console.error('no visible ship found');
      this.shipGameObject = null;
    }

    const pos = this.char.getPosition();
    this.x = pos.x;
    this.y = pos.y;
    this.angle = pos.angle;

    this.txtNameTag = new PIXI.Text(this.char.getName(), {fontSize: '16px', fill: 'white'});
    this.txtNameTag.anchor.set(0.5, 0.5);
    this.txtNameTag.x = NAME_TAG_OFFSET.x;
    this.txtNameTag.y = NAME_TAG_OFFSET.y;
    this.addChild(this.txtNameTag);

    window.addEventListener('keydown', this.keyDownListener.bind(this));
    window.addEventListener('keyup', this.keyUpListener.bind(this));
  }

  // @ts-ignore
  get angle(): number {
    if (this.shipGameObject) {
      return this.shipGameObject.angle;
    }

    return 0;
  }

  set angle(x: number) {
    if (this.shipGameObject) {
      this.shipGameObject.angle = x;
    }
  }

  update(delta: number): void {
    if (this.shipGameObject) {
      this.shipGameObject.update(delta);
    }

    if (this.interactive) {
      this.handleMovement(delta);
    }
  }

  protected keyDownListener(evt: KeyboardEvent): void {
    if (!this.interactive) {
      return;
    }

    if (evt.code === 'KeyW' || evt.code === 'ArrowUp') {
      this.movement.up = true;
      evt.preventDefault();
    }

    if (evt.code === 'KeyS' || evt.code === 'ArrowDown') {
      this.movement.down = true;
      evt.preventDefault();
    }

    if (evt.code === 'KeyA' || evt.code === 'ArrowLeft') {
      this.movement.left = true;
      evt.preventDefault();
    }

    if (evt.code === 'KeyD' || evt.code === 'ArrowRight') {
      this.movement.right = true;
      evt.preventDefault();
    }

  }

  protected keyUpListener(evt: KeyboardEvent): void {
    if (!this.interactive) {
      return;
    }

    if (evt.code === 'KeyW' || evt.code === 'ArrowUp') {
      this.movement.up = false;
      evt.preventDefault();
    }

    if (evt.code === 'KeyS' || evt.code === 'ArrowDown') {
      this.movement.down = false;
      evt.preventDefault();
    }

    if (evt.code === 'KeyA' || evt.code === 'ArrowLeft') {
      this.movement.left = false;
      evt.preventDefault();
    }

    if (evt.code === 'KeyD' || evt.code === 'ArrowRight') {
      this.movement.right = false;
      evt.preventDefault();
    }

  }

  protected handleMovement(delta: number): void {

    const moveSpeed = this.char.getMovementSpeed();
    const rotSpeed = this.char.getRotationSpeed();
    const rad = (this.angle / 180) * Math.PI;

    if (this.movement.up) {
      // console.log('UP', this.character.angle, rad, Math.sin(rad), Math.cos(rad));
      this.position.x += Math.sin(rad) * delta * moveSpeed;
      this.position.y -= Math.cos(rad) * delta * moveSpeed;
    }
    // INFO: Backward movement is weird and not required when the user is able to rotate.
    // else if (this.movement.down) {
    //   this.position.x -= Math.sin(rad) * delta * moveSpeed;
    //   this.position.y += Math.cos(rad) * delta * moveSpeed;
    // }

    if (this.movement.left) {
      this.angle = (this.angle - rotSpeed * delta) % 360;
    } else if (this.movement.right) {
      this.angle = (this.angle + rotSpeed * delta) % 360;
    }

    // Send an update to the server
    this.char.move({
      x: this.x,
      y: this.y,
      angle: this.angle,
    });
  }

}
