import {Component, Input} from '@angular/core';
import {DamageRange, DamageStats} from '@game/stats/stat-data';

@Component({
  selector: 'wg-damage-stats',
  templateUrl: './damage-stats.component.html',
  styleUrls: ['./damage-stats.component.less']
})
export class DamageStatsComponent {
  @Input() name: string | undefined;
  @Input() stats: DamageStats | undefined;
  @Input() source: DamageRange | undefined;
  @Input() suffix: string = '';
}
