import {Injectable} from '@angular/core';
import {AppService, AppState} from '../app.service';
import {WarpScene} from './warp/warp.scene';
import {BattleScene} from './battle/battle.scene';
import {DeathScene} from './death/death.scene';
import {StarsystemScene} from './starsystem/starsystem.scene';
import * as PIXI from 'pixi.js';
import {Scene} from '../app.component';
import {StarSystemService} from '../../services/star-system.service';
import {HudService} from '../hud/hud.service';
import {QuestService} from '../../services/quest.service';
import {InteractionService} from '../hud/interactions/interaction.service';
import {CharacterService} from '../../services/character.service';
import {MainmenuScene} from './mainmenu/mainmenu.scene';
import {PreloadScene} from './preload/preload.scene';
import {LogoScene} from './logo/logo.scene';
import {IntroScene} from './intro/intro.scene';
import {DebugScene} from './debug/debug.scene';
import {MapService} from '../../services/map.service';
import {BattleService} from '../../services/battle.service';

@Injectable()
export class SceneManagerService {

  private scenes: (PIXI.Container & Scene)[] = [];

  constructor(private appService: AppService,
              private mapService: MapService,
              private battleService: BattleService,
              private starSystemService: StarSystemService,
              private characterService: CharacterService,
              private hudService: HudService,
              private questService: QuestService,
              private interactionService: InteractionService) {
    console.log('[SceneManager] started... ');
  }

  get engine(): PIXI.Application {
    return this.appService.engine;
  }

  init(): void {
    this.appService.afterStateChanged().subscribe((state) => this.handleSceneChange(state));

    // Add the central update loop to the PIXI app ticker instead directly to the
    // GSAP update cycle.
    this.engine.ticker.add(this.update.bind(this));
  }

  handleSceneChange(state: AppState): void {
    console.log(`[SceneManager] change to ${state}`);
    this.clearScenes();

    switch (state) {
      case AppState.Preload:
        this.pushPreloadScene();
        break;

      case AppState.Logo:
        this.pushLogoScene();
        break;

      case AppState.Intro:
        this.pushIntroScene();
        break;

      case AppState.Debug:
        this.pushDebugScene();
        break;

      case AppState.MainMenu:
        this.pushMainMenuScene();
        break;

      case AppState.Warp:
        this.pushWarpScene();
        break;

      case AppState.StarSystem:
        this.pushStarSystemScene();
        break;

      case AppState.Battle:
        this.pushBattleScene();
        break;

      case AppState.Death:
        this.pushDeathScene();
        break;
    }
  }

  private clearScenes(): void {
    this.scenes.forEach((s) => {
      s.destroy({
        children: true,
      });
    });
    this.scenes = [];
  }

  private pushPreloadScene(): void {
    const scene = new PreloadScene(this.appService);
    this.scenes.push(scene);
    this.engine.stage.addChild(scene);
  }

  private pushLogoScene(): void {
    const scene = new LogoScene(this.appService);
    this.scenes.push(scene);
    this.engine.stage.addChild(scene);
  }

  private pushIntroScene(): void {
    const scene = new IntroScene(this.appService);
    this.scenes.push(scene);
    this.engine.stage.addChild(scene);
  }

  private pushDebugScene(): void {
    const scene = new DebugScene(this.appService);
    this.scenes.push(scene);
    this.engine.stage.addChild(scene);
  }

  private pushWarpScene(): void {
    const scene = new WarpScene(this.starSystemService, this.appService.warpTarget);
    this.scenes.push(scene);
    this.engine.stage.addChild(scene);
  }

  private pushMainMenuScene(): void {
    const scene = new MainmenuScene(this.starSystemService);
    this.scenes.push(scene);
    this.engine.stage.addChild(scene);
  }

  private pushBattleScene(): void {
    const scene = new BattleScene(this.starSystemService, this.battleService);
    this.scenes.push(scene);
    this.engine.stage.addChild(scene);
  }

  private pushDeathScene(): void {
    const scene = new DeathScene(this.starSystemService);
    this.scenes.push(scene);
    this.engine.stage.addChild(scene);
  }

  private pushStarSystemScene(): void {
    this.clearScenes();

    const scene = new StarsystemScene(
      this.starSystemService,
      this.mapService,
      this.characterService,
      this.engine,
      this.hudService,
      this.questService,
      this.interactionService
    );

    this.scenes.push(scene);
    this.engine.stage.addChild(scene);
  }

  private update(delta: number): void {
    this.scenes.forEach(scene => {
      scene.update(delta / 100);
    });
  }

}
