import {Battle, BattleGroup} from '@game/battles/battle';
import {BattleSkillData, BattleTurnEndedEvent, LootDropData} from '@game/battles/events';
import {CombatantGameObject} from '@game/battles/combatant.game-object';
import {CellStatus} from '@game/teams/square-cell.game-object';
import {GridEvent, GridOrientation} from '@game/teams/team3x3';
import * as PIXI from 'pixi.js';
import {EntityLayer} from '@game/entities/map';
import {Ship} from '@game/ships/ship';
import {gsap, Linear, Power2, Power4} from 'gsap';
import {colorForRarity} from '@game/colors/colors';
import {DEFAULT_SCREEN_HEIGHT, DEFAULT_SCREEN_WIDTH} from '../../../projects/game/src/app/screen';
import {Combatant} from '@game/battles/combatant';

export enum BattlePosition {
  LEFT = 0,
  RIGHT = 1,
}

const FADE_IN_OFFSET_X = 150;
const FADE_IN_DURATION = 0.5;
const FADE_OUT_DELAY = 2.5;

const COMBATANT_CENTER_DISTANCE = 350;
const COMBATANT_TEAM_DISTANCE_X = 400;
const COMBATANT_TEAM_DISTANCE_Y = 450;

export class BattleGameObject {

  private combatantsObjects: { [key: string]: CombatantGameObject } = {};
  private groups: { [pos: number]: CombatantGameObject }[] = [{}, {}];
  private parent: PIXI.Container | null = null;
  private selectedSkill: BattleSkillData | null = null;

  private usedWidth: number;
  private usedHeight: number;
  private shipSizeRef: number = 1;

  constructor(private battle: Battle) {
    this.usedWidth = DEFAULT_SCREEN_WIDTH;
    this.usedHeight = DEFAULT_SCREEN_HEIGHT;
  }

  getCenter(): PIXI.Point {
    return new PIXI.Point(this.usedWidth / 2, this.usedHeight / 2);
  }

  attachTo(parent: PIXI.Container): void {
    this.parent = parent;

    this.reloadCombatantComponents(parent, false);

    this.battle.afterCombatantJoined().subscribe(() => {
      this.reloadCombatantComponents(parent, true);
    });

    this.battle.afterCombatantLeft().subscribe((evt) => {
      this.removeCombatantComponentById(evt.combatantId, evt.isDestroyed);
    });

    this.battle.afterLootDropped().subscribe((dropMap) => {
      this.spawnLoot(dropMap);
    });

    // TODO Unsub on destroy
    this.battle.afterActiveGroupChanged().subscribe((g: BattleGroup) => {
      this.onNextGroup(g);
    });

    this.battle.afterCharacterShipChanged().subscribe((s: Ship) => {
      this.onNextShip(s);
    });

    this.battle.selectedSkill$.subscribe(skill => {
      if (skill) {
        this.previewSkill(skill);
      }
    });

    this.battle.afterSkillUsed().subscribe((evt: BattleTurnEndedEvent) => {
      this.onSkillUsed(evt);
    });

    this.battle.afterGroupEnded().subscribe(() => {
      this.selectedSkill = null;
      this.clearHighlights('', CellStatus.Attacked);
      this.clearHighlights('', CellStatus.Attacker);
      this.clearHighlights('', CellStatus.Pattern);
      this.clearHighlights('', CellStatus.Target);
    });
  }

  private removeCombatantComponentById(removedCombatantId: string, destroyed: boolean): void {
    this.groups.forEach((group, groupIndex: number) => {
      Object.keys(group).forEach((posStr: string) => {
        const pos = +posStr;
        if (group[pos].getCombatant().getCombatantID() === removedCombatantId) {
          return this.removeCombatantComponent(groupIndex, pos, destroyed);
        }
      });
    });

    console.error('No combatant found which matches the removal', removedCombatantId, destroyed);
  }

  private removeCombatantComponent(groupIndex: number, pos: number, destroyed: boolean): void {
    // Get hold of the component, but clear the position for new groups.
    const cmp = this.groups[groupIndex][pos];

    if (!destroyed) {

      let target = cmp.position.x;
      if (groupIndex === BattlePosition.LEFT) {
        target = target - FADE_IN_OFFSET_X;
      } else {
        target = target + FADE_IN_OFFSET_X;
      }

      // Create a simple fade-out effect.
      gsap.to(cmp.position, {
        x: target,
        duration: 2 * FADE_IN_DURATION,
        ease: Power4.easeInOut,
      });
    }

    gsap.to(cmp, {
      alpha: 0,
      duration: 2 * FADE_IN_DURATION,
      delay: FADE_OUT_DELAY,
      ease: Linear.easeInOut,
      onComplete: () => {
        delete this.groups[groupIndex][pos];
        delete this.combatantsObjects[cmp.getCombatant().getCombatantID()];

        // Only update ship sizes if the battle is not over yet.
        if (this.groups.every(g => Object.keys(g).length > 0)) {
          this.updateShipSizes();
        }

        cmp.destroy({
          children: true,
        });
      }
    });
  }

  /**
   * Add this battle to a scene
   */
  reloadCombatantComponents(parent: PIXI.Container, scaleIn: boolean): void {

    this.battle.groups.forEach((group, groupIndex) => {

      // Remove all combatants when they no longer exist.
      Object.keys(this.groups[groupIndex]).forEach((posStr: string) => {
        const pos = +posStr;

        if (!group.combatants[pos]) {
          this.removeCombatantComponent(groupIndex, pos, false);
        }
      });

      // Create new groups if they are not shown yet.
      Object.keys(group.combatants).forEach((posStr: string) => {
        const pos = +posStr;

        if (!!this.groups[groupIndex][pos]) {
          console.log('Skipping creation of combatant. Already created.');
          return;
        }

        let orientation = GridOrientation.LEFT;

        if (groupIndex === BattlePosition.LEFT) {
          orientation = GridOrientation.RIGHT;
        }

        const combatant = group.combatants[+pos];

        // Use the current size as reference here. This will cause the new ship (if larger) to appear
        // larger than all other ships. Update the ship size afterwards to normalize it and make all
        // other ships smaller!
        const cmp = new CombatantGameObject(combatant, orientation, this.shipSizeRef);
        this.groups[groupIndex][+pos] = cmp;
        this.combatantsObjects[combatant.getCombatantID()] = cmp;

        this.updateShipSizes(scaleIn, combatant.getCombatantID());


        let targetX = cmp.x;
        if (groupIndex === BattlePosition.LEFT) {
          targetX = cmp.x - FADE_IN_OFFSET_X;
        } else {
          targetX = cmp.x + FADE_IN_OFFSET_X;
        }

        cmp.alpha = 0;

        // Create a simple fade-in effect.
        gsap.from(cmp.position, {
          x: targetX,
          duration: FADE_IN_DURATION,
          ease: Power4.easeInOut,
        });
        gsap.to(cmp, {
          alpha: 1,
          duration: FADE_IN_DURATION,
          ease: Linear.easeInOut,
        });

        cmp.zIndex = EntityLayer.CHARACTER;

        parent.addChild(cmp);

        cmp.afterFieldEntered().subscribe((evt) => {
          if (this.selectedSkill && evt.ship && evt.combatant && evt.team) {
            const shipId = evt.ship.shipId;
            const comb = evt.combatant.getCombatant();
            const targets = this.selectedSkill.targets[comb.getCombatantID()];
            // If the ship is a current target
            if (targets && targets.includes(shipId)) {
              for (let i = 0; i < this.selectedSkill.patterns.length; i++) {
                evt.team.highlightPattern(shipId, this.selectedSkill.patterns[i]);
              }
            }
          }
        });

        cmp.afterFieldLeft().subscribe(evt => {
          if (evt.team) {
            evt.team.clearStyling(CellStatus.Pattern);
          }
        });

        cmp.afterFieldLeftClicked().subscribe((evt: GridEvent) => {
          this.onTargetAttacked(evt, true);
        });

        cmp.afterFieldRightClicked().subscribe((evt: GridEvent) => {
          this.onTargetAttacked(evt, false);
        });

      });
    });
  }

  private updateShipSizes(useAnimation: boolean = true, newID: string = ''): void {

    // Get the largest ship of all combatants as base reference.
    const refSize = Object.values(this.combatantsObjects).map(comb => {
      return comb.getLargestShipSize();
    }).reduce((p, c) => Math.max(p, c), 0);

    // Get the largest ship in a battle group to resize the whole group and move all
    // combatants around.
    this.groups.forEach((group, groupIndex: number) => {
      const largest = Object.values(group).map(comb => {
        return comb.getLargestShipSize();
      }).reduce((p, c) => Math.max(p, c), 0);

      for (let pos in group) {
        const comb = group[+pos];
        const f = largest / refSize;

        // Place teams closer together based on the scale.
        let targetX = 0;
        if (groupIndex === BattlePosition.LEFT) {
          targetX = this.usedWidth / 2 - COMBATANT_CENTER_DISTANCE - f * (COMBATANT_TEAM_DISTANCE_X * Math.floor(+pos / 3));
        } else {
          targetX = this.usedWidth / 2 + COMBATANT_CENTER_DISTANCE + f * (COMBATANT_TEAM_DISTANCE_X * Math.floor(+pos / 3));
        }

        let targetY = this.usedHeight / 2;

        if (+pos % 3 === 1) {
          targetY += -COMBATANT_TEAM_DISTANCE_Y * f;
        } else if (+pos % 3 === 2) {
          targetY += +COMBATANT_TEAM_DISTANCE_Y * f;
        }

        if (!useAnimation || comb.getCombatant().getCombatantID() === newID) {
          comb.x = targetX;
          comb.y = targetY;
        } else {
          gsap.to(comb, {
            x: targetX,
            y: targetY,
            duration: 1,
            ease: Power2.easeInOut,
          });
        }
      }

    });

    Object.keys(this.combatantsObjects).forEach(combID => {
      this.combatantsObjects[combID].updateShipSizes(refSize, !useAnimation || combID === newID ? 0 : 1);
    })
  }

  private onTargetAttacked(evt: GridEvent, useSelected: boolean): void {
    if (!this.selectedSkill) {
      return;
    }

    if (!evt.ship || !evt.combatant) {
      return;
    }

    const targetShipId = evt.ship.shipId;
    const targetComb = evt.combatant.getCombatant();

    if (useSelected) {
      const targets = this.selectedSkill.targets[targetComb.getCombatantID()];

      // If the ship is a current target
      if (targets && targets.includes(targetShipId)) {
        this.battle.execute(this.selectedSkill.identifier, targetShipId);
        return;
      }
    } else {
      // Find the first skill that has the selected ship as target! Ignore if no skills
      // are available.
      // EXTEND: Add some sort of message or hint?
      const skills = this.battle.availableSkill$.getValue();
      if (!skills) {
        return;
      }

      for (let skill of skills) {
        // Filter the default skills! Do not trigger default attacks, skip or defend
        // with this!
        if (skill.identifier.startsWith('_')) {
          continue;
        }

        const cId = evt.combatant.getCombatant().getCombatantID();

        if (!skill.targets[cId]) {
          // console.log('[Battle] No targets for ', cId, skill.targets[cId]);
          continue;
        }

        if (!skill.targets[cId].includes(evt.ship.shipId)) {
          // console.log('[Battle] Target ship not included ', evt.ship.shipId, skill.targets[cId]);
          continue
        }

        console.log('[Battle] Executing first skill with targets');
        this.battle.execute(skill.identifier, targetShipId);
        return;
      }
    }
  }

  spawnLoot(data: LootDropData): void {
    let droppedFrom = this.combatantsObjects[data.droppedBy.getCombatantID()].getCenter();

    const LOOT_SIZE = 12;
    const LOOT_INITIAL_DELAY = 1.2;
    const LOOT_DISTRIB_DISTANCE = 0.4;
    const LOOT_DISTRIB_FLYTIME = 2;
    const LOOT_DISTRIB_FLYTIME_JITTER = 0.1;
    const LOOT_DISTRIB_DISTANCE_JITTER = 0.1;
    const LOOT_SEEK_TIME = 1;
    const LOOT_SPACING = 250;

    Object.keys(data.lootMap).forEach(commanderId => {
      const loot = data.lootMap[commanderId];
      const target = this.combatantsObjects[commanderId].getCenter();

      loot.forEach(entry => {
        const g = new PIXI.Graphics();
        g.position = droppedFrom.clone();

        const xOffset = - LOOT_SPACING/2 + (LOOT_SPACING * Math.random());
        const yOffset = - LOOT_SPACING/2 + (LOOT_SPACING * Math.random());
        const distribJitter =  LOOT_DISTRIB_DISTANCE_JITTER * Math.random();
        const distribFlyTimeJitter = LOOT_DISTRIB_FLYTIME_JITTER * Math.random();
        const spawnStartX = g.x + (xOffset * LOOT_DISTRIB_DISTANCE + distribJitter);
        const spawnStartY = g.y + (yOffset * LOOT_DISTRIB_DISTANCE + distribJitter)
        const spawnEndX = g.x + xOffset;
        const spawnEndY = g.y + yOffset;

        g.x = spawnStartX;
        g.y = spawnStartY;

        g.beginFill(colorForRarity(entry.rarity));
        if (entry.isItem) {
          g.drawCircle(-LOOT_SIZE / 2, -LOOT_SIZE / 2, LOOT_SIZE / 4);
        } else {
          g.drawRect(-LOOT_SIZE / 2, -LOOT_SIZE / 2, LOOT_SIZE, LOOT_SIZE);
        }

        g.zIndex = EntityLayer.HUD
        g.alpha = 0;
        this.parent?.addChild(g);

        const tl = gsap.timeline();
        tl.to(g, {
          alpha: 0.3,
          delay: LOOT_INITIAL_DELAY,
          duration: 0.1 + (LOOT_INITIAL_DELAY / 3),
        });
        tl.to(g, {
          x: spawnEndX,
          y: spawnEndY,
          alpha: 1,
          duration: LOOT_DISTRIB_FLYTIME + distribFlyTimeJitter,
        });
        tl.to(g, {
          x: target.x,
          y: target.y,
          alpha: 0,
          duration: LOOT_SEEK_TIME,
          ease: Power4.easeOut,
          onComplete: () => {
            g.destroy();
            tl.kill();
          },
        });
        tl.play();
      });

    });
  }

  onNextGroup(c: BattleGroup): void {
    this.selectedSkill = null;
  }

  onNextShip(s: Ship): void {
    this.clearHighlights('', CellStatus.Attacked);
    this.clearHighlights('', CellStatus.Attacker);
    this.clearHighlights('', CellStatus.Pattern);
    this.clearHighlights('', CellStatus.Target);
    this.highlight('', s.shipId, CellStatus.Attacker);
  }

  private clearHighlights(combatantId: string, style: CellStatus): void {
    this.groups.forEach(g => {
      Object.keys(g).forEach(pos => {
        const combCmp = g[+pos];
        if (combatantId === '' || combCmp.getCombatant().getCombatantID() === combatantId) {
          combCmp.clearHighlights(style);
        }
      });
    });
  }

  private onSkillUsed(evt: BattleTurnEndedEvent): void {
    this.selectedSkill = null;
    // this.highlight(evt.sourceShip, CellStatus.Attacker);
    // this.highlight(evt.targetShip, CellStatus.Target);
  }

  private highlight(combatantId: string, shipId: string, type: CellStatus): void {
    this.groups.forEach(group => {
      Object.keys(group).forEach(pos => {
        const combCmp = group[+pos];
        if (combatantId === '' || combCmp.getCombatant().getCombatantID() === combatantId) {
          combCmp.highlight(shipId, type);
        }
      });
    });
  }


  /**
   * Show all possible targets for this skill
   */
  private previewSkill(skill: BattleSkillData): void {
    this.selectedSkill = skill;

    Object.values(this.combatantsObjects).forEach(cmp => {
      cmp.clearHighlights(CellStatus.Target);
    });

    // Set new highlights for the new skill
    Object.keys(skill.targets).forEach(combatantId => {
      skill.targets[combatantId].forEach(target => {
        this.combatantsObjects[combatantId].highlight(target, CellStatus.Target);
      });
    });
  }

  /**
   * React on a hovered target by displaying the hit patterns
   * of the active skill.
   */
  private onTargetHovered(): void {

  }
}
