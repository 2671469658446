import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {WebsocketService} from '@shared/services/websocket.service';
import {Vector2da} from '@shared/utils/vector2da';
import {Tick} from './tick';
import {BattleState} from '@game/battles/events';
import {CharacterData, QuestData, QuestObjectiveData} from '@game/characters/character';
import {InventoryChange, LootReceivedEvent} from './inventory.service';
import {Environment} from '@game/environments/environment';
import {EntityConfig} from '@game/entities/entity';

export const CLIENT_TICK_RATE: number = 6;
export const POSITION_PRECISION: number = 0;

export interface MapState {
  identifier: string;
  name: string;
  width: number;
  height: number;
  biome: string;
  environment: Environment;
  dangerLevel: number;
  entities: Tick;
}

export interface SessionConnectedEvent {
  character: CharacterData;
  map: MapState;
  battle?: BattleState;
  server: {
    tickRate: number;
  }
}

export interface MoveCharacterCommand {
  position: Vector2da;
}

export interface QuestsChangedEvent {
  quests: { [ident: string]: QuestData };
}

export interface QuestAcceptedEvent {
  questId: string;
  name: string;
  objectives: QuestObjectiveData[];
  marker?: Location;
}

export interface QuestAbortedEvent {
  questId: string;
  name: string;
}

export interface QuestCompletedEvent {
  questId: string;
  name: string;
  scrap: number;
  crystals: number;
  changes: InventoryChange[];
}

@Injectable({
  providedIn: 'root',
})
export class StarSystemService extends WebsocketService {

  constructor() {
    super();
  }

  warpTo(mapName: string): void {
    this.pub({
      method: 'WarpCharacter',
      params: {
        map: mapName,
      }
    });

    // FIXME 'character.systemmap.started' (5s timeout für Effekte?)
    // FIXME 'character.warped'
  }

  getQuestIntro(questId: string, entityId: number): Promise<any> {
    return this.rpc({
      method: 'GetQuestIntro',
      params: {
        entityId: entityId,
        questId: questId,
      },
    });
  }

  getQuestOutro(questId: string, entityId: number): Promise<any> {
    return this.rpc({
      method: 'GetQuestOutro',
      params: {
        entityId: entityId,
        questId: questId,
      }
    });
  }

  acceptQuest(questId: string, entityId: number): Promise<any> {
    return this.rpc({
      method: 'AcceptQuest',
      params: {
        entityId: entityId,
        questId: questId,
      },
    });
  }

  submitQuest(questId: string, entityId: number): Promise<any> {
    return this.rpc({
      method: 'SubmitQuest',
      params: {
        entityId: entityId,
        questId: questId,
      },
    });
  }

  loadItemTrader(entityId: number): Promise<any> {
    return this.rpc({
      method: 'LoadItemTrader',
      params: {
        entityId: entityId,
      },
    });
  }

  loadHullTrader(entityId: number): Promise<any> {
    return this.rpc({
      method: 'LoadHullTrader',
      params: {
        entityId: entityId,
      },
    });
  }

  loadHangar(entityId: number): Promise<any> {
    return this.rpc({
      method: 'LoadHangarMechanic',
      params: {
        entityId: entityId,
      },
    });
  }

  sellItem(pos: any, entityId: number): Promise<any> {
    // EXTEND Sell item directly from ships?
    return this.rpc({
      method: 'SellItem',
      params: {
        entityId: entityId,
        position: pos,
      }
    });
  }

  abortQuest(questId: string): Promise<any> {
    return this.rpc({
      method: 'AbortQuest',
      params: {
        questId: questId,
      }
    });
  }

  afterSessionConnected(): Observable<SessionConnectedEvent> {
    return this.sub('session.connected');
  }

  useBridge(entityId: number): void {
    this.rpc({
      method: 'UseBridge',
      params: {
        entityId: entityId,
      }
    })
  }

  afterQuestAccepted(): Observable<QuestAcceptedEvent> {
    return this.sub('quest.accepted');
  }

  afterQuestAborted(): Observable<QuestAbortedEvent> {
    return this.sub('quest.aborted');
  }

  afterQuestCompleted(): Observable<QuestCompletedEvent> {
    return this.sub('quest.completed');
  }

  afterQuestsChanged(): Observable<QuestsChangedEvent> {
    return this.sub('quests.changed');
  }

  startBattleWith(entity: EntityConfig): Promise<any> {
    return this.rpc({
      method: 'AttackEntity',
      params: {
        entityId: entity.entityId,
      }
    })
  }

  afterLootReceived(): Observable<LootReceivedEvent> {
    return this.sub('loot.received');
  }

  afterDeath(): Observable<any> {
    return this.sub('character.died');
  }

  afterTeamXPEarned(): Observable<any> {
    return this.sub('team.xp.earned');
  }
}
