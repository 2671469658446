import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Team3x3} from '@game/teams/team3x3';
import {CharacterService} from '../../../services/character.service';

@Component({
  selector: 'wg-hud-team',
  templateUrl: './team-hud.component.html',
  styleUrls: ['./team-hud.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamHudComponent implements OnInit {
  team: null | Team3x3 = null;

  constructor(private characterService: CharacterService,
              private cdr: ChangeDetectorRef) {
    // Empty
  }

  ngOnInit(): void {
    this.team = this.characterService.getCharacter().getTeam();
    this.team.afterShipsChanged().subscribe(() => {
      this.cdr.detectChanges();
    });

    this.characterService.getCharacter().afterTeamChanged().subscribe(() => {
      this.team = this.characterService.getCharacter().getTeam();
      this.team.afterShipsChanged().subscribe(() => {
        this.cdr.detectChanges();
      });

      this.cdr.detectChanges();
    });
  }
}
