import {StarSystemService} from '../../../services/star-system.service';
import * as PIXI from 'pixi.js';
import {ShipFactory} from '@game/ships/ship.factory';
import {CharacterService} from '../../../services/character.service';
import {Character} from '@game/characters/character';
import {ShipGameObject} from '@game/ships/ship.game-object';
import {fromEvent, Subject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import {screenHeight, screenWidth} from '../../screen';
import {QuestService} from '../../../services/quest.service';
import {MapService} from '../../../services/map.service';
import {EntityFactory} from '@game/entities/entity.factory';
import {convertLayer, EntityLayerName} from '@game/entities/map';
import {EntityGameObject} from '@game/entities/entity.game-object';
import {Entity} from '@angular/compiler-cli/src/ngtsc/file_system/testing/src/mock_file_system';

const ENTITY_MAP_FACTOR = 1;
const MAP_MOVE_SPEED = 50;
const MAP_WHEEL_ZOOM_FACTOR = -0.0001;

export class MapGameObject extends PIXI.Container {

  private char: Character;

  private charGameObject: null | ShipGameObject;
  private entityComponents: Map<number, EntityGameObject> = new Map<number, EntityGameObject>();

  private destroyed$: Subject<any> = new Subject();

  private scaledElements: PIXI.DisplayObject[] = [];
  private lastSelection: null | EntityGameObject = null;

  constructor(private starSystemService: StarSystemService,
              private mapService: MapService,
              private characterService: CharacterService,
              private questService: QuestService) {
    super();

    this.sortableChildren = true;

    this.char = this.characterService.getCharacter();

    const visShip = this.char.getVisibleShip();
    if (visShip) {
      this.charGameObject = ShipFactory.createShipGameObject(visShip, {});
    } else {
      console.error('no visible ship to render');
      this.charGameObject = null;
    }

    // Add a simple circle to show the tracking distance
    const pos = this.char.getPosition();
    const trackingRange = this.char.getViewRange();
    const g = new PIXI.Graphics();
    g.beginFill(0xEEEEEE);
    g.drawCircle(pos.x, pos.y, trackingRange);
    g.endFill();
    g.beginHole();
    g.drawCircle(pos.x, pos.y, trackingRange - 20);
    g.endHole();
    g.zIndex = convertLayer(EntityLayerName.CHARACTER);
    this.addChild(g);

    if (this.charGameObject) {
      this.addChild(this.charGameObject);
      this.charGameObject.position.set(pos.x, pos.y);
      this.charGameObject.rotation = (this.char.getPosition().angle / 180) * Math.PI;
      this.charGameObject.pivot.set(0.5);
      this.charGameObject.zIndex = convertLayer(EntityLayerName.CHARACTER);
      this.charGameObject.scale.set(ENTITY_MAP_FACTOR * 3);
      this.scaledElements.push(this.charGameObject);
    }

    // Add and track all entities on the map.
    const entities = this.mapService.getEntities();
    entities.forEach((cfg) => {
      let obj;

      obj = EntityFactory.create(cfg, {
        register: false,
        charRef: this.char,
        onSelect: () => {
          this.mapService.setSelectedEntity(cfg);
        },
      });

      obj.position.set(cfg.position.x, cfg.position.y);
      obj.pivot.set(0.5);
      obj.zIndex = convertLayer(cfg.layer);
      this.addChild(obj);
      obj.scale.set(ENTITY_MAP_FACTOR * 3);

      this.entityComponents.set(cfg.entityId, obj);
    });

    // TODO: Nearly identicial Code with Starsystem!
    this.mapService.afterSelectedEntityChanged().pipe(
      takeUntil(this.destroyed$)
    ).subscribe((selEn) => {
      if (!!this.lastSelection) {
        this.lastSelection.removeSelected();
        this.lastSelection = null;
      }

      if (selEn) {
        const newSelection = this.entityComponents.get(selEn.entityId);
        if (newSelection) {
          newSelection.markSelected();
          this.lastSelection = newSelection;
        }
      }
    });

    // Add all quest markers to the map.
    const questMarkers = this.questService.getMarkers();
    Object.keys(questMarkers).forEach(questId => {
      Object.keys(questMarkers[questId]).forEach((objId: string) => {
        const marker = questMarkers[questId][+objId];
        const obj = new PIXI.Text('O', {fill: 'red', fontSize: '20px'});
        obj.position.set(marker.position.x, marker.position.y);
        obj.zIndex = 50;
        this.addChild(obj);
        obj.scale.set(ENTITY_MAP_FACTOR * 10);

        console.log(`[Map] Create new quest for '${questId}' objective '${objId}' marker at ${obj.x};${obj.y}`);
      });
    });

    // Prepare basic map configuration.
    this.scale.set(0.2);
    this.centerToChar();

    fromEvent(window, 'keydown').pipe(
      takeUntil(this.destroyed$),
      map(evt => evt as KeyboardEvent),
    ).subscribe(this.handleKeys);

    fromEvent(window, 'wheel').pipe(
      takeUntil(this.destroyed$),
      map(evt => evt as WheelEvent),
    ).subscribe(this.handleWheel);
  }

  update(delta: number): void {
    // this.x
  }

  destroy(options?: { children?: boolean; texture?: boolean; baseTexture?: boolean }): void {
    super.destroy(options);

    this.destroyed$.next();
    this.destroyed$.complete();
    this.entityComponents.clear();
  }

  private handleKeys = (evt: KeyboardEvent): void => {
    if (evt.key === 'ArrowUp' || evt.key === 'w') {
      this.position.y += MAP_MOVE_SPEED;
    } else if (evt.key === 'ArrowDown' || evt.key === 's') {
      this.position.y -= MAP_MOVE_SPEED;
    } else if (evt.key === 'ArrowLeft' || evt.key === 'a') {
      this.position.x += MAP_MOVE_SPEED;
    } else if (evt.key === 'ArrowRight' || evt.key === 'd') {
      this.position.x -= MAP_MOVE_SPEED;
    } else if (evt.key === ' ') {
      this.centerToChar();
    }
  };

  private handleWheel = (evt: WheelEvent): void => {
    let oldScale = this.scale.x;
    let scale = this.scale.x + evt.deltaY * MAP_WHEEL_ZOOM_FACTOR;
    scale = Math.min(Math.max(scale, 0.005), 0.3);

    console.log(oldScale, scale, this.position);

    const posX = (this.position.x - (screenWidth() / 2)) / oldScale;
    const posY = (this.position.y - (screenHeight() / 2)) / oldScale;

    this.scale.set(scale);
    this.position.x = (posX * this.scale.x) + (screenWidth() / 2);
    this.position.y = (posY * this.scale.y) + (screenHeight() / 2);

    // Rescale alle entities
    this.scaledElements.forEach(obj => {
      obj.scale.set(ENTITY_MAP_FACTOR / scale);
    })
  };

  private centerToChar(): void {
    this.position.x = (-this.char.getPosition().x * this.scale.x) + screenWidth() / 2;
    this.position.y = (-this.char.getPosition().y * this.scale.y) + screenHeight() / 2;

    console.log('center to char', this.position);
  }
}
