import {AnimationTargets, EffectManager} from '@game/vfx/effect.manager';
import {ShipChanges} from '@game/battles/events';
import {EntityLayer} from '@game/entities/map';
import {vfxGodRay, vfxNegate} from '@game/vfx/utils.vfx';
import Timeline = gsap.core.Timeline;

EffectManager.instance.register('laser_attack',
  (t: Timeline, targets: AnimationTargets, when: number, changes: { [p: string]: ShipChanges[] }) => {

    vfxNegate(t, targets.effectSourceObj, 0, 1.2);
    // sfxLaserChargeUpDebug(t, 0, targets.effectSourceObj, targets.effectTargetObj);
    // sfxLaserBeamDebug(t, 0.3, targets.effectSourceObj, targets.effectTargetObj);

    targets.effectTargets.forEach((target) => {

      vfxGodRay(t, target.obj, when);
      vfxGodRay(t, target.obj, when + 0.1);

      t.call(() =>
          EffectManager.instance.spawnAnimation(target.obj,
            'explosions',
            'explosion_m',
            0.3, 0, 0, EntityLayer.FOREGROUND, 0, 0.6)
        , undefined, when + 0.3);

    });

    t.call(() => EffectManager.instance.updateShips(changes), undefined, when + 1.2);

    return 1.5;
  }
);

EffectManager.instance.register('plasma_attack',
  (t: Timeline, targets: AnimationTargets, when: number, changes: { [p: string]: ShipChanges[] }) => {

    vfxNegate(t, targets.effectSourceObj, 0, 1.2);
    // sfxLaserChargeUpDebug(t, 0, targets.effectSourceObj, targets.effectTargetObj);
    // sfxLaserBeamDebug(t, 0.3, targets.effectSourceObj, targets.effectTargetObj);

    targets.effectTargets.forEach((target) => {

      vfxGodRay(t, target.obj, when);
      vfxGodRay(t, target.obj, when + 0.1);

      t.call(() =>
          EffectManager.instance.spawnAnimation(target.obj,
            'explosions',
            'explosion_m',
            0.3, 0, 0, EntityLayer.FOREGROUND, 0, 0.6)
        , undefined, when + 0.3);

    });

    t.call(() => EffectManager.instance.updateShips(changes), undefined, when + 1.2);

    return 1.5;
  }
);
