import {IonizedAtmosphereEnvGameObject} from '@game/environments/ionized-atmosphere-environment.game-object';
import {EnvironmentGameObject} from '@game/environments/environment.game-object';
import {NebulaEnvironmentGameObject} from '@game/environments/nebula-environment.game-object';
import {EnvironmentStyling} from '@game/environments/environment';

export abstract class EnvironmentFactory {

  public static RequiredAssets = [
    {
      name: 'environments/nebula',
      src: 'environments/nebula/nebula.png',
    }
  ];

  static create(biome: string, cfg?: EnvironmentStyling): null | EnvironmentGameObject {
    switch (biome) {
      case 'nebula':
        return new NebulaEnvironmentGameObject(cfg);
      case 'ionization':
        return new IonizedAtmosphereEnvGameObject();
      default:
        console.warn('unknown environment type: ' + biome);
    }

    return null;
  }
}
