import {screenHeight, screenWidth} from '../../screen';
import {StarSystemService} from '../../../services/star-system.service';
import {Scene} from '../../app.component';
import * as PIXI from 'pixi.js';

export class WarpScene extends PIXI.Container implements Scene {

  constructor(starSystemService: StarSystemService, warpTarget: string) {
    super();

    console.log('Create warp scene');

    const overlay = new PIXI.Graphics();

    overlay.beginFill(0x003300);
    overlay.alpha = 1;
    overlay.drawRect(0, 0, screenWidth(), screenHeight());
    this.addChild(overlay);

    const txt = new PIXI.Text(`Warp to: ${warpTarget}`, {fontSize: '60px'});
    txt.position.x = screenWidth() / 2;
    txt.position.y = screenHeight() / 2;
    txt.anchor.set(0.5);
    this.addChild(txt);
  }

  update(delta: number): void {
  }
}
