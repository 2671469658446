import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {Battle} from '@game/battles/battle';
import {
  BattleCombatantJoinedEvent,
  BattleCombatantLeftEvent,
  BattleEndedEvent,
  BattleGroupChangedEvent,
  BattleGroupEndedEvent,
  BattleShipChangedEvent,
  BattleState,
  BattleTurnEndedEvent
} from '@game/battles/events';
import {StarSystemService} from './star-system.service';
import {Environment} from '@game/environments/environment';
import {Mood, SoundManager} from '@game/sound/sound.manager';
import {distinctUntilChanged, filter, skip} from 'rxjs/operators';
import {TeamData} from '@game/characters/character';

export interface BattleEnvironmentChangedEvent {
  environment: Environment;
  team: TeamData;
}

@Injectable()
export class BattleService {

  // Battle
  private battle: Battle | null = null;
  private battleStarted$ = new Subject<void>();
  private battleEnded$ = new Subject<void>();

  private characterId: string | null = null;

  private biome: string | null = null;
  private environment$ = new BehaviorSubject<Environment | null>(null);

  constructor(private starSystem: StarSystemService) {

    this.starSystem.sub('battle.joined').subscribe(evt => {
      this.joinBattle(evt.battle);
    });

    this.starSystem.sub('battle.group.changed').subscribe(this.handleGroupChanged.bind(this));
    this.starSystem.sub('battle.group.ended').subscribe(this.handleGroupEnded.bind(this));
    this.starSystem.sub('battle.ship.changed').subscribe(this.handleShipChanged.bind(this));
    this.starSystem.sub('battle.turn.ended').subscribe(this.handleTurnEnded.bind(this));
    this.starSystem.sub('battle.left').subscribe(this.handleBattleEnded.bind(this));

    this.starSystem.sub('battle.environment.changed').subscribe(this.handleEnvironmentChanged.bind(this));

    this.starSystem.sub('battle.combatant.joined').subscribe(this.handleCombatantJoined.bind(this));
    this.starSystem.sub('battle.combatant.left').subscribe(this.handleCombatantLeft.bind(this));

    this.starSystem.afterConnectionClosed().subscribe(() => this.purge());
    this.starSystem.afterDeath().subscribe(() => this.purge());

    this.starSystem.afterSessionConnected().subscribe((evt) => {
      this.characterId = evt.character.characterId;

      // Load active battle if one exists
      if (evt.battle) {
        this.joinBattle(evt.battle);
      }
    })
  }

  getBattle(): Battle | null {
    return this.battle;
  }

  afterBattleStarted(): Observable<any> {
    return this.battleStarted$;
  }

  afterBattleEnded(): Observable<any> {
    return this.battleEnded$;
  }

  hasBattle(): boolean {
    return !!this.battle;
  }


  /**
   * Clear all leftover data inside the system.
   */
  private purge(): void {
    this.battle = null;
  }

  private handleGroupEnded(evt: BattleGroupEndedEvent): void {
    if (!this.battle) {
      console.error('Battle not initialized');
      return;
    }

    console.log('[OnCombatantEnded] ', evt);
    this.battle.handleGroupEnded(evt);
  }

  private handleGroupChanged(evt: BattleGroupChangedEvent): void {
    if (!this.battle) {
      console.error('Battle not initialized');
      return;
    }

    console.log('[OnNextCombatant] ', evt);
    this.battle.handleGroupChanged(evt);
  }

  private handleCombatantJoined(evt: BattleCombatantJoinedEvent): void {
    if (!this.battle) {
      console.error('Battle not initialized');
      return;
    }

    console.log('[Battle:CombatantJoined] ', evt);
    this.battle.handleCombatantJoined(evt);
  }

  private handleCombatantLeft(evt: BattleCombatantLeftEvent): void {
    if (!this.battle) {
      console.error('Battle not initialized');
      return;
    }

    console.log('[Battle:CombatantLeft] ', evt);
    this.battle.handleCombatantLeft(evt);
  }

  private handleShipChanged(evt: BattleShipChangedEvent): void {
    if (!this.battle) {
      console.error('Battle not initialized');
      return;
    }

    console.log('[OnNextShip] ', evt);
    this.battle.handleShipChanged(evt);
  }

  private handleTurnEnded(evt: BattleTurnEndedEvent): void {
    if (!this.battle) {
      console.error('Battle not initialized');
      return;
    }

    console.log('[OnAction] ', evt);
    this.battle.handleTurnEnded(evt);
  }

  private handleBattleEnded(evt: BattleEndedEvent): void {
    if (!this.battle) {
      console.error('Battle not initialized');
      return;
    }

    console.log('[OnBattleEnded] ', evt);

    SoundManager.instance.setMood(Mood.Calm);

    this.battle = null;
    this.battleEnded$.next();
  }

  getBiome(): string | null {
    return this.biome;
  }

  getEnvironment(): Environment | null {
    return this.environment$.getValue();
  }

  afterEnvironmentChanged(): Observable<Environment> {
    return this.environment$.pipe(
      skip(1),
      // @ts-ignore
      filter(b => b !== null),
      distinctUntilChanged(),
    );
  }

  private joinBattle(evt: BattleState): void {

    console.log('[BATTLE] join battle');

    if (!this.characterId) {
      console.error('unable to join battle - character not loaded');
      return;
    }

    this.biome = evt.biome;
    this.environment$.next(evt.environment);

    const battle = new Battle(this.characterId, evt);

    // Send battle commands back to the server for evaluation
    battle.beforeSkillExecuting$.subscribe(cmd => {
      console.log('Execute Skill Command');
      this.starSystem.pub({
        method: 'BattleExecuteSkill',
        params: cmd,
      });
    });

    this.battle = battle;

    SoundManager.instance.setMood(Mood.Angry);

    this.battleStarted$.next();
  }

  private handleEnvironmentChanged(evt: BattleEnvironmentChangedEvent): void {
    if (!this.battle) {
      console.error('unable to update environment: battle not initialized');
      return;
    }

    this.environment$.next(evt.environment);
    // this.replaceTeam(evt.team);
  }
}
