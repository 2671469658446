import * as PIXI from 'pixi.js';
import {EntityGameObject} from '@game/entities/entity.game-object';
import {EntityConfig} from '@game/entities/entity';
import {EntityOptions} from '@game/entities/entity.factory';

export class BattleStationAGameObject extends EntityGameObject {

  // @ts-ignore
  angle: number; // FIXME OR IGNORE

  constructor(cfg: EntityConfig, options: EntityOptions) {
    super(cfg, options);

    const res = PIXI.Loader.shared.resources;

    // @ts-ignore
    const sprite = new PIXI.Sprite(res['stations/battle-stations-a'].textures['battle-station-a.png']);
    sprite.anchor.set(0.5, 0.5);
    sprite.scale.set(2);

    // this.filters = [filterForFaction(cfg.faction)];

    this.addChild(sprite);
  }
}
