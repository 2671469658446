import * as PIXI from 'pixi.js';
import {Container, DisplayObject} from 'pixi.js';
import gsap from 'gsap';

import {EffectResult, EffectResultTarget, ShipChanges, SkillResult} from '@game/battles/events';
import {EntityLayer} from '@game/entities/map';
import {ShipGameObject} from '@game/ships/ship.game-object';

export interface AnimationReferenceData {
  source: string;
  target: string;
  sourceTeam: string;
  targetTeam: string;
}

export interface AnimationTargets {
  parent: Container;

  rootSourceObj: Container;
  rootSourceTeam: Container;
  effectSourceObj: Container;
  effectSourceTeam: Container;

  rootTargetObj: Container;
  rootTargetTeam: Container;
  effectTargetObj: Container;
  effectTargetTeam: Container;

  effectTargets: {
    obj: Container;
    secondary: boolean;
  }[];
}

export type AnimationFunc = (t: gsap.core.Timeline, obj: Container, when: number, duration: number) => void;

export type SkillAnimationFunc = (
  t: gsap.core.Timeline,
  targets: AnimationTargets,
  when: number,
  changes: { [key: string]: ShipChanges[] }) => number;


export class EffectManager {

  public static RequiredAssets = [
    {
      name: 'explosions',
      src: 'vfx/explosions.json',
    },
    {
      name: 'particles',
      src: 'vfx/particles.json',
    },
    {
      name: 'vfx',
      src: 'vfx/vfx.json'
    }
  ];

  private static _instance: EffectManager;
  private animations: { [name: string]: SkillAnimationFunc };

  static get instance(): EffectManager {
    if (!EffectManager._instance) {
      EffectManager._instance = new EffectManager();
    }
    return EffectManager._instance;
  }

  private parent: null | PIXI.Container = null;
  private objects: { [id: string]: PIXI.Container };

  private currentAnimation: null | string = null;
  private currentObjects: PIXI.DisplayObject[] = [];

  private constructor() {
    this.objects = {};
    this.animations = {};
  }

  registerEntity(id: string, obj: PIXI.Container): void {
    console.log('[VFX] Registered', id);
    this.objects[id] = obj;
  }

  removeEntity(id: string): void {
    console.log('[VFX] Removed', id);
    delete (this.objects[id]);
  }

  setParent(p: PIXI.Container): void {
    console.log('[VFX] Effect parent changed', p);
    this.parent = p;
  }

  updateShips(changes: { [shipId: string]: ShipChanges[] }): void {

    Object.keys(changes).forEach(shipId => {
      const shipObj = this.objects[shipId] as ShipGameObject;
      if (!!shipObj.ship) {
        changes[shipId].forEach(changeSet => {
          shipObj.ship.updateStats(changeSet);
        });
      } else {
        console.warn('not a ship?');
      }
    });
  }

  private resolveTargets(source: 'source' | 'target', target: 'source' | 'target', targets: EffectResultTarget[], refData: AnimationReferenceData): null | AnimationTargets {

    const rootSourceObj = this.objects[refData.source];
    const rootSourceTeam = this.objects[refData.sourceTeam];
    const rootTargetObj = this.objects[refData.target];
    const rootTargetTeam = this.objects[refData.targetTeam];

    let sourceObj;
    let sourceTeam;
    if (source === 'source') {
      sourceObj = this.objects[refData.source];
      sourceTeam = this.objects[refData.sourceTeam];
    } else {
      sourceObj = this.objects[refData.target];
      sourceTeam = this.objects[refData.targetTeam];
    }

    let targetObj;
    let targetTeam;
    if (target === 'source') {
      targetObj = this.objects[refData.source];
      targetTeam = this.objects[refData.sourceTeam];
    } else {
      targetObj = this.objects[refData.target];
      targetTeam = this.objects[refData.targetTeam];
    }

    const effectTargets = targets.map(value => {
      return {
        obj: this.objects[value.shipId],
        secondary: value.secondary,
      };
    });

    if (!this.parent) {
      console.error('cannot start animation: parent not set');
      return null;
    }

    const animRefs: AnimationTargets = {
      parent: this.parent,

      rootSourceObj: rootSourceObj,
      rootSourceTeam: rootSourceTeam,
      rootTargetObj: rootTargetObj,
      rootTargetTeam: rootTargetTeam,

      effectSourceObj: sourceObj,
      effectSourceTeam: sourceTeam,
      effectTargetObj: targetObj,
      effectTargetTeam: targetTeam,

      effectTargets: effectTargets,
    };

    return animRefs;
  }

  startEffectAnimation(t: gsap.core.Timeline, effect: EffectResult, refData: AnimationReferenceData, offset: number = 0): number {
    const animRefs = this.resolveTargets(effect.source, effect.target, effect.targets, refData);
    if (animRefs === null) {
      return offset;
    }

    const animFn = this.animations[effect.animation];
    let newOffset = offset;
    if (!!animFn) {
      newOffset = offset + animFn(t, animRefs, offset, effect.changes);
    } else {
      if (effect.animation) {
        console.warn(`[VFX] Animation '${effect.animation}' not found`);
      } else {
        console.warn(`[VFX] No animation for effect `);
      }
      t.call(() => this.updateShips(effect.changes), undefined);
    }

    return newOffset;
  }

  startSkillAnimation(skill: SkillResult, refData: AnimationReferenceData): void {
    const t = gsap.timeline();

    let offset = 0;
    skill.effects.forEach(effect => {
      offset = this.startEffectAnimation(t, effect, refData, offset);
    });
  }

  spawnAnimation(parent: DisplayObject, resBase: string, animationName: string, scale: number, offsetX: number,
                 offsetY: number, zIndex: EntityLayer, rot: number, speed: number): void {

    const res = PIXI.Loader.shared.resources[resBase];
    if (!res || !res.spritesheet) {
      console.warn(`[VFX] Resource '${resBase}' not found`);
      return;
    }

    const sheet = res.spritesheet.animations[animationName];
    if (!sheet) {
      console.warn(`[VFX] Animation '${animationName}' not found`, res.spritesheet.animations);
      return;
    }

    const sprite = new PIXI.AnimatedSprite(sheet);

    if (!this.parent) {
      console.warn(`[VFX] Parent not set`);
      return;
    }

    sprite.position = parent.getGlobalPosition();
    console.log('Spawn animation at ', sprite.position);

    sprite.x += offsetX;
    sprite.y += offsetY;
    sprite.zIndex = zIndex;
    sprite.rotation = rot;
    sprite.scale.set(scale);
    sprite.animationSpeed = speed;
    sprite.loop = false;
    sprite.play();
    sprite.onComplete = () => {
      sprite.destroy({
        children: true,
      });
    };

    this.parent.addChild(sprite);
  }

  stopAnimation(): void {
    // TODO Remove all objects
  }

  playSimple(sourceRef: string, targetRef: string, animName: string, callback?: () => void): void {
    const t = new gsap.core.Timeline({
      onComplete: callback,
    });
    const offset = this.startEffectAnimation(t, {
      source: 'source',
      target: 'target',
      animation: animName,
      targets: [{
        shipId: targetRef,
        secondary: false,
      }],
      type: '',
      changes: {},
    }, {
      sourceTeam: sourceRef,
      source: sourceRef,
      target: targetRef,
      targetTeam: targetRef,
    });
  }

  register(animName: string, fn: SkillAnimationFunc): void {
    console.log('[VFX] Register animation ', animName);
    this.animations[animName] = fn;
  }

}
