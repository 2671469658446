import * as PIXI from 'pixi.js';
import {getNearestTierTexture, Ship} from '@game/ships/ship';
import {HullSize, ShipGameObject} from '@game/ships/ship.game-object';
import {ExhaustGameObject} from '@game/ships/parts/exhaust.game-object';
import {ShipCreateOptions} from '@game/ships/ship.factory';

export class DestroyerSharkGameObject extends ShipGameObject {

  constructor(ship: Ship, opts: ShipCreateOptions) {
    super(ship, opts);

    this.loadStructure({
      type: '',
      config: {},
      children: [{
        type: 'wing',
        config: {
          asset: 'wing_r_07',
          x: 5,
          y: -0,
          flipY: true,
          scale: 0.5,
        },
      }, {
        type: 'wing',
        config: {
          asset: 'wing_r_07',
          x: -5,
          y: 0,
          flipX: true,
          flipY: true,
          scale: 0.5,
        }
      }, {
        type: 'drive',
        config: {
          asset: 'drive_11_r',
          x: 0,
          y: 25,
          scale: 1.2,
        },
        children: [{
          type: 'exhaust',
          config: {
            track: this.ship,
            placement: 0,
            x: 2,
            y: 40,
            scale: 2.5,
          }
        }]
      }, {
        type: 'body',
        config: {
          asset: 'body_01_variant_03',
          x: 0,
          y: -20,
          scale: 1,
        },
      }, {
        type: 'wing',
        config: {
          asset: 'wing_r_07',
          x: 2,
          y: 11,
          scale: 0.7,
        },
      }, {
        type: 'wing',
        config: {
          asset: 'wing_r_07',
          x: -2,
          y: 11,
          flipX: true,
          scale: 0.7,
        }
      }]
    });

    // const shipTexture = getNearestTierTexture(ship.getHighestModuleTier(), 'ships', 'destroyer_shark_t%.png')
    // if (shipTexture) {
    //   const sprite = new PIXI.Sprite(shipTexture);
    //   sprite.x -= 100;
    //   this.addChild(sprite);
    // }
  }

  getSize(): number {
    return HullSize.Large;
  }
}
