import {Injectable, OnDestroy} from '@angular/core';
import {GalaxyService} from './galaxy.service';
import {CLIENT_TICK_RATE, POSITION_PRECISION, StarSystemService} from './star-system.service';
import {
  EnvironmentChangedEvent,
  InventoryChange,
  ModuleEquippedEvent,
  ModuleMovedEvent,
  ShipsExchangedEvent,
  ShipsRepairedEvent,
  ShipsRevivedEvent,
  TeamExperienceEarnedEvent,
} from './inventory.service';
import {Character, TeamData} from '@game/characters/character';
import {ShipData} from '@game/ships/ship';
import {ShipChanges} from '@game/battles/events';
import {BehaviorSubject, Observable} from 'rxjs';
import {distinctUntilChanged, filter, map, throttleTime} from 'rxjs/operators';
import {Vector2da} from '@shared/utils/vector2da';
import {MapService} from './map.service';

export interface ShipMovedEvent {
  from: number;
  to: number;
}

export interface ShipBoughtEvent {
  pos: number;
  ship: ShipData;
  price: number;

  team: TeamData;
}

export interface ShipUpgradedEvent {
  pos: number;
  changes: InventoryChange[];
  ship: ShipData;
  price: number;

  team: TeamData;
}

export interface ShieldsRechargedEvent {
  changes: { [id: string]: ShipChanges };
}


@Injectable()
export class CharacterService implements OnDestroy {

  private character$ = new BehaviorSubject<Character | null>(null);

  constructor(private galaxyService: GalaxyService,
              private starSystem: StarSystemService,
              private mapService: MapService) {

    console.log('[CharacterService] started..');

    this.starSystem.afterSessionConnected().subscribe(evt => {
      const char = new Character(evt.character);
      this.character$.next(char);

      this.startCharacterPositionWatch();
    })

    this.starSystem.sub('environment.changed').subscribe(this.handleEnvironmentChanged.bind(this));
    this.starSystem.sub('module.moved').subscribe(this.handleModuleMoved.bind(this));
    this.starSystem.sub('module.equipped').subscribe(this.handleModuleEquipped.bind(this));
    this.starSystem.sub('shield.recharged').subscribe(this.handleShieldsRecharged.bind(this));
    this.starSystem.sub('ship.moved').subscribe(this.handleShipMoved.bind(this));

    this.starSystem.sub('ship.bought').subscribe(this.handleShipBought.bind(this));
    this.starSystem.sub('ship.upgraded').subscribe(this.handleShipUpgraded.bind(this));
    this.starSystem.sub('ships.exchanged').subscribe(this.handleShipExchanged.bind(this));
    this.starSystem.sub('ships.repaired').subscribe(this.handleShipsRepaired.bind(this));
    this.starSystem.sub('ships.revived').subscribe(this.handleShipsRevived.bind(this));
    this.starSystem.afterTeamXPEarned().subscribe(this.handleTeamXPEarned.bind(this));
  }

  get character(): null | Character {
    return this.character$.getValue();
  }

  ngOnDestroy(): void {
    this.character$.complete();
  }

  moveShip(from: number, to: number): Promise<any> {
    return this.starSystem.rpc({
      method: 'MoveShip',
      params: {
        from: from,
        to: to,
      },
    });
  }

  watchCharacter(): Observable<Character> {
    return this.character$.pipe(
      filter(char => !!char)
    ) as Observable<Character>;
  }

  getCharacter(): Character {
    if (!this.character) {
      throw new Error('character not loaded');
    }

    return this.character;
  }

  private startCharacterPositionWatch(): void {

    const char = this.character$.getValue();

    if (!char) {
      return;
    }

    char.afterPositionChanged().pipe(
      filter(pos => pos !== null),
      throttleTime(1000 / CLIENT_TICK_RATE),
      map((pos: Vector2da) => ({
        x: +pos.x.toFixed(POSITION_PRECISION),
        y: +pos.y.toFixed(POSITION_PRECISION),
        angle: +pos.angle.toFixed(POSITION_PRECISION),
      })),
      distinctUntilChanged((data, oldPos) => data.x === oldPos.x &&
        data.y === oldPos.y &&
        data.angle === oldPos.angle),
    ).subscribe(pos => {
      // TODO Only send the changed values, not all of them

      this.starSystem.pub({
        method: 'MoveCharacter',
        params: {
          position: pos,
        }
      });
    });
  }

  private handleShieldsRecharged(evt: ShieldsRechargedEvent): void {
    const team = this.getCharacter().getTeam();
    Object.keys(evt.changes).forEach(shipId => {
      team.updateShip(shipId, evt.changes[shipId]);
    });
  }

  private handleEnvironmentChanged(evt: EnvironmentChangedEvent): void {
    if (!this.character) {
      console.error('unable to update environment: character not loaded');
      return;
    }

    console.log('[ENV] Update Character stats!', evt.character);
    this.character.updateStats(evt.character.combinedStats, evt.character.reducedStats);
    this.replaceTeam(evt.team);
  }

  private handleModuleMoved(evt: ModuleMovedEvent): void {
    if (!this.character) {
      throw new Error('character not loaded');
    }

    // Moving an item may result in changes to the whole team.
    if (evt.team) {
      this.replaceTeam(evt.team);
    }
  }

  private handleShipExchanged(evt: ShipsExchangedEvent): void {
    if (!this.character) {
      throw new Error('character not loaded');
    }

    // Moving an item may result in changes to the whole team.
    if (evt.team) {
      this.replaceTeam(evt.team);
    }
  }

  private handleShipsRepaired(evt: ShipsRepairedEvent): void {
    if (!this.character) {
      throw new Error('character not loaded');
    }

    this.updateShips(evt.shipChanges)
  }

  private handleShipsRevived(evt: ShipsRevivedEvent): void {
    if (!this.character) {
      throw new Error('character not loaded');
    }

    this.updateShips(evt.shipChanges);
  }

  private handleTeamXPEarned(evt: TeamExperienceEarnedEvent): void {
    if (!this.character) {
      throw new Error('character not loaded');
    }

    console.log(`[CharacterService] Earned ${evt.xp} experience`);

    this.updateShips(evt.shipChanges);

    if (evt.team) {
      this.replaceTeam(evt.team);
    }
  }

  private handleModuleEquipped(evt: ModuleEquippedEvent): void {
    if (!this.character) {
      throw new Error('character not loaded');
    }

    // Moving an item may result in changes to the whole team.
    if (evt.team) {
      this.replaceTeam(evt.team);
    }
  }

  private handleShipBought(evt: ShipBoughtEvent): void {
    if (!this.character) {
      throw new Error('character not loaded');
    }

    // Moving an item may result in changes to the whole team.
    if (evt.team) {
      this.replaceTeam(evt.team);
    }
  }

  private handleShipUpgraded(evt: ShipUpgradedEvent): void {
    if (!this.character) {
      throw new Error('character not loaded');
    }

    // Moving an item may result in changes to the whole team.
    if (evt.team) {
      this.replaceTeam(evt.team);
    }
  }

  private updateShips(shipChanges: { [shipId: string]: ShipChanges }): void {

    const char = this.character$.getValue();
    if (!char) {
      return;
    }

    Object.keys(shipChanges).forEach(shipId => {
      const changes = shipChanges[shipId];
      if (changes) {
        this.character?.updateShip(shipId, changes);
      }
    });
  }

  private replaceTeam(team: TeamData): void {

    const char = this.character$.getValue();
    if (!char) {
      return;
    }

    char.setTeam(team);
  }

  private handleShipMoved(evt: ShipMovedEvent): void {
    if (!this.character) {
      throw new Error('character not loaded');
    }

    this.character.getTeam().moveShip(evt.from, evt.to);
  }

  exchangeShip(teamPos: number, hangerPos: number): Promise<any> {
    return this.starSystem.rpc({
      method: 'ExchangeShip',
      params: {
        entityId: this.mapService.lockedOn?.entityId,
        teamPos: teamPos,
        hangarPos: hangerPos,
      },
    });
  }

  moveHangar(from: number, to: number): Promise<any>  {
    return this.starSystem.rpc({
      method: 'MoveHanger',
      params: {
        entityId: this.mapService.lockedOn?.entityId,
        from: from,
        to: to,
      },
    });
  }

  repairAll(): Promise<any> {
    return this.starSystem.rpc({
      method: 'RepairAll',
      params: {
        entityId: this.mapService.lockedOn?.entityId,
      }
    });
  }

  repairShip(shipId: string): Promise<any> {
    return this.starSystem.rpc({
      method: 'RepairShip',
      params: {
        entityId: this.mapService.lockedOn?.entityId,
        shipId: shipId,
      },
    });
  }

  reviveAll(): Promise<any> {
    return this.starSystem.rpc({
      method: 'ReviveAll',
      params: {
        entityId: this.mapService.lockedOn?.entityId,
      }
    })
  }

  reviveShip(shipId: string): Promise<any> {
    return this.starSystem.rpc({
      method: 'RepairShip',
      params: {
        entityId: this.mapService.lockedOn?.entityId,
        shipId: shipId,
      },
    });
  }
}
