import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import * as PIXI from 'pixi.js';
import {gsap} from 'gsap';
import {StarSystemService} from '../services/star-system.service';
import {GalaxyService} from '../services/galaxy.service';
import {ConfigService} from '../services/config.service';
import {AuthService} from '@shared/services/auth.service';
import {MapService} from '../services/map.service';
import {InventoryService} from '../services/inventory.service';
import {BattleService} from '../services/battle.service';

export enum AppState {
  Preload = 'preload',
  Logo = 'logo',
  Intro = 'intro',
  MainMenu = 'mainMenu',
  Warp = 'warp',
  StarSystem = 'starSystem',
  Battle = 'battle',
  Death = 'death',

  Debug = 'debug',
}

@Injectable()
export class AppService {

  public engine!: PIXI.Application;
  private state$ = new BehaviorSubject<AppState>(AppState.Preload);

  warpTarget: string = '';

  constructor(private configService: ConfigService,
              private starSystemService: StarSystemService,
              private galaxyService: GalaxyService,
              private authService: AuthService,
              private mapService: MapService,
              private battleService: BattleService,
              private inventoryService: InventoryService) {
    console.log('[AppService] started...');

    this.state$.subscribe((s) => console.log(`[AppService] state changed to ${s}`));
  }

  afterStateChanged(): Observable<AppState> {
    return this.state$;
  }

  init(): void {

    this.engine = new PIXI.Application({
      antialias: false,
      autoStart: true,
      backgroundColor: 0x000000,
      clearBeforeRender: true,
      resizeTo: window,
      autoDensity: true,
      // width: 1280,
      // height: 780,
      preserveDrawingBuffer: true,
      sharedLoader: true,
      sharedTicker: true,
    });

    // We stop Pixi ticker using stop() function because autoStart = false does NOT stop the shared ticker:
    // doc: http://pixijs.download/release/docs/PIXI.Application.html
    this.engine.ticker.stop();

    // Now, we use 'tick' from gsap
    gsap.ticker.add(() => {
      this.engine.ticker.update();
    });

    this.listen();
  }

  changeState(nextState: AppState): void {
    this.state$.next(nextState);
  }

  private listen(): void {

    this.galaxyService.afterConnectionOpened().subscribe(() => {

    });

    this.galaxyService.afterConnectionClosed().subscribe(() => {

    });

    this.galaxyService.sub('reconnecting').subscribe(() => {
      // this.state$.next(AppState.Warp)
    });

    this.galaxyService.afterCharacterSelected().subscribe(() => {
      // Do nothing...
    }, (err) => {
      // FIXME Handle errors
    });

    this.galaxyService.afterInitWarp().subscribe(data => {
      console.log('[InitWarp] ', data);
      this.warpTarget = data.mapName;
      this.state$.next(AppState.Warp);
    });


    this.galaxyService.afterConnectTo().subscribe(data => {
      console.log('[ConnectTo] ', data);
      this.authService.getAuthToken().subscribe(token => {
        this.starSystemService.connect(() => {
          return (data.starSystemUrl + '/starsystem').replace('http', 'ws');
        }, token);
      });
    });

    this.starSystemService.afterConnectionClosed().subscribe(() => {
      if (this.state$.getValue() === AppState.StarSystem || this.state$.getValue() === AppState.Battle) {
        this.state$.next(AppState.MainMenu);
      }
    });

    this.starSystemService.afterSessionConnected().subscribe((evt) => {
      if (!evt.battle) {
        this.state$.next(AppState.StarSystem);
      }
    });

    this.battleService.afterBattleStarted().subscribe(() => {
      this.state$.next(AppState.Battle);
    });

    this.battleService.afterBattleEnded().subscribe(() => {
      setTimeout(() => {
        this.state$.next(AppState.StarSystem);
      }, 5);
    });

    this.starSystemService.afterDeath().subscribe(() => {
      this.state$.next(AppState.Death);
    });
  }

  connectToGalaxy(): void {
    const url = this.configService.galaxyUrl.replace('http', 'ws');

    this.authService.getAuthToken().subscribe(token => {
      this.galaxyService.connect(() => `${url}/v1/galaxy`, token);
    });
  }
}
