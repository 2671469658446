import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {ShipChanges, ShipInBattleData, StatusEffect} from '@game/battles/events';
import {distinctUntilChanged, filter, map, pairwise, tap} from 'rxjs/operators';
import {ModuleSlotData} from '@game/modules/slots';
import {FlatModValue, Range, SkillStats, StatData} from '@game/stats/stat-data';
import {SkillData} from '@game/battles/skills';
import {Rarity} from '../../../projects/game/src/app/hud/shared/stats/rarity';
import * as PIXI from 'pixi.js';

export function getNearestTierTexture(tier: number, resName: string, baseName: string): PIXI.Texture | null {

  const res = PIXI.Loader.shared.resources[resName];

  if (!res || !res.textures) {
    console.error(`resources or textures for ${resName} not found`);
    return null;
  }

  for (let i = tier; i >= 0; i--) {
    const texName = baseName.replace('%', '' + i);
    const shipRes = res.textures[texName];
    if (shipRes) {
      return shipRes;
    }
  }

  console.error(`no textures for ${baseName}`);
  return null;
}

export interface ShipStats {
  maxShields: number;
  maxStructure: number;
  apRegeneration: number;
  buffRes: number;
  buffPierce: number;
  debuffRes: number;
  debuffPierce: number;
  shieldRegeneration: number;
  damage: { [dt: string]: Range[] };
  damageReduction: { [dt: string]: FlatModValue };
  damagePierce: { [dt: string]: FlatModValue };
  availableSkills: SkillData[];
  allSkills: SkillStats;
  skillCategories: { [cat: string]: SkillStats };
  skills: { [ident: string]: SkillStats };
}

export interface StatModifier {
  name: string;
  description: string;

  moduleEfficiency?: number;
  apRegeneration?: number;
  allDamageTypes?: number;
  damage?: { [damageType: string]: number };
  damageReduction?: number;
  shields?: number;
  shieldRegeneration?: number;
}

export interface ShipData {
  shipId: string;
  name: string;
  faction: string;
  hullType: string;
  hullTier: number;
  typeName: string;
  className: string;
  asset: string;
  shields: number;
  structure: number;
  moduleTier: number;
  level: number;
  totalXP: number;
  requiredXP: number;
  levelProgress: number;
  statModifiers: { [name: string]: StatModifier };
  moduleSlots?: { [id: string]: ModuleSlotData };

  shipStats: ShipStats;
  teamStats: StatData;

  rarity: Rarity;
}

export class Ship {

  protected shipId$: BehaviorSubject<string>;
  protected name$: BehaviorSubject<string>;
  protected maxShields$: BehaviorSubject<number>;
  protected shields$: BehaviorSubject<number>;
  protected maxStructure$: BehaviorSubject<number>;
  protected structure$: BehaviorSubject<number>;
  protected level$: BehaviorSubject<number>;
  protected totalXP$: BehaviorSubject<number>;
  protected levelProgress$: BehaviorSubject<number>;
  protected requiredXP$: BehaviorSubject<number>;
  protected ap$: BehaviorSubject<number>;
  protected isDefending$: BehaviorSubject<boolean>;
  protected isShieldRestoring$: BehaviorSubject<boolean>;
  protected statusEffects$: BehaviorSubject<StatusEffect[]>;

  protected damageTaken$: Subject<number>;
  protected healTaken$: Subject<number>;

  private shipStats$: BehaviorSubject<any>;
  private teamStats$: BehaviorSubject<any>;
  private moduleSlots$: BehaviorSubject<{ [id: string]: ModuleSlotData }>;

  protected rarity$: BehaviorSubject<Rarity>;

  private resisted$ = new Subject<void>();

  constructor(private shipData: ShipData | ShipInBattleData) {

    this.shipId$ = new BehaviorSubject<string>(shipData.shipId);
    this.name$ = new BehaviorSubject<string>(shipData.name);

    this.moduleSlots$ = new BehaviorSubject<any>(shipData.moduleSlots);

    this.maxShields$ = new BehaviorSubject<number>(shipData.shipStats?.maxShields);
    this.shields$ = new BehaviorSubject<number>(shipData.shields);

    this.maxStructure$ = new BehaviorSubject<number>(shipData.shipStats?.maxStructure);
    this.structure$ = new BehaviorSubject<number>(shipData.structure);

    this.rarity$ = new BehaviorSubject<Rarity>(shipData.rarity);

    this.shipStats$ = new BehaviorSubject<any>(shipData.shipStats);
    this.teamStats$ = new BehaviorSubject<any>(shipData.teamStats);

    this.level$ = new BehaviorSubject<number>(shipData.level);
    this.levelProgress$ = new BehaviorSubject<number>(shipData.levelProgress);
    this.totalXP$ = new BehaviorSubject<number>(shipData.totalXP);
    this.requiredXP$ = new BehaviorSubject<number>(shipData.requiredXP);

    const battleShipData = shipData as ShipInBattleData;
    this.ap$ = new BehaviorSubject<number>(battleShipData.ap || 0);
    this.statusEffects$ = new BehaviorSubject<StatusEffect[]>(battleShipData.statusEffects || []);
    this.isDefending$ = new BehaviorSubject<boolean>(battleShipData.isDefending);
    this.isShieldRestoring$ = new BehaviorSubject<boolean>(battleShipData.isShieldRestoring);

    this.damageTaken$ = new Subject<number>();
    this.healTaken$ = new Subject<number>();
  }

  get moduleSlots(): { [key: string]: ModuleSlotData } {
    return this.moduleSlots$.getValue();
  }

  get shipId(): string {
    return this.shipId$.getValue();
  }

  get displayName(): string {
    return this.name$.getValue() || this.typeName;
  }

  get name(): string {
    return this.name$.getValue();
  }

  get hullType(): string {
    return this.shipData.hullType;
  }

  get typeName(): string {
    return this.shipData.typeName;
  }

  get className(): string {
    return this.shipData.className;
  }

  get level(): number {
    return this.level$.getValue();
  }

  get totalXP(): number {
    return this.totalXP$.getValue();
  }

  get requiredXP(): number {
    return this.requiredXP$.getValue();
  }

  get levelProgress(): number {
    return this.levelProgress$.getValue();
  }

  get statModifiers(): { [name: string]: StatModifier } {
    return this.shipData.statModifiers;
  }

  get asset(): string {
    return this.shipData.asset;
  }

  get faction(): string {
    return this.shipData.faction;
  }

  get shipStats(): ShipStats {
    return this.shipStats$.getValue();
  }

  get teamStats(): any {
    return this.teamStats$.getValue();
  }

  get maxShields(): number {
    return this.maxShields$.getValue();
  }

  get shields(): number {
    return this.shields$.getValue();
  }

  get maxStructure(): number {
    return this.maxStructure$.getValue();
  }

  get structure(): number {
    return this.structure$.getValue();
  }

  get ap(): number {
    return this.ap$.getValue();
  }

  get isDefending(): boolean {
    return this.isDefending$.getValue();
  }

  get isShieldRestoring(): boolean {
    return this.isShieldRestoring$.getValue();
  }

  get rarity(): Rarity {
    return this.rarity$.getValue();
  }

  get statusEffects(): StatusEffect[] {
    return this.statusEffects$.getValue();
  }

  destroy(): void {
    this.shipId$.complete();

    this.maxShields$.complete();
    this.shields$.complete();

    this.maxStructure$.complete();
    this.structure$.complete();

    this.ap$.complete();
  }

  afterDamageTaken(): Observable<number> {
    return this.damageTaken$.asObservable();
  }

  afterHealTaken(): Observable<number> {
    return this.healTaken$.asObservable();
  }

  afterLevelUp(): Observable<number> {
    return this.level$.pipe(
      distinctUntilChanged(),
    );
  }

  afterXPGained(): Observable<number> {
    return this.totalXP$.pipe(
      tap((val) => console.log('xp gained', val)),
      pairwise(),
      map((val) => val[1] - val[0]),
    );
  }

  afterShieldDamaged(): Observable<number> {
    return this.shields$.pipe(
      pairwise(),
      map((val) => val[0] - val[1]),
      filter((val) => val > 0),
    );
  }

  afterShieldDestroyed(): Observable<number> {
    return this.shields$.pipe(
      distinctUntilChanged(),
      filter(s => s <= 0)
    );
  }

  afterShieldChanged(): Observable<number> {
    return this.shields$.pipe(
      distinctUntilChanged(),
    );
  }

  afterShieldRestored(): Observable<number> {
    return this.shields$.pipe(
      pairwise(),
      map((val) => val[1] - val[0]),
      filter((val) => val > 0),
    );
  }

  afterStructureChanged(): Observable<number> {
    return this.structure$.pipe(
      distinctUntilChanged()
    );
  }

  afterStructureDamaged(): Observable<number> {
    return this.structure$.pipe(
      pairwise(),
      map((val) => val[0] - val[1]),
      filter((val) => val > 0)
    );
  }

  afterStatusEffectsChanged(): Observable<any> {
    return this.statusEffects$.pipe(
      distinctUntilChanged()
    );
  }

  afterDestroyed(): Observable<number> {
    return this.structure$.pipe(
      distinctUntilChanged(),
      filter(s => s <= 0)
    );
  }

  isDestroyed(): boolean {
    return this.structure <= 0;
  }

  afterDefendingChanged(): Observable<boolean> {
    return this.isDefending$;
  }

  afterShieldRestoringChange(): Observable<boolean> {
    return this.isShieldRestoring$;
  }

  afterRarityChanged(): Observable<Rarity> {
    return this.rarity$;
  }

  afterStructureRepaired(): Observable<number> {
    return this.structure$.pipe(
      pairwise(),
      map((val) => val[1] - val[0]),
      filter((val) => val > 0)
    );
  }

  updateStats(changes: ShipChanges): void {

    if (changes.level || changes.level === 0) {
      this.level$.next(changes.level);
    }

    if (changes.totalXP || changes.totalXP === 0) {
      console.log('total xp changes', changes.totalXP);
      this.totalXP$.next(changes.totalXP);
    }

    if (changes.requiredXP || changes.requiredXP === 0) {
      this.requiredXP$.next(changes.requiredXP);
    }

    if (changes.levelProgress || changes.levelProgress === 0) {
      this.levelProgress$.next(changes.levelProgress);
    }

    if (changes.ap || changes.ap === 0) {
      this.ap$.next(changes.ap);
    }

    if (changes.shields || changes.shields === 0) {
      this.shields$.next(changes.shields);
    }

    if (changes.maxShields || changes.maxShields === 0) {
      this.maxShields$.next(changes.maxShields);
    }

    if (changes.damageTaken) {
      this.damageTaken$.next(changes.damageTaken);
    }

    if (changes.healTaken || changes.healTaken === 0) {
      this.healTaken$.next(changes.healTaken);
    }

    if (changes.structure || changes.structure === 0) {
      this.structure$.next(changes.structure);
    }

    if (changes.maxStructure || changes.maxStructure === 0) {
      this.maxStructure$.next(changes.maxStructure);
    }

    if (changes.statusEffects) {
      this.statusEffects$.next(changes.statusEffects);
    }

    if (changes.resisted) {
      this.resisted$.next();
    }

    if (changes.isDefending === true || changes.isDefending === false) {
      this.isDefending$.next(changes.isDefending);
    }

    if (changes.isShieldRestoring === true || changes.isShieldRestoring === false) {
      this.isShieldRestoring$.next(changes.isShieldRestoring);
    }
  }

  afterAPChanged(): Observable<number> {
    return this.ap$;
  }

  afterResisted(): Observable<void> {
    return this.resisted$;
  }

  getHighestModuleTier(): number {
    const slots = this.moduleSlots;
    if (!slots) {
      return this.shipData.moduleTier || 0;
    }

    return Object.values(slots).map((slot: ModuleSlotData) => {
      if (!slot.module) {
        return 0;
      }
      return slot.module.tier;
    }).reduce((p, c) => Math.max(p, c), 0);
  }
}
