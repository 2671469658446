import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {distinctUntilChanged, filter, map} from 'rxjs/operators';
import {Ship} from '../ships/ship';
import {
  BattleCombatantJoinedEvent,
  BattleCombatantLeftEvent,
  BattleGroupChangedEvent,
  BattleGroupEndedEvent, BattleGroupRefreshData,
  BattleShipChangedEvent,
  BattleSkillData,
  BattleState,
  BattleTurnEndedEvent,
  LootDropData,
  ShipChanges
} from '@game/battles/events';
import {Combatant} from '@game/battles/combatant';
import {EffectManager} from '@game/vfx/effect.manager';

// The default time subtracted to ensure that users are not bothered by the
// round time ending too early.
const DEFAULT_ROUND_TIME_OFFSET = 1000;

export enum BattlePosition {
  LEFT = 0,
  RIGHT = 1,
}

export class BattleGroup {
  combatants: { [pos: number]: Combatant } = {};
}

export interface ExecuteSkillCommand {
  skillId: string;
  sourceId: string;
  targetId: string;
}

export class Battle {

  groups: BattleGroup[] = [];

  activeGroupIndex$ = new BehaviorSubject<null | BattlePosition>(null);
  activeGroup$ = new BehaviorSubject<null | BattleGroup>(null);
  characterShip$ = new BehaviorSubject<null | Ship>(null);

  availableSkill$ = new BehaviorSubject<null | BattleSkillData[]>(null);
  selectedSkill$ = new BehaviorSubject<null | BattleSkillData>(null);
  afterSkillUsed$ = new Subject<BattleTurnEndedEvent>();
  beforeSkillExecuting$ = new Subject<ExecuteSkillCommand>();

  combatantJoined$ = new Subject<Combatant>();
  combatantLeft$ = new Subject<BattleCombatantLeftEvent>();
  lootDropped$ = new Subject<LootDropData>();
  groupEnded$ = new Subject<void>();

  roundTime: number = 0;

  constructor(private characterId: string, state: BattleState) {

    // Restore the configuration of the BattleState.
    state.groups.forEach(groupConfig => {
      const newGroup = new BattleGroup();
      Object.keys(groupConfig.combatants).forEach((pos) => {
        const cfg = groupConfig.combatants[+pos];
        newGroup.combatants[+pos] = new Combatant(cfg);
      });
      this.groups.push(newGroup);
    });

    if (state.activeGroup) {
      this.handleGroupChanged({
        activeGroup: state.activeGroup,
        combatantChanges: {},
        roundTime: state.roundTime,
      });
    }

    if (state.activeShips) {
      Object.keys(state.activeShips).forEach(combatantID => {

        const activeShip = state.activeShips[combatantID];

        // FIXME: Active own ship and highlight ships of others.
        this.handleShipChanged({
          activeShip: activeShip,
          combatant: combatantID,
          skills: state.skills,
        });
      });
    }

    console.log('[Battle] created with round time', state.roundTime);
    this.roundTime = state.roundTime;
  }

  handleGroupEnded(evt: BattleGroupEndedEvent): void {

    console.log('[BATTLE] Combatant ended', evt);

    Object.keys(evt.combatantChanges).forEach(combatantID => {

      const cc = evt.combatantChanges[combatantID];

      if (cc.changes) {
        Object.keys(cc.changes).forEach(shipId => {
          const changes = cc.changes ? cc.changes[shipId] : null;
          if (changes) {
            this.updateStats(shipId, changes);
          }
        });
      }

      if (cc.skillResults) {
        cc.skillResults.forEach(skill => {
          EffectManager.instance.startSkillAnimation(skill, {} as any); // FIXME
          console.log('[DEBUG] Start skill animation ', skill.effects[0].animation);
        });
      }
    });

    this.groupEnded$.next();
  }

  /**
   * Activates the next player, generates the user initiative order.
   */
  handleGroupChanged(evt: BattleGroupChangedEvent): void {

    Object.keys(evt.combatantChanges).forEach(combatantID => {

      const cc = evt.combatantChanges[combatantID];

      if (cc.changes) {
        Object.keys(cc.changes).forEach(shipId => {
          const changes = cc.changes ? cc.changes[shipId] : null;
          if (changes) {
            this.updateStats(shipId, changes);
          }
        });
      }

      if (cc.skillResults) {
        cc.skillResults.forEach(skill => {
          EffectManager.instance.startSkillAnimation(skill, {} as any); // FIXME
          console.log('[DEBUG] Start skill animation ', skill.effects[0].animation);
        });
      }
    });

    this.roundTime = evt.roundTime - DEFAULT_ROUND_TIME_OFFSET;
    this.activeGroupIndex$.next(evt.activeGroup);
    this.activeGroup$.next(this.groups[evt.activeGroup]);
  }

  /**
   * Activates the next ship and prepares all grids for the offensive or defense highlighting.
   */
  handleShipChanged(evt: BattleShipChangedEvent): void {

    if (evt.combatant === this.characterId) {
      // Only update the skills if the players character is involved.
      const ship = this.findShip(evt.activeShip);

      this.characterShip$.next(ship);
      this.availableSkill$.next(evt.skills);

      if (!!evt.skills) {
        this.selectedSkill$.next(evt.skills[0]);
      }
    }
  }


  handleTurnEnded(evt: BattleTurnEndedEvent): void {
    console.log('[BATTLE] Handle Skill', evt);

    if (this.characterId === evt.sourceTeam) {
      this.afterSkillUsed$.next(evt);
    }

    if (evt.skillResults) {
      console.log('[DEBUG] Start skill animation ', evt.skillResults.effects[0].animation);
      EffectManager.instance.startSkillAnimation(evt.skillResults, {
        source: evt.sourceShip,
        target: evt.targetShip,
        sourceTeam: evt.sourceTeam,
        targetTeam: evt.targetTeam,
      });
    }

    // Update all ships with the chanced params
    if (evt.changes) {
      Object.keys(evt.changes).forEach(shipId => {
        this.updateStats(shipId, evt.changes[shipId]);
      });
    }

    if (evt.skillRefresh) {
      this.availableSkill$.next(evt.skillRefresh);
    }
  }

  handleCombatantJoined(evt: BattleCombatantJoinedEvent): void {
    const c = new Combatant(evt.combatant);
    this.groups[evt.groupIndex].combatants[evt.groupPosition] = c;
    this.combatantJoined$.next(c);
  }

  handleCombatantLeft(evt: BattleCombatantLeftEvent): void {
    this.groups.forEach((bg): boolean => {
      let foundPos = -1;
      let foundComb: null | Combatant = null;
      Object.keys(bg.combatants).forEach(pos => {
        if (bg.combatants[+pos].getCombatantID() === evt.combatantId) {
          foundComb = bg.combatants[+pos];
          foundPos = +pos;
          return false;
        }
        return true;
      });
      if (foundPos === -1 || foundComb === null) {
        return true;
      }

      delete bg.combatants[foundPos];
      evt.combatant = foundComb;
      this.combatantLeft$.next(evt);

      if (evt.lootMap) {
        this.lootDropped$.next({
          droppedBy: foundComb,
          lootMap: evt.lootMap,
        });
      }

      return false;
    });

    if (evt.refreshData) {
      // Update all combatants!
      Object.keys(evt.refreshData).forEach(groupIndex => {
        const group = evt.refreshData[+groupIndex];
        Object.keys(group.combatants).forEach(combPos => {
          const comb = evt.refreshData[+groupIndex].combatants[+combPos];
          Object.keys(comb.ships).forEach(shipId => {
            const changes = comb.ships[shipId];
            this.updateStats(shipId, changes);
          });
        });
      });
    }

    if (evt.shipChanges) {
      for (let shipId in evt.shipChanges) {
        this.updateStats(shipId, evt.shipChanges[shipId]);
      }
    }
  }

  afterActiveGroupChanged(): Observable<BattleGroup> {
    return this.activeGroup$.pipe(
      filter((group) => group !== null),
      distinctUntilChanged(),
      map(x => x as BattleGroup), // FIXME This is superflous and only serves to please the compiler.
    );
  }

  afterCharacterShipChanged(): Observable<Ship> {
    return this.characterShip$.pipe(
      filter((ship: null | Ship) => ship !== null),
      distinctUntilChanged(),
      map(x => x as Ship) // FIXME This is superflous and only serves to please the compiler.
    );
  }

  execute(identifier: string, targetId: string): void {

    const ship = this.characterShip$.getValue();
    if (!ship) {
      throw new Error('unable to execute skill - no ship active');
    }

    this.beforeSkillExecuting$.next({
      skillId: identifier,
      sourceId: ship.shipId,
      targetId: targetId,
    });
  }

  afterGroupEnded(): Observable<void> {
    return this.groupEnded$.asObservable();
  }

  afterSkillUsed(): Observable<BattleTurnEndedEvent> {
    return this.afterSkillUsed$.pipe(
      filter((skill) => skill !== null),
      distinctUntilChanged(),
    );
  }

  private findShip(id: string): null | Ship {
    for (const g of this.groups) {
      for (const pos of Object.keys(g.combatants)) {
        for (const s of g.combatants[+pos].getShips()) {
          if (s && s.shipId === id) {
            return s;
          }
        }
      }
    }
    return null;
  }

  private updateStats(shipId: string, changes: ShipChanges): void {
    // console.log('Update ', shipId, ' to ', changes);
    const ship = this.findShip(shipId);

    if (ship) {
      ship.updateStats(changes);
    } else {
      console.error(`unable to update stats: ship "${shipId}" not found`);
    }
  }

  afterCombatantJoined(): Observable<Combatant> {
    return this.combatantJoined$;
  }

  afterCombatantLeft(): Observable<BattleCombatantLeftEvent> {
    return this.combatantLeft$;
  }

  afterLootDropped(): Observable<LootDropData> {
    return this.lootDropped$;
  }
}
