import * as PIXI from 'pixi.js';
import {BiomeGameObject} from '@game/biomes/biome.game-object';

export class IonizedAtmosphereEnvGameObject implements BiomeGameObject {
  private parent: any;
  private filter: PIXI.filters.ColorMatrixFilter;
  private time: number = 0;

  constructor() {
    console.log('[Env:IonizedAtmosphere] loading...');
    this.filter = new PIXI.filters.ColorMatrixFilter()

  }

  update(delta: number): void {
    this.time += delta;
    if (this.filter) {
      this.filter.reset();
      this.filter.brightness(1 + 0.25 * Math.sin(this.time), true);
    }
  }

  destroyAfter(ms: number = 0): void {
    console.log('[Env:IonizedAtmosphere] destroying...');
    this.parent.filters = [];
  }

  attachTo(parent: PIXI.Container): void {
    this.parent = parent;
    this.parent.filters = [this.filter];
  }
}
