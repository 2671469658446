import {Component} from '@angular/core';
import {QuestTrackerData} from '@game/characters/character';
import {StarSystemService} from '../../../../services/star-system.service';
import {Observable} from 'rxjs';
import {QuestService} from '../../../../services/quest.service';

@Component({
  selector: 'wg-quest-log',
  templateUrl: 'quest-log.component.html',
  styleUrls: ['quest-log.component.less']
})
export class QuestLogComponent {
  quests$: Observable<QuestTrackerData>;
  activeQuestId: string | null = null;

  constructor(private starSystemService: StarSystemService,
              private questService: QuestService) {
    // FIXME until destroyed!
    this.quests$ = this.questService.watchQuests();
  }

  showQuestText(questId: string): void {
    this.activeQuestId = questId;
  }

  abortQuest(): void {
    if (!this.activeQuestId) {
      console.error('unable to abort. no active quest');
      return;
    }

    this.starSystemService.abortQuest(this.activeQuestId);
    this.activeQuestId = null;
  }
}
