import {Component} from '@angular/core';
import {StarSystemService} from '../../../services/star-system.service';

@Component({
  selector: 'wg-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.less'],
})
export class MenuComponent {

  constructor(private starSystemService: StarSystemService) {
  }

  logout(): void {
    this.starSystemService.rpc({
      method: 'Logout',
    })
  }
}
