import {Component, OnInit} from '@angular/core';
import {StarSystemService} from '../../../services/star-system.service';
import {HudService, ItemType} from '../hud.service';
import {AppState} from '../../app.service';
import {ItemData} from '@game/characters/character';
import {delay} from 'rxjs/operators';
import {BattleService} from '../../../services/battle.service';
import {InventoryService} from '../../../services/inventory.service';

interface PreviewEntry {
  type: 'scrap' | 'crystal' | 'item';
  change: 'added' | 'lost' | 'removed';
  itemType?: ItemType;
  itemData?: ItemData;
  num: number;
}

@Component({
  selector: 'wg-inventory-hud',
  templateUrl: './inventory-hud.component.html',
  styleUrls: ['./inventory-hud.component.less'],
})
export class InventoryHudComponent implements OnInit {

  AppState = AppState;

  previewList: PreviewEntry[] = [];

  constructor(private starSystem: StarSystemService,
              private battleService: BattleService,
              public hudService: HudService,
              public inventoryService: InventoryService) {
  }

  ngOnInit(): void {
    this.starSystem.afterLootReceived().pipe(
      delay(4000),
    ).subscribe(loot => {

      if (loot.received) {
        loot.received.forEach(entry => {
          this.addPreviewEntry({
            change: 'added',
            type: 'item',
            itemType: entry.type,
            itemData: entry.data,
            num: entry.num,
          });
        });
      }

      if (loot.lost) {
        loot.lost.forEach(entry => {
          this.addPreviewEntry({
            change: 'lost',
            type: 'item',
            itemType: entry.type,
            itemData: entry.data,
            num: entry.num,
          });
        });
      }

      if (loot.scrap) {
        this.addPreviewEntry({
          change: 'added',
          type: 'scrap',
          num: loot.scrap,
        });
      }

      if (loot.crystals) {
        this.addPreviewEntry({
          change: 'added',
          type: 'crystal',
          num: loot.crystals,
        });
      }
    });
  }

  showPreviewList(): boolean {
    return !this.hudService.showInventory || this.battleService.hasBattle();
  }

  private addPreviewEntry(entry: PreviewEntry): void {
    // Do not show the entry if the inventory is being shown!
    if (!this.showPreviewList()) {
      return;
    }

    this.previewList.push(entry);
    setTimeout(() => {
      this.previewList.pop();
    }, 3500)
  }

}
