import {Component, Input} from '@angular/core';
import {StarSystemService} from '../../../../services/star-system.service';
import {InteractionService} from '../interaction.service';
import {ItemStackData} from '@game/characters/character';
import {DragSource} from '../../drag';
import {MapService} from '../../../../services/map.service';
import {HudService} from '../../hud.service';

@Component({
  selector: 'wg-menu-item-trader',
  templateUrl: 'item-trader.component.html',
  styleUrls: ['item-trader.component.less']
})
export class ItemTraderComponent {

  @Input() items: null | ItemStackData[] = null;

  constructor(private starSystemService: StarSystemService,
              private interactionService: InteractionService,
              private mapService: MapService,
              private hudService: HudService) {
  }

  sellDroppedItem(evt: DragEvent): void {
    const data = this.hudService.getDragData();
    if (!data) {
      return;
    }

    console.log('sell dropped item', data);

    if (data.type !== 'item') {
      evt.preventDefault();
      return;
    }

    this.interactionService.sellItemAt(data.pos);
  }

  onDragOver(evt: DragEvent): void {
    const data = this.hudService.getDragData();
    if (!data) {
      return;
    }

    if (data.source === DragSource.Inventory && data.type === 'item') {
      evt.preventDefault();
    }
  }

  onDragStart(pos: number, evt: DragEvent): void {
    if (!this.items || !this.items[pos]) {
      evt.preventDefault();
      return;
    }

    const item = this.items[pos];

    this.hudService.handleDragStart({
      source: DragSource.Trader,
      type: 'item',
      identifier: item.data.identifier,
    });
  }

  buyItem(pos: number): void {

    if (!this.items) {
      console.log('cannot buy - no set items')
      return;
    }

    if (!this.mapService.lockedOn) {
      console.warn('not locked to any entity');
      return;
    }

    const item = this.items[pos];

    this.starSystemService.rpc({
      method: 'BuyItem',
      params: {
        entityId: this.mapService.lockedOn?.entityId,
        identifier: item.data.identifier,
      }
    });
  }
}
