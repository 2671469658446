import {Vector2da} from '@shared/utils/vector2da';
import {EntityLayerName} from '@game/entities/map';
import {Faction} from '@game/entities/entity';
import {Rarity} from '../app/hud/shared/stats/rarity';

export enum EntityClass {
  Enemy = 0,
  Station = 1,
  Planet = 2,
  NPC = 3,
  Player = 4,
}

export enum EntityState {
  Idling = 'idle',
  Fighting = 'fighting',
  Warping = 'warping',
}

interface TickTraitData {
  isQuestGiver: boolean;
  questReceiverState: '' | 'r' | 'n';
  isLoreTeller: boolean;
  isItemTrader: boolean;
  isHullTrader: boolean;
  isBridgePillar: boolean;
  isHangarMechanic: boolean;
  isDepotManager: boolean
}

export interface TickShowData extends TickTraitData {
  index: number;
  name: string;
  asset: string;
  tier: number;

  class: EntityClass;

  faction: Faction;
  layer: EntityLayerName;

  position: Vector2da;
  velocity: Vector2da;

  rarity: Rarity;
  isCommander: boolean;
  characterRef: string;
  teamLevel: number;

  state: EntityState;
}

export interface TickTraitChangeData extends TickTraitData {
  index: number;
}

export interface TickStateChangeData {
  index: number;
  state: EntityState;
}

export interface TickHideData {
  index: number;
  reason: '' | 'removed' | 'death';
}

export interface TickPositionData {
  index: number;
  position: Vector2da;
  velocity: Vector2da;
}

export interface Tick {
  show?: TickShowData[];
  hide?: TickHideData[];
  positions?: TickPositionData[];
  traits?: TickTraitChangeData[];
  states?: TickStateChangeData[];
}
