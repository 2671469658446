import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {ChatComponent} from './hud/chat/chat.component';
import {TeamHudComponent} from './hud/team/team-hud.component';
import {MenuHudComponent} from './hud/menu/menu-hud.component';
import {MinimapComponent} from './hud/minimap/minimap.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ConfigService} from '../services/config.service';
import {MainMenuComponent} from './hud/mainmenu/main-menu.component';
import {FormsModule} from '@angular/forms';
import {TeamHudShipComponent} from './hud/team/components/team-hud-ship.component';
import {TeamMenuComponent} from './hud/team/team-menu.component';
import {Team3x3Component} from '@game/teams/team3x3.component';
import {ShipComponent} from '@game/ships/ship.component';
import {InventoryMenuComponent} from './hud/inventory/inventory-menu.component';
import {InventoryHudComponent} from './hud/inventory/inventory-hud.component';
import {InteractionContainerComponent} from './hud/interactions/interaction-container.component';
import {HudComponent} from './hud/hud.component';
import {FlatModStatsComponent} from './hud/shared/stats/flat-mod-stats.component';
import {DamageStatsComponent} from './hud/shared/stats/damage-stats.component';
import {ModuleStatsComponent} from './hud/shared/stats/module-stats.component';
import {ShipStatsComponent} from './hud/shared/stats/ship-stats.component';
import {BattleSkillsHudComponent} from './hud/battle/battle-skills-hud.component';
import {InventoryComponent} from './hud/shared/inventory/inventory.component';
import {ItemTraderComponent} from './hud/interactions/item-trader/item-trader.component';
import {InventoryItemComponent} from './hud/shared/inventory/inventory-item.component';
import {QuestHudComponent} from './hud/quests/hud/quest-hud.component';
import {HullTraderComponent} from './hud/interactions/hull-trader/hull-trader.component';
import {QuestLogComponent} from './hud/quests/menues/quest-log.component';
import {QuestIntroComponent} from './hud/interactions/quest-intro/quest-intro.component';
import {QuestService} from '../services/quest.service';
import {CharacterService} from '../services/character.service';
import {CombinationMenuComponent} from './hud/combination/combination-menu.component';
import {EnhancementMenuComponent} from './hud/enhancement/enhancement-menu.component';
import {ItemSlotComponent} from './hud/shared/inventory/item-slot.component';
import {CatalystStatsComponent} from './hud/shared/catalysts/catalyst-stats.component';
import {AppService} from './app.service';
import {SceneManagerService} from './scenes/scene-manager.service';
import {PrefixSignPipe} from '@shared/pipe/prefix-sign.pipe';
import {MenuComponent} from './hud/menu/menu.component';
import {InventoryService} from '../services/inventory.service';
import {MapService} from '../services/map.service';
import {BattleService} from '../services/battle.service';
import {HangarMechanicComponent} from './hud/interactions/hangar-mechanic/hangar-mechanic.component';
import {InteractionService} from './hud/interactions/interaction.service';
import {BattleRoundTimeHudComponent} from './hud/battle/battle-round-time-hud.component';
import {EntityHudComponent} from './hud/entity/entity-hud.component';
import {PartyHudComponent} from './hud/party/party-hud.component';
import {PartyService} from '../services/party.service';
import {SkillEntryComponent} from './hud/battle/skill-entry.component';
import {StatusEffectHudComponent} from './hud/status-effects/status-effect-hud.component';
import {StatComponent} from './hud/shared/stats/stat.component';
import {StatModifierComponent} from './hud/shared/stats/stat-modifier.component';
import {SkillStatComponent} from './hud/shared/stats/skill-stat.component';

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
  ],
  declarations: [
    AppComponent,

    // TODO Can be lazy loaded
    ChatComponent,
    TeamHudComponent,
    TeamHudShipComponent,
    MenuComponent,
    MenuHudComponent,
    MinimapComponent,
    InteractionContainerComponent,
    EntityHudComponent,
    PartyHudComponent,

    // Menus
    MainMenuComponent,
    TeamMenuComponent,
    Team3x3Component,
    ShipComponent,
    InventoryMenuComponent,
    InventoryHudComponent,
    HudComponent,

    BattleSkillsHudComponent,
    BattleRoundTimeHudComponent,

    FlatModStatsComponent,
    DamageStatsComponent,
    ModuleStatsComponent,
    ShipStatsComponent,
    CatalystStatsComponent,
    InventoryComponent,
    ItemTraderComponent,
    HullTraderComponent,
    HangarMechanicComponent,
    InventoryItemComponent,
    QuestHudComponent,
    QuestLogComponent,
    QuestIntroComponent,

    CombinationMenuComponent,
    EnhancementMenuComponent,
    ItemSlotComponent,

    PrefixSignPipe,
    SkillEntryComponent,
    StatusEffectHudComponent,
    StatComponent,
    SkillStatComponent,
    StatModifierComponent,
  ],
  providers: [
    ConfigService,

    {
      provide: HTTP_INTERCEPTORS,
      useClass: ConfigService,
      multi: true,
    },

    AppService,
    SceneManagerService,
    MapService,
    BattleService,
    QuestService,
    InventoryService,
    InteractionService,
    CharacterService,
    PartyService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
