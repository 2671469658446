import {Vector2da} from '@shared/utils/vector2da';
import {EntityLayerName} from '@game/entities/map';
import {EntityClass, EntityState} from '../../../projects/game/src/services/tick';
import {Rarity} from '../../../projects/game/src/app/hud/shared/stats/rarity';


export enum Faction {
  Neutral = '',
  EHE = 'ehe',
  Pirates = 'pirates',
}

export interface PositionFrame {
  position: Vector2da;
  timestamp: number;
}

export interface EntityConfig {
  entityId: number;

  position: Vector2da;
  anim?: gsap.core.Animation;

  name: string;
  asset: string;
  tier: number;
  faction: Faction;
  layer: EntityLayerName;
  class: EntityClass;
  rarity: Rarity;
  teamLevel: number;
  isCommander: boolean;
  characterRef: string;

  isQuestGiver?: boolean;
  questReceiverState?: '' | 'r' | 'n';
  isItemTrader?: boolean;
  isHullTrader?: boolean;
  isLoreTeller?: boolean;
  isBridgePillar?: boolean;
  isHangarMechanic?: boolean;
  isDepotManager?: boolean;

  state: EntityState;
}
