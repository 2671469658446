import {Component, Input} from '@angular/core';
import {SkillData} from '@game/battles/skills';
import {HudService} from '../hud.service';

@Component({
  selector: 'wg-skill-entry',
  templateUrl: './skill-entry.component.html',
  styleUrls: ['./skill-entry.component.less'],
})
export class SkillEntryComponent {

  @Input() skill: null | SkillData = null;
  @Input() active: boolean = false;
  @Input() unavailable: boolean = false;

  constructor(private hudService: HudService) {
  }

  showTooltip(): void {
    if (!this.skill) {
      return;
    }
    this.hudService.showSkillTooltip(this.skill);
  }

  hidePreview(): void {
    this.hudService.hideTooltip();
  }
}
