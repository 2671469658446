import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {catchError, map, tap} from 'rxjs/operators';
import jwtDecode, {JwtPayload} from 'jwt-decode';

interface UserLoginRequest {
  username: string;
  password: string;
}

interface UserRegisterRequest {
  email: string;
  password: string;
}

interface LoginResponse {
  accessToken: string;
  refreshToken: string;
}

interface RefreshRequest {
  token: string;
}

interface RefreshResponse {
  accessToken: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  private authenticated$: BehaviorSubject<null | boolean> = new BehaviorSubject<null | boolean>(null);

  constructor(private http: HttpClient) {
  }

  login(username: string, password: string): Observable<LoginResponse> {
    const req = {
      username: username,
      password: password
    } as UserLoginRequest;

    return this.http.post<LoginResponse>('{idm}/v1/auth/login', req).pipe(
      tap(resp => {
        if (!resp.accessToken || !resp.refreshToken) {
          throw new Error('missing critical data for login');
        }

        if (localStorage) {
          localStorage.setItem('accessToken', resp.accessToken);
          localStorage.setItem('refreshToken', resp.refreshToken);
        } else {
          sessionStorage.setItem('accessToken', resp.accessToken);
          sessionStorage.setItem('refreshToken', resp.refreshToken);
        }
        this.authenticated$.next(true);
      })
    );
  }

  refresh(): Observable<any> {
    const req = {
      token: this.getRefreshToken(),
    } as RefreshRequest;

    return this.http.post<RefreshResponse>('{idm}/v1/auth/refresh', req).pipe(
      tap((resp) => {
        if (!resp.accessToken) {
          throw new Error('missing critical data for refresh');
        }

        if (localStorage) {
          localStorage.setItem('accessToken', resp.accessToken);
        } else {
          sessionStorage.setItem('accessToken', resp.accessToken);
        }

        this.authenticated$.next(true);
      }, (err) => {
        // TODO Show message and logout?
        console.log('[AuthService] refresh failed: ', err);
      })
    );
  }

  register(username: string, email: string, password: string): Observable<boolean> {
    const req = {
      username: username,
      email: email,
      password: password,
    } as UserRegisterRequest;

    return this.http.post<boolean>('{idm}/v1/auth/register', req, {observe: 'response'}).pipe(
      map(resp => {
        const ok = (resp.status === 201);

        if (ok) {
          this.authenticated$.next(true);
          // TODO Toast
        }

        return ok;
      }),

    );
  }

  isAuthenticated(): Observable<boolean> {
    console.log('[AuthService] check authentication');

    const authKey = this.getAccessToken();

    if (!authKey) {
      this.clearAccessToken();
      if (!this.verifyRefreshToken()) {
        return of(false);
      } else {
        return this.refresh().pipe(
          map(x => true),
          catchError((err) => of(false)),
        );
      }
    }

    // Make some very basic checks if the jwt could be valid...
    const claims = jwtDecode<JwtPayload>(authKey);

    // Remove milliseconds of 'now' to match the claims exp format.
    const now = Math.floor(Date.now() / 1000);
    if (!claims.exp || claims.exp < now) {
      this.clearAccessToken();

      if (!this.verifyRefreshToken()) {
        return of(false);
      } else {
        return this.refresh().pipe(
          map(x => true),
          catchError((err) => of(false)),
        );
      }
    }

    return of(true);
  }

  verifyRefreshToken(): boolean {
    const refreshKey = this.getRefreshToken();

    if (!refreshKey) {
      return false;
    }

    // Make some very basic checks if the jwt could be valid...
    const claims = jwtDecode<JwtPayload>(refreshKey);

    // Remove millisecons of 'now' to match the claims exp format.
    const now = Math.floor(Date.now() / 1000);
    if (!claims.exp || claims.exp < now) {
      this.clearRefreshToken();
      return false;
    }

    return true;
  }

  private getAccessToken(): string {
    let key = localStorage.getItem('accessToken');
    if (!key) {
      key = sessionStorage.getItem('accessToken');
    }
    return key || '';
  }

  private clearAccessToken(): void {
    localStorage.removeItem('accessToken');
    sessionStorage.removeItem('accessToken');
  }

  private getRefreshToken(): string | null {
    let key = localStorage.getItem('refreshToken');
    if (!key) {
      key = sessionStorage.getItem('refreshToken');
    }
    return key;
  }

  private clearRefreshToken(): void {
    localStorage.removeItem('refreshToken');
    sessionStorage.removeItem('refreshToken');
  }

  getAuthToken(): Observable<string> {
    return this.isAuthenticated().pipe(map(_ => this.getAccessToken()));
  }
}
