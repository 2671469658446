import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {StarSystemService} from '../../../services/star-system.service';
import {Character} from '@game/characters/character';
import {Ship} from '@game/ships/ship';
import {CharacterService} from '../../../services/character.service';
import {HudService} from '../hud.service';

@Component({
  selector: 'wg-menu-team',
  templateUrl: 'team-menu.component.html',
  styleUrls: ['team-menu.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamMenuComponent implements OnInit {

  character: Character | null = null;
  ship: Ship | null = null;
  shipPosition: number | null = null;

  constructor(public starSystemService: StarSystemService,
              public characterService: CharacterService,
              private hudService: HudService,
              private cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.character = this.characterService.getCharacter();

    this.character.afterTeamChanged().subscribe((team) => {

      this.cdr.detectChanges();

      if (this.shipPosition !== null) {
        this.ship = team.getShipAt(this.shipPosition);
        this.hudService.setSelectedShip(this.ship);
        this.cdr.detectChanges();
      }

      team.afterShipsChanged().subscribe(() => {
        this.cdr.detectChanges();
      });
    });
  }

  onShipSelected(pos: number | null): void {
    if (!this.character) {
      return;
    }

    this.shipPosition = pos;
    if (pos !== null) {
      this.ship = this.character?.getTeam().getShipAt(pos);
    } else {
      this.ship = null;
    }

    this.hudService.setSelectedShip(this.ship);
  }
}
