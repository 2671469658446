export interface Range {
  rangeStart: number;
  rangeEnd: number;
}

export interface SplitFlatModValue {
  globalFlat: number;
  localFlat: number;
  localMod: number;
  globalMod: number;
}

export interface FlatModValue {
  globalFlat: number;
  globalMod: number;
}

export enum DamageType {
  Kinetic = 'kinetic',
  Energy = 'energy',
  Explosive = 'explosive',
}

export interface WeaponRange extends Range {
  includedFlat: number;
  includedMod: number;
}

export interface DamageStats {
  sources: WeaponRange[];
  localFlat: number;
  localMod: number;
  globalFlat: number;
  globalMod: number;
}

export interface SkillStats {
  name?: string;
  level?: number;
  multiplier?: FlatModValue;
  costs?: FlatModValue;
}

export function checkSkillStatsForContent(stats: SkillStats, onlyPositive: boolean = false): boolean {
  return ((stats.level && ((stats.level < 0) || (stats.level > 0 && !onlyPositive))) ||
    !!stats.multiplier?.globalFlat ||
    !!stats.multiplier?.globalMod ||
    !!stats.costs?.globalFlat ||
    !!stats.costs?.globalMod);
}

export interface StatData {
  damage: { [dt: string]: DamageStats };
  damageReduction: { [dt: string]: FlatModValue };
  damagePierce: { [dt: string]: FlatModValue };
  shields: SplitFlatModValue;
  shieldRegeneration: FlatModValue;
  structure: FlatModValue;
  apRegeneration: FlatModValue;
  buffRes: FlatModValue;
  debuffRes: FlatModValue;
  buffPierce: FlatModValue;
  debuffPierce: FlatModValue;
  allSkills: SkillStats;
  skillCategories: { [key: string]: SkillStats };
  skills: { [key: string]: SkillStats };
}

export interface RandomRange {
  rangeStartMin: number;
  rangeStartMax: number;
  rangeEndMin: number;
  rangeEndMax: number;
}

export interface DamageRange {
  sources: RandomRange[];
  localFlat: Range;
  localMod: Range;
  globalFlat: Range;
  globalMod: Range;
}

export interface FlatModRange {
  globalFlat: Range;
  globalMod: Range;
}

export interface SplitFlatModRange {
  globalFlat: Range;
  globalMod: Range;
  localFlat: Range;
  localMod: Range;
}

export interface SkillConfigEntry {
  level: Range;
  multiplier: FlatModRange;
  costs: FlatModRange;
}

export interface StatGenerator {
  damage: { [dt: string]: DamageRange };
  damageReduction: { [dt: string]: FlatModRange };
  damagePierce: { [dt: string]: FlatModRange };
  shields: SplitFlatModRange;
  shieldRegeneration: FlatModRange;
  structure: FlatModRange;
  apRegeneration: FlatModRange;
  buffRes: FlatModRange;
  buffPierce: FlatModRange;
  debuffRes: FlatModRange;
  debuffPierce: FlatModRange;
  moduleEfficiency: Range;
  allSkills: SkillConfigEntry;
  skillCategories: { [cat: string]: SkillConfigEntry };
  skills: { [ident: string]: SkillConfigEntry };
}
