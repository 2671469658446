import {Ship} from '@game/ships/ship';
import {AfterViewInit, Component, ElementRef, Input, OnChanges, ViewChild} from '@angular/core';
import {HudService, ItemType} from '../../../projects/game/src/app/hud/hud.service';
import {ModuleData} from '@game/modules/modules';
import {InventoryService} from '../../../projects/game/src/services/inventory.service';
import {DragData, DragSource} from '../../../projects/game/src/app/hud/drag';
import {ItemStackData} from '@game/characters/character';
import * as PIXI from 'pixi.js';
import {Faction} from '@game/entities/entity';
import {ShipFactory} from '@game/ships/ship.factory';

@Component({
  selector: 'wg-ship',
  templateUrl: './ship.component.html',
  styleUrls: ['./ship.component.less']
})
export class ShipComponent implements AfterViewInit, OnChanges {

  DragSource = DragSource;
  ItemType = ItemType;

  @Input() ship: null | Ship = null;
  @Input() position: number = -1;
  @Input() dragSource: DragSource = DragSource.Unknown;
  @Input() dragModules: boolean = true;

  @ViewChild('canvas') pixiContainer!: ElementRef;

  loading: { [slot: string]: boolean } = {};

  constructor(private hudService: HudService,
              private inventoryService: InventoryService) {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.redrawPreview();
    }, 0);
  }

  ngOnChanges(): void {
    if (this.pixiContainer) {
      setTimeout(() => {
        this.redrawPreview();
      }, 10);
    }
  }

  toInventoryItem(module?: ModuleData): undefined | ItemStackData {

    if (!module) {
      return undefined;
    }

    return {
      type: ItemType.Module,
      data: module,
      num: 1,
    };
  }

  onDragStart(evt: DragEvent): void {

    // Must stop here to avoid issues with underlying ship drag systems;
    evt.stopImmediatePropagation();

    this.hudService.handleDragStart({
      source: this.dragSource,
      type: 'ship',
      pos: this.position,
    });
  }

  onDragStartModule(evt: DragEvent, slotKey: string): void {
    // Must stop here to avoid issues with underlying ship drag systems;
    evt.stopImmediatePropagation();
    evt.stopPropagation();

    this.hudService.handleDragStart({
      source: this.dragSource,
      type: 'item',
      pos: this.position,
      slot: slotKey,
      stack: this.toInventoryItem(this.ship?.moduleSlots[slotKey].module),
    });
  }

  onDropModule(drag: DragData, slotKey: string): void {
    if (!this.ship) {
      return;
    }

    if (drag.source === DragSource.Inventory) {

      if (drag.pos === undefined) {
        console.log('cannot equip module - inventory slot missing');
        return;
      }

      this.loading[slotKey] = true;
      this.inventoryService.equipModule(this.position, slotKey, drag.pos).then((result) => {
        this.loading[slotKey] = false;
      }).catch((err) => {
        this.loading[slotKey] = false;
        console.log(err);
      })
    } else if (drag.source === DragSource.Team) {

      if (drag.pos === undefined || !drag.slot) {
        console.log('cannot equip module - shipID or slot missing');
        return;
      }

      this.loading[slotKey] = true;
      this.inventoryService.moveModule(drag.pos, drag.slot, this.position, slotKey).then((result) => {
        this.loading[slotKey] = false;
      }).catch((err) => {
        this.loading[slotKey] = false;
        console.log(err);
      })
    }
  }


  private redrawPreview(): void {
    if (!this.ship || !this.pixiContainer) {
      return;
    }

    // TODO There might be a memory leak here as the webgl contexts are not properly removed!

    var renderer = new PIXI.Renderer({
      antialias: false,
      clearBeforeRender: true,
      width: 128,
      height: 128,
      transparent: true,
      preserveDrawingBuffer: false,
      view: this.pixiContainer.nativeElement as HTMLCanvasElement
    });

    const shipObject = ShipFactory.createShipGameObject(this.ship, {
      parentFaction: Faction.EHE, // FIXME
    });

    shipObject.x = 64;
    shipObject.y = 64;

    renderer.render(shipObject);
    renderer.reset();
    renderer.destroy();
  }
}
