import {Team3x3} from '@game/teams/team3x3';
import {Ship, ShipData} from '@game/ships/ship';
import {Rarity} from '../../../projects/game/src/app/hud/shared/stats/rarity';

export interface CombatantConfig {
  combatantId: string;
  name: string;
  rarity: Rarity;
  teamType: string;
  teamLevel: number;
  ships: { [pos: number]: ShipData };
}

export class Combatant {
  private combatantId: string;
  private team: Team3x3; // TODO team
  private name: string;
  private rarity: Rarity;

  constructor(cfg: CombatantConfig) {
    const ships: Ship[] = [];

    Object.keys(cfg.ships).forEach((i) => {
      ships[+i] = new Ship(cfg.ships[+i]);
    });

    this.team = new Team3x3(cfg.combatantId, ships, cfg.teamLevel); // FIXME Teamfactory
    this.name = cfg.name;
    this.rarity = cfg.rarity;
    this.combatantId = cfg.combatantId;
  }

  getCombatantID(): string {
    return this.combatantId;
  }

  getDisplayName(): string {
    return this.name;
  }

  hasShip(shipId: string): boolean {
    return this.team.hasShip(shipId);
  }

  getShips(): Ship[] {
    return this.team.getShips();
  }

  getTeam(): Team3x3 {
    return this.team;
  }

  getRarity(): Rarity {
    return this.rarity;
  }
}
