import * as PIXI from 'pixi.js';
import {AppService} from '../../app.service';
import {Battle} from '@game/battles/battle';
import {BattleGameObject} from '@game/battles/battle.game-object';
import {ShipInBattleData} from '@game/battles/events';
import {Rarity} from '../../hud/shared/stats/rarity';

export class BattleDebugGameObject extends PIXI.Container {

  private battleGameObject: BattleGameObject;

  private moveForward: boolean = false;
  private turnLeft: boolean = false;
  private turnRight: boolean = false;
  private battle: Battle;

  constructor(private appService: AppService) {
    super();

    const c1 = {
      combatantId: '',
      name: 'User',
      rarity: Rarity.Uncommon,
      teamLevel: 1,
      ships: {
        0: {
          shipId: '13sa',
          asset: 'ships/cruiser_cheetah',
          shipStats: {
            maxStructure: 100,
            maxShields: 100,
          },
          shields: 50,
          structure: 50,
          teamStats: {},
          moduleSlots: {},
          isDefending: true,
          ap: 100,
          statusEffects: [{
            name: 'Test',
            level: 5,
            remaining: 3,
            duration: 5,
            harm: true,
          }],
        } as ShipInBattleData,
        1: {
          shipId: '13sa',
          asset: 'ships/cruiser_ocelot',
          shipStats: {},
          teamStats: {},
          moduleSlots: {},
        } as ShipInBattleData,
        2: {
          shipId: '13sa',
          asset: 'ships/cruiser_ocelot',
          shipStats: {},
          teamStats: {},
          moduleSlots: {},
        } as ShipInBattleData,
        3: {
          shipId: '13sa',
          asset: 'ships/destroyer_shark',
          shipStats: {},
          teamStats: {},
          moduleSlots: {},
        } as ShipInBattleData,
        4: {
          shipId: '13sa',
          asset: 'ships/destroyer_shark',
          shipStats: {},
          teamStats: {},
          moduleSlots: {},
        } as ShipInBattleData,
        5: {
          shipId: '13sa',
          asset: 'ships/destroyer_shark',
          shipStats: {},
          teamStats: {},
          moduleSlots: {},
        } as ShipInBattleData,
        6: {
          shipId: '13sa',
          asset: 'ships/destroyer_wolf',
          shipStats: {},
          teamStats: {},
          moduleSlots: {},
        } as ShipInBattleData,
        7: {
          shipId: '13sa',
          asset: 'ships/destroyer_wolf',
          shipStats: {},
          teamStats: {},
          moduleSlots: {},
        } as ShipInBattleData,
        8: {
          shipId: '13sa',
          asset: 'ships/destroyer_wolf',
          shipStats: {},
          teamStats: {},
          moduleSlots: {},
        } as ShipInBattleData,
      },
      teamType: 'team3x3',
    };

    const c2 = {
      combatantId: '',
      name: 'User2',
      rarity: Rarity.Common,
      teamLevel: 4,
      ships: {
        0: {
          shipId: '13sa',
          asset: 'ships/cruiser_cheetah',
          shipStats: {},
          teamStats: {},
          moduleSlots: {},
        } as ShipInBattleData,
        1: {
          shipId: '13sa',
          asset: 'ships/cruiser_ocelot',
          shipStats: {},
          teamStats: {},
          moduleSlots: {},
        } as ShipInBattleData,
        2: {
          shipId: '13sa',
          asset: 'ships/cruiser_ocelot',
          shipStats: {},
          teamStats: {},
          moduleSlots: {},
        } as ShipInBattleData,
        3: {
          shipId: '13sa',
          asset: 'ships/destroyer_shark',
          shipStats: {},
          teamStats: {},
          moduleSlots: {},
        } as ShipInBattleData,
        4: {
          shipId: '13sa',
          asset: 'ships/destroyer_shark',
          shipStats: {},
          teamStats: {},
          moduleSlots: {},
        } as ShipInBattleData,
        5: {
          shipId: '13sa',
          asset: 'ships/destroyer_shark',
          shipStats: {},
          teamStats: {},
          moduleSlots: {},
        } as ShipInBattleData,
        6: {
          shipId: '13sa',
          asset: 'ships/destroyer_wolf',
          shipStats: {},
          teamStats: {},
          moduleSlots: {},
        } as ShipInBattleData,
        7: {
          shipId: '13sa',
          asset: 'ships/destroyer_wolf',
          shipStats: {},
          teamStats: {},
          moduleSlots: {},
        } as ShipInBattleData,
        8: {
          shipId: '13sa',
          asset: 'ships/destroyer_wolf',
          shipStats: {},
          teamStats: {},
          moduleSlots: {},
        } as ShipInBattleData,
      },
      teamType: 'team3x3',
    };

    this.battle = new Battle('asd123', {
      environment: {
        identifier: 'clear_space',
        name: 'Clear Space'
      },
      biome: 'nebula',
      activeShips: {},
      activeGroup: 0,
      groups: [{
        combatants: {
          0: c1,
        },
      }, {
        combatants: {
          0: c2,
        }
      }],
      skills: [],
      roundTime: 30000,
    })

    this.battleGameObject = new BattleGameObject(this.battle);
    this.battleGameObject.attachTo(this);
  }

  handleKeys(evt: KeyboardEvent): void {
  }

  update(delta: number): void {
  }
}
