import * as PIXI from 'pixi.js';
import {getNearestTierTexture, Ship} from '@game/ships/ship';
import {HullSize, ShipGameObject} from '@game/ships/ship.game-object';
import {ExhaustGameObject} from '@game/ships/parts/exhaust.game-object';
import {ShipCreateOptions} from '@game/ships/ship.factory';

export class FrigateHippopotamusGameObject extends ShipGameObject {

  constructor(ship: Ship, opts: ShipCreateOptions) {
    super(ship, opts);

    // Left
    this.addExhaust(new ExhaustGameObject({
      track: this.ship,
      placement: -1,
      x: -22,
      y: 35,
      scale: 1.8,
    }));

    // Left-Mid
    this.addExhaust(new ExhaustGameObject({
      track: this.ship,
      placement: -0.5,
      x: -8,
      y: 35,
      scale: 1.8,
    }));

    // Right-Mid
    this.addExhaust(new ExhaustGameObject({
      track: this.ship,
      placement: 0.5,
      x: 10,
      y: 35,
      scale: 1.8,
    }));

    // Right
    this.addExhaust(new ExhaustGameObject({
      track: this.ship,
      placement: 1,
      x: 25,
      y: 35,
      scale: 1.8,
    }));

    const shipTexture = getNearestTierTexture(ship.getHighestModuleTier(), 'ships', 'frigate_hippopotamus_t%.png')
    if (shipTexture) {
      const sprite = new PIXI.Sprite(shipTexture);
      this.addChild(sprite);
    }
  }

  getSize(): number {
    return HullSize.Large;
  }
}
