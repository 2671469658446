import {AnimationTargets, EffectManager} from '@game/vfx/effect.manager';
import {ShipChanges} from '@game/battles/events';
import {EntityLayer} from '@game/entities/map';
import {vfxPlayHit} from '@game/vfx/kinetic.vfx';
import Timeline = gsap.core.Timeline;

EffectManager.instance.register('bomb_attack',
  (t: Timeline, targets: AnimationTargets, when: number, changes: { [p: string]: ShipChanges[] }) => {

    t.call(() => vfxPlayHit(targets.effectSourceObj), undefined, when);

    t.call(() =>
        EffectManager.instance.spawnAnimation(targets.effectSourceObj,
          'explosions',
          'explosion_d',
          1, 30, 0, EntityLayer.FOREGROUND, 0, 0.7)
      , undefined, when);

    t.call(() =>
        EffectManager.instance.spawnAnimation(targets.effectTargetObj,
          'explosions',
          'explosion_m',
          1, -5 + Math.random() * 10, -5 + Math.random() * 10, EntityLayer.FOREGROUND, 0, 0.7)
      , undefined, when + 0.8);

    targets.effectTargets.forEach((target) => {
      t.call(() =>
          EffectManager.instance.spawnAnimation(target.obj,
            'explosions',
            'explosion_i',
            1, -5 + Math.random() * 10, -5 + Math.random() * 10, EntityLayer.FOREGROUND, 0, 0.6)
        , undefined, when + 1);

      t.call(() =>
          EffectManager.instance.spawnAnimation(target.obj,
            'explosions',
            'explosion_d',
            3, -5 + Math.random() * 10, -5 + Math.random() * 10, EntityLayer.BACKGROUND, 0, 1.2)
        , undefined, when + 1.1);

    });

    t.call(() => EffectManager.instance.updateShips(changes), undefined, when + 1.2);

    return 1;
  }
);


EffectManager.instance.register('rocket_attack',
  (t: Timeline, targets: AnimationTargets, when: number, changes: { [p: string]: ShipChanges[] }) => {

    t.call(() => vfxPlayHit(targets.effectSourceObj), undefined, when);

    t.call(() =>
        EffectManager.instance.spawnAnimation(targets.effectSourceObj,
          'explosions',
          'explosion_d',
          1, 30, 0, EntityLayer.FOREGROUND, 0, 0.7)
      , undefined, when);

    t.call(() =>
        EffectManager.instance.spawnAnimation(targets.effectTargetObj,
          'explosions',
          'explosion_m',
          1, -5 + Math.random() * 10, -5 + Math.random() * 10, EntityLayer.FOREGROUND, 0, 0.7)
      , undefined, when + 0.8);

    targets.effectTargets.forEach((target) => {
      t.call(() =>
          EffectManager.instance.spawnAnimation(target.obj,
            'explosions',
            'explosion_i',
            1, -5 + Math.random() * 10, -5 + Math.random() * 10, EntityLayer.FOREGROUND, 0, 0.6)
        , undefined, when + 1);

      t.call(() =>
          EffectManager.instance.spawnAnimation(target.obj,
            'explosions',
            'explosion_d',
            3, -5 + Math.random() * 10, -5 + Math.random() * 10, EntityLayer.BACKGROUND, 0, 1.2)
        , undefined, when + 1.1);

    });

    t.call(() => EffectManager.instance.updateShips(changes), undefined, when + 1.2);

    return 1;
  }
);

EffectManager.instance.register('kinetic_barrage',
  (t: Timeline, targets: AnimationTargets, when: number, changes: { [p: string]: ShipChanges[] }) => {

    t.call(() => vfxPlayHit(targets.effectSourceObj), undefined, when);

    targets.effectTargets.forEach((target) => {
      t.call(() => {
        // this.debugPlayHit(target.obj);
      }, undefined, when + 1);

      t.call(() =>
          EffectManager.instance.spawnAnimation(target.obj,
            'explosions',
            'explosion_i',
            1, -5 + Math.random() * 10, -5 + Math.random() * 10, EntityLayer.FOREGROUND, 0, 0.6)
        , undefined, when);

      t.call(() =>
          EffectManager.instance.spawnAnimation(target.obj,
            'explosions',
            'explosion_d',
            3, -5 + Math.random() * 10, -5 + Math.random() * 10, EntityLayer.BACKGROUND, 0, 1.2)
        , undefined, when + 0.2);

      t.call(() =>
          EffectManager.instance.spawnAnimation(target.obj,
            'explosions',
            'explosion_b',
            3, -5 + Math.random() * 10, -5 + Math.random() * 10, EntityLayer.FOREGROUND, 0, 0.7)
        , undefined, when + 0.3);

    });

    t.call(() => EffectManager.instance.updateShips(changes), undefined, when + 1.2);

    return 1;
  }
);
