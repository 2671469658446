import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {HangarData, HullTraderUpgradeOptions, InteractionService} from '../interaction.service';
import {StarSystemService} from '../../../../services/star-system.service';
import {Ship} from '@game/ships/ship';
import {HudService} from '../../hud.service';
import {CharacterService} from '../../../../services/character.service';
import {DragSource} from '../../drag';

@Component({
  selector: 'wg-menu-hangar-mechanic',
  templateUrl: 'hangar-mechanic.component.html',
  styleUrls: ['hangar-mechanic.component.less']
})
export class HangarMechanicComponent implements OnInit, OnChanges {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  DragSource = DragSource;

  @Input() data: null | HangarData = null;

  public selectedShip: null | Ship = null;
  public upgradePaths: null | { [hullId: string]: HullTraderUpgradeOptions } = null;

  hangar: { [id: number]: null | Ship } = {};

  fullRepairCosts: number = 0;
  fullReviveCosts: number = 0;

  constructor(private starSystemService: StarSystemService,
              private interactionService: InteractionService,
              private hudService: HudService,
              private characterService: CharacterService) {
  }

  ngOnInit(): void {
    this.hudService.watchSelectedShip().subscribe(ship => {
      this.selectedShip = ship;
    });
  }

  ngOnChanges(): void {
    if (!this.data) {
      return;
    }

    console.log('[HangarMechanic] data changed!');

    const data = this.data;
    let maxEntry = data.hangarSize;

    if (data.hangar) {
      // Track the current legecy hangar size and account for "index vs. size" difference.
      let hangarMax = Object.keys(data.hangar).reduce((p, c) => Math.max(p, +c), 0);
      hangarMax += 1;

      if (maxEntry < hangarMax) {
        maxEntry = hangarMax
      }
    }

    for (let i = 0; i < maxEntry; i++) {
      const cfg = data.hangar && data.hangar[i];
      if (cfg) {
        this.hangar[i] = new Ship(cfg);
      } else {
        this.hangar[i] = null;
      }
    }

    console.log(data.hangar, maxEntry, this.hangar);

    this.fullRepairCosts = Object.values(data.repairPrices).reduce((p, c) => p + c, 0);
    this.fullReviveCosts = Object.values(data.revivePrices).reduce((p, c) => p + c, 0);
  }

  onDragOver(evt: DragEvent, to: number): void {
    const data = this.hudService.getDragData();
    if (!data) {
      return;
    }

    if (data.type === 'ship') {

      const from = data.pos;

      if (from !== to) {
        evt.preventDefault();
      }
    }
  }

  onDrop(evt: DragEvent, hangerPos: number): void {
    const drag = this.hudService.getDragData();
    if (!drag) {
      return;
    }

    if (drag.type !== 'ship') {
      evt.preventDefault();
    }

    if (drag.type !== 'ship') {
      return;
    }

    if (drag.source === DragSource.Team) {

      if (drag.pos === undefined) {
        console.log('cannot exchange - identifier missing')
        return;
      }

      this.characterService.exchangeShip(drag.pos, hangerPos).then((result) => {}).catch((err) => {
        console.log(err);
      });

    } else if (drag.source === DragSource.Hangar) {

      if (drag.pos === undefined || drag.pos === hangerPos) {
        return;
      }

      this.characterService.moveHangar(drag.pos, hangerPos).then((result) => {}).catch((err) => {
        console.log(err);
      });
    }

  }

  repairAll(): void {
    this.characterService.repairAll().then(() => {

    }, (err) => {

    });
  }

  reviveAll(): void {
    this.characterService.reviveAll().then(() => {

    }, (err) => {

    });
  }

  repairShip(shipId: string): void {
    this.characterService.repairShip(shipId).then(() => {

    }, (err) => {

    });
  }

  reviveShip(shipId: string): void {
    this.characterService.reviveShip(shipId).then(() => {

    }, (err) => {

    });
  }
}
