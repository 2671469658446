import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Ship} from '@game/ships/ship';
import {Subscription} from 'rxjs';
import {HudService} from '../../hud.service';

@Component({
  selector: 'wg-team-hud-ship',
  templateUrl: './team-hud-ship.component.html',
  styleUrls: ['./team-hud-ship.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamHudShipComponent implements OnInit, OnDestroy {
  @Input() ship: null | Ship = null;

  private structureSub: null | Subscription = null;
  private destroyedSub: null | Subscription = null;

  constructor(private cdr: ChangeDetectorRef,
              private hudService: HudService) {
  }

  showTooltip(text: string): void {
    this.hudService.showTooltip(text);
  }

  ngOnInit(): void {

    if (!this.ship) {
      throw new Error('team hud requires a ship as input');
    }

    this.structureSub = this.ship.afterStructureChanged().subscribe((struct) => {
      this.cdr.detectChanges();
    });

    this.structureSub = this.ship.afterShieldChanged().subscribe((struct) => {
      this.cdr.detectChanges();
    });

    this.destroyedSub = this.ship.afterDestroyed().subscribe((struct) => {
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy(): void {
    if (this.structureSub) {
      this.structureSub.unsubscribe();
      this.structureSub = null;
    }

    if (this.destroyedSub) {
      this.destroyedSub.unsubscribe();
      this.destroyedSub = null;
    }
  }

  getStructurePercentage(): string {
    if (!this.ship) {
      return '0%';
    }

    const perc = this.ship.structure / this.ship.maxStructure;

    return Math.max(2, (100 * perc)).toFixed(0) + '%';
  }

  getShieldPercentage(): string {
    if (!this.ship) {
      return '0%';
    }

    const perc = this.ship.shields / this.ship.maxShields;

    return Math.max(2, (100 * perc)).toFixed(0) + '%';
  }

  getLevelPercentage(): string {
    if (!this.ship) {
      return '0%';
    }

    return Math.max(1, (this.ship.levelProgress)).toFixed(0) + '%';
  }

  hideTooltip(): void {
    this.hudService.hideTooltip();
  }

  showStructureTooltip(): void {
    if (!this.ship) {
      return;
    }
    this.hudService.showTooltip(`${this.ship.structure} / ${this.ship.maxStructure}`);
  }

  showShieldTooltip(): void {
    if (!this.ship) {
      return;
    }
    this.hudService.showTooltip(`${this.ship.shields} / ${this.ship.maxShields}`);
  }

  showExperienceTooltip(): void {
    if (!this.ship) {
      return;
    }
    this.hudService.showTooltip(`${this.ship.totalXP} / ${this.ship.requiredXP} (${this.getLevelPercentage()})`);
  }
}
