import {screenHeight, screenWidth} from '../../screen';
import {StarSystemService} from '../../../services/star-system.service';
import {Scene} from '../../app.component';
import * as PIXI from 'pixi.js';

export class DeathScene extends PIXI.Container implements Scene {

  private txtDied: PIXI.Text;
  private overlay: PIXI.Graphics;

  constructor(gameService: StarSystemService) {
    super();

    this.overlay = new PIXI.Graphics();

    this.overlay.beginFill(0x000000);
    this.overlay.drawRect(0, 0, screenWidth(), screenHeight());

    this.txtDied = new PIXI.Text('YOU DIED', {fontSize: '60px', fill: 'red'});
    this.txtDied.position.set(screenWidth() / 2, screenHeight() / 2);
    this.txtDied.anchor.set(0.5);

    this.addChild(this.overlay);
    this.addChild(this.txtDied);
  }

  destroy(options?: { children?: boolean; texture?: boolean; baseTexture?: boolean }): void {
    super.destroy(options);
  }

  update(delta: number): void {

  }
}
