import {Subject} from 'rxjs';
import {Ship} from '@game/ships/ship';
import {GridEvent, GridOrientation, Team3x3} from '@game/teams/team3x3';
import {ShipFactory} from '@game/ships/ship.factory';
import {ShipGameObject} from '@game/ships/ship.game-object';
import * as PIXI from 'pixi.js';
import {gsap, Power2, Power4} from 'gsap';

export enum CellStatus {
  Hovered = 'hover',
  Attacker = 'attacker',
  Target = 'target',
  Attacked = 'attacked',
  Pattern = 'pattern',
}

const BAR_MOVE_DURATION = 2;
const FADE_OUT_DURATION = 1;

const HEALTH_TEXT_OFFSET = 22;

const CELL_SHIP_OFFSET = -5;

export class SquareCellGameObject extends PIXI.Container {

  public static SQUARE_WIDTH = 120;
  public static SQUARE_HEIGHT = 120;

  static Styles = {
    hover: {
      alpha: 0.6,
    },
    attacker: {
      alpha: 0.4,
      tint: 0x4CAF50,
    },
    target: {
      alpha: 0.4,
      tint: 0x0000FF,
    },
    pattern: {
      alpha: 0.4,
      tint: 0xFF0000,
    },
    attacked: {
      alpha: 0,
    },
    default: {
      alpha: 0.05,
      tint: 0xFFFFFF,
    }
  };

  // Styling lowest to highest
  static StylingPriority = [
    CellStatus.Attacker,
    CellStatus.Target,
    CellStatus.Pattern,
    CellStatus.Attacked,
    CellStatus.Hovered,
  ];

  pointerOver$ = new Subject<GridEvent>();
  pointerOut$ = new Subject<GridEvent>();
  leftDown$ = new Subject<GridEvent>();
  rightDown$ = new Subject<GridEvent>();

  // tslint:disable-next-line:variable-name
  private _status: CellStatus[] = [];

  private nameTag: PIXI.Text | null = null;
  private structureBar: PIXI.Graphics | null = null;
  private xpBar: PIXI.Graphics | null = null;
  private shieldBar: PIXI.Graphics | null = null;
  private apCounter: PIXI.Text | null = null;

  private textAnims: gsap.core.Tween[] = [];

  private statusEffects: PIXI.Text | null = null;
  private defendingText: PIXI.Text | null = null;

  private fromShield: number = 0;
  private fromStructure: number = 0;
  private fromLevelProgress: number = 0;
  private shieldChangeAnim: gsap.core.Tween | null = null;
  private structureChangeAnim: gsap.core.Tween | null = null;
  private xpBarFadeout: gsap.core.Tween | null = null;

  get status(): CellStatus[] {
    return this._status;
  }

  private contentObject: PIXI.Container;
  private fieldObject: PIXI.Graphics;
  private shipObject: ShipGameObject | null = null;
  private ship: Ship;

  constructor(private team: Team3x3, private orientation: GridOrientation, private pos: number, shipSize: number) {
    super();
    this.ship = this.team.getShipAt(this.pos);

    const width = SquareCellGameObject.SQUARE_WIDTH;
    const height = SquareCellGameObject.SQUARE_HEIGHT;

    this.fieldObject = new PIXI.Graphics();
    this.fieldObject.beginFill(0xFFFFFF, 1);
    this.fieldObject.drawRect(-(width / 2), -(height / 2), width, height);
    this.addChild(this.fieldObject);

    this.contentObject = new PIXI.Container();

    // TODO: Maybe render some sort of destroyed ship instead of nothing!
    if (this.ship && !this.ship.isDestroyed()) {
      this.shipObject = ShipFactory.createShipGameObject(this.ship, {});
      this.addChild(this.shipObject);
      this.shipObject.x = 0;
      this.shipObject.y = CELL_SHIP_OFFSET;
      this.shipObject.angle = orientation;

      // Initialize with the current ships size.
      this.shipObject.updateScale(shipSize);

      // Initialize the update bar.
      this.fromLevelProgress = this.ship.levelProgress;

      this.ship.afterDefendingChanged().subscribe(() => {
        this.updateDefending();
      })

      this.ship.afterStatusEffectsChanged().subscribe(() => {
        this.updateStatusEffects();
      });

      this.ship.afterResisted().subscribe(() => {
        this.spawnRandomText(`Resisted`, 'yellow');
      });

      this.ship.afterXPGained().subscribe((xp) => {
        this.flashXPBar();
      });

      this.ship.afterLevelUp().subscribe(() => {
        this.playLevelUp();
      });

    } else {
      console.log('no ship at ', this.pos);
    }

    this.addChild(this.contentObject);

    this.updateStyling();

    if (this.ship) {
      this.showNameTag();
      this.showAPCounter();
      this.showHealthBar();
      this.updateStatusEffects();
    }
  }

  getShipSize(): number {
    if (this.shipObject) {
      return this.shipObject.getSize();
    }
    return 0;
  }

  updateShipSize(globalScale: number, refSize: number, animDuration: number): void {
    if (this.shipObject) {
      const size = this.shipObject.getSize();

      // Counter the scaling to have the bars always correlate with the
      // size of the square.
      const f = 1 / globalScale;
      console.log('Counter the scale to ', globalScale, f, size, refSize)
      if (animDuration) {
        gsap.to(this.contentObject.scale, {
          x: f,
          y: f,
          duration: animDuration,
          ease: Power2.easeInOut,
        });
      } else {
        this.contentObject.scale.set(f);
      }

      this.shipObject.updateScale(refSize, animDuration);
    }
  }

  addStatus(s: CellStatus): void {
    if (this._status.includes(s)) {
      return;
    }
    this._status.push(s);
    this.updateStyling();
  }

  removeStatus(s: CellStatus): void {
    if (!this._status.includes(s)) {
      return;
    }

    const i = this._status.findIndex(e => e === s);
    this._status.splice(i, 1);

    this.updateStyling();
  }

  hasStatus(s: CellStatus): boolean {
    return this._status.includes(s);
  }

  enableInteraction(): void {
    this.fieldObject.interactive = true;
    this.fieldObject.on('pointerover', () => {
      this.onPointerOver();
    });
    this.fieldObject.on('pointerout', () => {
      this.onPointerOut();
    });
    this.fieldObject.on('mousedown', () => {
      this.onLeftDown();
    });
    this.fieldObject.on('rightdown', () => {
      this.onRightDown();
    });
  }

  onPointerOver(): void {
    this.addStatus(CellStatus.Hovered);

    this.pointerOver$.next({
      source: this,
      ship: this.ship,
    });
  }

  onPointerOut(): void {
    this.removeStatus(CellStatus.Hovered);

    this.pointerOut$.next({
      source: this,
      ship: this.ship,
    });
  }

  onLeftDown(): void {
    this.leftDown$.next({
      source: this,
      ship: this.ship,
    });
  }

  onRightDown(): void {
    this.rightDown$.next({
      source: this,
      ship: this.ship,
    });
  }

  destroy(options?: { children?: boolean; texture?: boolean; baseTexture?: boolean }): void {

    this.shieldBar?.destroy();
    this.shieldBar = null;

    this.structureBar?.destroy();
    this.structureBar = null;

    this.apCounter?.destroy();
    this.apCounter = null;

    if (this.statusEffects) {
      this.statusEffects.destroy(options);
      this.statusEffects = null;
    }

    this.textAnims.forEach(anim => anim.kill());

    if (this.shieldChangeAnim) {
      this.shieldChangeAnim.kill();
    }

    if (this.structureChangeAnim) {
      this.structureChangeAnim.kill();
    }

    super.destroy(options);
  }

  private updateStyling(): void {
    let style = SquareCellGameObject.Styles.default;

    // Merge all active styles
    SquareCellGameObject.StylingPriority.forEach(stylename => {
      if (this.status.includes(stylename)) {
        style = {...style, ...SquareCellGameObject.Styles[stylename]};
      }
    });

    // Apply status
    Object.keys(style).forEach((key: string) => {
      (this.fieldObject as any)[key] = (style as any)[key];
    });
  }

  private spawnRandomText(txt: string, color: string): void {

    const x = (Math.random() * 140 - 70);
    const y = (Math.random() * 140 - 70);

    this.spawnText(txt, color, x, y);
  }

  private spawnText(txt: string, color: string, x: number, y: number, duration = 10, anchor = new PIXI.Point(0, 0.5)): void {
    if (!this.parent) {
      return;
    }

    const text = new PIXI.Text(txt, {
      fontSize: '16px',
      fill: color,
      fontWeight: 300,
      fontFamily: 'Lucida Console',
    });

    text.x = this.x + x;
    text.y = this.y + y;
    text.anchor = anchor;

    this.parent.addChild(text);

    const anim = gsap.to(text, {
      alpha: 0,
      duration: duration,
      ease: Power4.easeOut,
      onComplete: () => {
        text.destroy();
      },
    });

    // TODO Should be cleaned regularly
    this.textAnims.push(anim);
  }

  private showNameTag(): void {
    // TODO Replace with variable.
    this.nameTag = new PIXI.Text(this.ship.displayName, {fontSize: '12px', fill: 'white'});
    this.nameTag.y = 8;
    this.nameTag.anchor.set(0.5, 0.5);
    this.contentObject.addChild(this.nameTag);
  }

  private hideNameTag(): void {
    if (!this.nameTag) {
      return;
    }
    this.nameTag.destroy();
    this.nameTag = null;
  }

  private updateShieldBar(): void {
    if (!this.shieldBar) {
      return;
    }

    this.shieldBar.clear();

    // Draw the background of the shield bar.
    this.shieldBar.beginFill(0x011f4b);
    this.shieldBar.drawRect(0, 0, 50, 6);

    if (this.ship.isShieldRestoring) {
      // Draw the recharge bar.
      this.shieldBar.beginFill(0xC5A6DD);
      let wRec = 46;
      this.shieldBar.drawRect(2, 1, wRec, 4);
    } else {
      // Draw the actual health bar
      this.shieldBar.beginFill(0x005b96);
      const w = 46 * this.ship.shields / this.ship.maxShields;
      this.shieldBar.drawRect(2, 1, w, 4);

      // Draw the bar that indicates the change.
      if (this.fromShield < this.ship.shields) {
        this.shieldBar.beginFill(0x008AE0);
      } else {
        this.shieldBar.beginFill(0x004068);
      }
      const wstart = 46 * this.fromShield / this.ship.maxShields;
      const wend = w - wstart;
      this.shieldBar.drawRect(2 + wstart, 1, wend, 4);
    }

    this.shieldBar.endFill();
  }

  private updateStructureBar(): void {
    if (!this.structureBar) {
      return;
    }

    this.structureBar.clear();

    // Draw the background.
    this.structureBar.beginFill(0x651e3e);
    this.structureBar.drawRect(0, 0, 50, 6);

    // Draw the structure bar.
    this.structureBar.beginFill(0xCC061D);
    const w = 46 * this.ship.structure / this.ship.maxStructure;
    this.structureBar.drawRect(2, 1, w, 4);

    // Draw the bar that indicates the change.
    if (this.fromStructure < this.ship.structure) {
      this.structureBar.beginFill(0xFF0724);
    } else {
      this.structureBar.beginFill(0x77000F);
    }

    const wdiff = 46 * this.fromStructure / this.ship.maxStructure;
    const wbar = w - wdiff;
    this.structureBar.drawRect(2 + wdiff, 1, wbar, 4);

    this.structureBar.endFill();
  }

  private handleShieldChange(): void {
    if (this.shieldChangeAnim) {
      this.shieldChangeAnim.kill();
    }
    this.shieldChangeAnim = gsap.to(this, {
      fromShield: this.ship.shields,
      duration: Math.abs(this.ship.shields - this.fromShield) / this.ship.maxShields * BAR_MOVE_DURATION,
      onUpdate: () => {
        this.updateShieldBar();
      },
    });
  }

  private handleStructureChange(): void {
    if (this.structureChangeAnim) {
      this.structureChangeAnim.kill();
    }

    // Immediately hide the health bars and actions bars when the ship has been
    // destroyed!
    if (this.ship.structure <= 0) {
      this.afterShipDestroyed();
    } else {
      this.structureChangeAnim = gsap.to(this, {
        fromStructure: this.ship.structure,
        duration: Math.abs(this.ship.structure - this.fromStructure) / this.ship.maxStructure * BAR_MOVE_DURATION,
        onUpdate: () => {
          this.updateStructureBar();
        },
      });
    }
  }

  private showHealthBar(): void {
    this.shieldBar = new PIXI.Graphics();
    this.shieldBar.x = -25;
    this.shieldBar.y = 15;

    this.handleShieldChange();

    this.ship.afterDamageTaken().subscribe((dmg) => {
      this.spawnRandomText(`${dmg}`, '0xFF0000');
    });
    this.ship.afterHealTaken().subscribe((heal) => {

      // WARN: Ignore any heal <= 0 for now. but it might be good to show them. Maybe just
      // to emphasize that no healing has been done.
      if (heal <= 0) {
        return;
      }

      this.spawnText(`${heal}`, '0x0000FF', 35, HEALTH_TEXT_OFFSET);
    });

    this.ship.afterShieldChanged().subscribe(() => {
      this.handleShieldChange();
    });
    this.ship.afterShieldRestoringChange().subscribe(() => {
      this.handleShieldChange();
    });
    this.contentObject.addChild(this.shieldBar);

    this.structureBar = new PIXI.Graphics();
    this.structureBar.x = -25;
    this.structureBar.y = 20;

    this.handleStructureChange();

    this.ship.afterStructureChanged().subscribe(() => {
      this.handleStructureChange();
    });

    this.contentObject.addChild(this.structureBar);
  }

  private hideHealthBar(): void {
    if (!this.structureBar || !this.shieldBar) {
      return;
    }

    gsap.to(this.shieldBar, {
      alpha: 0,
      ease: Power4.easeOut,
      duration: FADE_OUT_DURATION,
      onComplete: () => {
        if (this.shieldBar) {
          this.shieldBar.destroy();
          this.shieldBar = null;
        }
      },
    });

    gsap.to(this.structureBar, {
      alpha: 0,
      ease: Power4.easeOut,
      duration: FADE_OUT_DURATION,
      onComplete: () => {
        if (this.structureBar) {
          this.structureBar.destroy();
          this.structureBar = null;
        }
      },
    });
  }

  private updateAPCounter(): void {
    if (!this.apCounter) {
      return;
    }

    this.apCounter.text = '' + this.ship.ap;
  }

  private showAPCounter(): void {
    this.apCounter = new PIXI.Text('' + this.ship.ap, {
      fontSize: '12px',
      fill: 'yellow',
      fontFamily: 'Lucida Console',
    });
    this.apCounter.position.set(25, 28);
    this.apCounter.anchor.set(1, 0);
    this.apCounter.alpha = 1;
    this.updateAPCounter();
    this.ship.afterAPChanged().subscribe(() => {
      this.updateAPCounter();
    });
    this.contentObject.addChild(this.apCounter);
  }

  private hideAPCounter(): void {
    if (!this.apCounter) {
      return;
    }
    gsap.to(this.apCounter, {
      alpha: 0,
      ease: Power4.easeOut,
      duration: FADE_OUT_DURATION,
      onComplete: () => {
        if (this.apCounter) {
          this.apCounter.destroy();
          this.apCounter = null;
        }
      },
    });
  }

  private updateDefending(): void {
    if (!this.defendingText) {
    }

    if (this.ship.isDefending) {

    } else {

    }
  }

  private updateStatusEffects(): void {
    if (!this.statusEffects) {
      this.statusEffects = new PIXI.Text('', {fontSize: '12px', fill: 'yellow'});
      this.statusEffects.position.set(-60, 60);
      this.statusEffects.alpha = 0.8;
      this.statusEffects.anchor.set(0, 1);
      this.contentObject.addChild(this.statusEffects);
    }

    if (this.ship.statusEffects?.length) {
      this.statusEffects.text = this.ship.statusEffects.map(e => `${e.name} ${e.level} [${e.remaining}]`).join('\n');
    } else {
      this.statusEffects.text = '';
    }
  }

  private hideStatusEffects(): void {
    if (!this.statusEffects) {
      return;
    }
    this.statusEffects.destroy();
    this.statusEffects = null;
  }

  playLevelUp(): void {
    this.spawnText('LEVEL UP', 'yellow', 0, -50, 20, new PIXI.Point(0.5));
  }

  private flashXPBar(): void {

    if (!this.xpBar) {
      this.xpBar = new PIXI.Graphics();
      this.xpBar.x = -25;
      this.xpBar.y = 10;
    }

    if (this.xpBarFadeout) {
      this.xpBarFadeout.kill();
    }

    this.xpBar.alpha = 1;
    this.updateXPBar();

    this.structureChangeAnim = gsap.to(this, {
      fromLevelProgress: this.ship.levelProgress,
      duration: BAR_MOVE_DURATION / 2,
      delay: 1,
      onUpdate: () => {
        this.updateXPBar();
      },
      onComplete: () => {
        setTimeout(() => {
          if (this.xpBar) {
            this.xpBar.alpha = 0;
          }
        }, 1000);
      },
    });

    this.contentObject.addChild(this.xpBar);
  }

  private updateXPBar(): void {
    if (!this.xpBar) {
      return;
    }

    this.xpBar.clear();

    // Draw the background.
    this.xpBar.beginFill(0x333333);
    this.xpBar.drawRect(0, 0, 50, 6);

    // Draw the xp bar.
    this.xpBar.beginFill(0xdeb301);
    const w = 46 * this.ship.levelProgress / 100;
    this.xpBar.drawRect(2, 1, w, 4);

    // Draw the update bar.
    this.xpBar.beginFill(0xDEC601);
    const wstart = 46 * (this.fromLevelProgress / 100);
    const wend = w - wstart;
    this.xpBar.drawRect(2 + wstart, 1, wend, 4);

    this.xpBar.endFill();
  }

  private afterShipDestroyed(): void {
    this.hideNameTag();
    this.hideStatusEffects();
    this.hideHealthBar();
    this.hideAPCounter();

    // Also kill the shield animation now.
    if (this.shieldChangeAnim) {
      this.shieldChangeAnim.kill();
    }
  }
}
