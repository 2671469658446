import {ShipEntityGameObject} from '@game/entities/ship-entity.game-object';
import {EntityGameObject} from '@game/entities/entity.game-object';
import {FallbackEntity} from '@game/entities/fallback.game-object';
import {PlanetEntityGameObject} from '@game/entities/planets/planet-entity.game-object';
import {EntityConfig} from '@game/entities/entity';
import {CargoStationAGameObject} from '@game/entities/stations/cargo-station-a.game-object';
import {BattleStationAGameObject} from '@game/entities/stations/battle-station-a.game-object';
import {Character} from '@game/characters/character';

export interface EntityOptions {
  // Register with the effect manager? [true]
  register?: boolean

  // Prefix for the effect manager [entity]
  effectPrefix?: string;

  // Reference to the character all entities will be shown for.
  charRef: Character

  onSelect?: () => void;
  onInteraction?: () => void;
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
}

export class EntityFactory {

  public static RequiredAssets = [
    {
      name: 'stations/cargo-stations-a',
      src: 'stations/cargo-stations-a@2x.json'
    },
    {
      name: 'stations/battle-stations-a',
      src: 'stations/battle-stations-a@2x.json'
    },
    {
      name: 'planets/rastaban_alero',
      src: 'planets/rastaban_alero.png'
    },
    {
      name: 'planets/rastaban_beta_draconis',
      src: 'planets/rastaban_beta_draconis.png'
    },
    {
      name: 'planets/rastaban_filorus',
      src: 'planets/rastaban_filorus.png'
    },
    {
      name: 'planets/rastaban_inner_asteroid',
      src: 'planets/rastaban_inner_asteroid.png'
    },
    {
      name: 'planets/rastaban_jarfen',
      src: 'planets/rastaban_jarfen.png'
    },
    {
      name: 'planets/rastaban_nagwal',
      src: 'planets/rastaban_nagwal.png'
    },
    {
      name: 'planets/rastaban_outer_asteroid',
      src: 'planets/rastaban_outer_asteroid.png'
    },
  ];

  static create(cfg: EntityConfig, options: EntityOptions): EntityGameObject {

    options = options || {};
    options = Object.assign({
      register: true,
      effectPrefix: 'entity'
    }, options);

    const spacerIndex = cfg.asset.indexOf('/');
    const category = cfg.asset.substring(0, spacerIndex);

    const segments = cfg.asset.split('/');
    let asset = segments[segments.length - 1];

    switch (category) {
      case 'ships':
        return new ShipEntityGameObject(cfg, options);
      case 'planets':
        return new PlanetEntityGameObject(cfg, options);
      case 'stations':
        switch (asset) {
          case 'cargo-station-a':
            return new CargoStationAGameObject(cfg, options);
          case 'battle-station-a':
            return new BattleStationAGameObject(cfg, options);
        }
    }

    cfg.name = cfg.name || asset;

    console.error('unknown entity type: ' + cfg.asset);
    return new FallbackEntity(cfg, options);
  }
}
