import {Component, Input} from '@angular/core';
import {CombinationCatalystData, EnhancementCatalystData} from '@game/characters/character';

@Component({
  selector: 'wg-catalyst-stats',
  templateUrl: './catalyst-stats.component.html'
})
export class CatalystStatsComponent {
  @Input() data!: CombinationCatalystData | EnhancementCatalystData;
}
