import {Injectable} from '@angular/core';
import {Ship, StatModifier} from '@game/ships/ship';
import {BehaviorSubject, Observable} from 'rxjs';
import {ItemData} from '@game/characters/character';
import {AppService, AppState} from '../app.service';
import {DragData} from './drag';
import {SkillData} from '@game/battles/skills';
import {Environment} from '@game/environments/environment';
import {StarSystemService} from '../../services/star-system.service';

export enum ItemType {
  Module = 'module',
  Quest = 'quest',
  Key = 'key',
  CombinationCatalyst = 'combinationCatalyst',
  EnhancementCatalyst = 'enhancementCatalyst',
}

@Injectable({
  providedIn: 'root',
})
export class HudService {

  showMenu: boolean;
  showTeamMenu: boolean;
  showInventory: boolean;
  showQuestLog: boolean;
  showCombinationMenu: boolean;
  showEnhancementMenu: boolean;

  tooltip: null | {
    type: 'text' | 'item' | 'skill' | 'statModifier' | 'environment';

    // text
    text?: string;

    // environment
    environment?: Environment;

    // statModifier
    statModifier?: StatModifier;

    // skill
    skill?: SkillData;

    // item / module
    itemType?: ItemType;
    item?: ItemData;
    isFallback?: boolean;
  } = null;

  state: null | AppState = null;

  private dragData$ = new BehaviorSubject<null | DragData>(null);
  private selectedShip$: BehaviorSubject<null | Ship> = new BehaviorSubject<null | Ship>(null);
  private showAdvanced: boolean = false;


  constructor(private appService: AppService,
              private starSystemService: StarSystemService) {

    this.showMenu = false;
    this.showTeamMenu = false;
    this.showInventory = false;
    this.showQuestLog = false;
    this.showCombinationMenu = false;
    this.showEnhancementMenu = false;

    this.selectedShip$ = new BehaviorSubject<null | Ship>(null);

    this.appService.afterStateChanged().subscribe((state) => {
      this.hideTooltip();
      this.handleDragEnd();
      this.closeEverything();
      this.state = state;
    });

    // window.addEventListener('wheel', this.handleZoom.bind(this));
    window.addEventListener('keydown', this.handleKeyDown.bind(this));
    window.addEventListener('keyup', this.handleKeyUp.bind(this));
    window.addEventListener('dragend', this.handleDragEnd.bind(this));
    window.addEventListener('drop', this.handleDragEnd.bind(this));

    this.starSystemService.afterDeath().subscribe(() => {
      this.closeEverything();
    });

    this.starSystemService.afterConnectionClosed().subscribe(() => {
      this.closeEverything();
    });
  }


  public handleDragStart(d: DragData): void {
    console.log('[HudService] React on DragStart...');
    this.hideTooltip();
    this.dragData$.next(d);
  }

  public handleDragEnd(): void {
    console.log('[HudService] React on DragEnd...');
    this.dragData$.next(null);
  }

  public getDragData(): null | DragData {
    return this.dragData$.getValue();
  }

  toggleTeamMenu(): void {
    this.showTeamMenu = !this.showTeamMenu;

    if (!this.showTeamMenu) {
      this.hideTooltip();
    }
  }

  toggleInventory(): void {
    this.showInventory = !this.showInventory;

    if (!this.showInventory) {
      this.hideTooltip();
    }
  }

  toggleCombinationMenu(): void {
    this.showCombinationMenu = !this.showCombinationMenu;

    if (!this.showCombinationMenu) {
      this.hideTooltip();
    } else {
      this.showEnhancementMenu = false;
    }
  }

  toggleEnhancementMenu(): void {
    this.showEnhancementMenu = !this.showEnhancementMenu;

    if (!this.showEnhancementMenu) {
      this.hideTooltip();
    } else {
      this.showCombinationMenu = false;
    }
  }


  toggleFullscreen(): void {
    document.body.requestFullscreen();
  }

  showStatModifierTooltip(statMod: StatModifier): void {
    this.tooltip = {
      type: 'statModifier',
      statModifier: statMod,
    };
  }

  showSkillTooltip(skill: SkillData): void {
    this.tooltip = {
      type: 'skill',
      skill: skill,
    };
  }

  showItemTooltip(item: any, type: ItemType, isFallback: boolean): void {
    this.tooltip = {
      type: 'item',

      itemType: type,
      item: item,
      isFallback: isFallback,
    };
  }

  showTooltip(text: string): void {
    this.tooltip = {
      type: 'text',
      text: text,
    };
  }

  showEnvironmentTooltip(env: Environment): void {
    this.tooltip = {
      type: 'environment',
      environment: env,
    };
  }

  hideTooltip(): void {
    this.tooltip = null;
  }

  setSelectedShip(ship: null | Ship): void {
    this.selectedShip$.next(ship);
  }

  watchSelectedShip(): Observable<null | Ship> {
    return this.selectedShip$;
  }

  toggleQuestLog(): void {
    this.showQuestLog = !this.showQuestLog;

    if (!this.showQuestLog) {
      this.hideTooltip();
    }
  }

  showsAnyMenu(): boolean {
    return this.showInventory ||
    this.showTeamMenu ||
    this.showQuestLog ||
    this.showCombinationMenu ||
    this.showEnhancementMenu;
  }

  closeEverything(): void {
    this.showMenu = false;
    this.showInventory = false;
    this.showTeamMenu = false;
    this.showQuestLog = false;
    this.showCombinationMenu = false;
    this.showEnhancementMenu = false;
    this.hideTooltip();
  }

  handleKeyDown(evt: KeyboardEvent): void {
    // This is not the way. Prevents everything.
    // evt.preventDefault();

    if (evt.altKey || evt.metaKey) {
      this.showAdvanced = true;
      evt.preventDefault();
    }
  }

  handleKeyUp(evt: KeyboardEvent): void {
    // This is not the way. Prevents everything.
    // evt.preventDefault();

    if (evt.code === 'KeyI') {
      this.toggleInventory();
    } else if (evt.code === 'KeyC') {
      this.toggleTeamMenu();
    } else if (evt.code === 'KeyJ') {
      this.toggleQuestLog();
    } else if (evt.code === 'KeyG') {
      this.toggleCombinationMenu();
    } else if (evt.code === 'KeyH') {
      this.toggleEnhancementMenu();
    } else if (evt.code === 'Escape') {

      if (this.showMenu) {
        this.showMenu = false;
      } else if (this.showsAnyMenu()) {
        this.closeEverything();
      } else {
        this.showMenu = true;
      }
    }

    if (!evt.altKey && !evt.metaKey) {
      this.showAdvanced = false;
      evt.preventDefault();
    }
  }

  afterDragChanged(): Observable<null | DragData> {
    return this.dragData$;
  }

  isAdvancedShown(): boolean {
    return this.showAdvanced;
  }
}
