import {BehaviorSubject} from 'rxjs';

export const DEFAULT_SCREEN_WIDTH = 1920;
export const DEFAULT_SCREEN_HEIGHT = 1080;

export const screen$ = new BehaviorSubject<Dimensions>({
  width: 1280,
  height: 780,
});

export interface Dimensions {
  width: number;
  height: number;
}

export function screenWidth(): number {
  return screen$.getValue().width;
}

export function screenHeight(): number {
  return screen$.getValue().height;
}
