export enum EntityLayerName {
  FAR_BACKGROUND = 'farBackground',
  BACKGROUND = 'background',
  NEAR_BACKGROUND = 'nearBackground',
  FAR_DISTANT = 'farDistant',
  DISTANT = 'distant',
  NEAR_DISTANT = 'nearDistant',
  FAR_NPC = 'farNpc',
  NPC = 'npc',
  NEAR_NPC = 'nearNpc',
  CHARACTER = 'character',
  NEAR_FOREGROUND = 'nearForeground',
  FOREGROUND = 'foreground',
  HUD = 'hud',
}

export function convertLayer(n: EntityLayerName): EntityLayer {
  switch (n) {
    case EntityLayerName.FAR_BACKGROUND:
      return EntityLayer.FAR_BACKGROUND;
    case EntityLayerName.BACKGROUND:
      return EntityLayer.BACKGROUND;
    case EntityLayerName.NEAR_BACKGROUND:
      return EntityLayer.NEAR_BACKGROUND;
    case EntityLayerName.FAR_DISTANT:
      return EntityLayer.FAR_DISTANT;
    case EntityLayerName.DISTANT:
      return EntityLayer.DISTANT;
    case EntityLayerName.NEAR_DISTANT:
      return EntityLayer.NEAR_DISTANT;
    case EntityLayerName.FAR_NPC:
      return EntityLayer.FAR_NPC;
    case EntityLayerName.NPC:
      return EntityLayer.NPC;
    case EntityLayerName.NEAR_NPC:
      return EntityLayer.NEAR_NPC;
    case EntityLayerName.CHARACTER:
      return EntityLayer.CHARACTER;
    case EntityLayerName.NEAR_FOREGROUND:
      return EntityLayer.NEAR_FOREGROUND;
    case EntityLayerName.FOREGROUND:
      return EntityLayer.FOREGROUND;
    case EntityLayerName.HUD:
      return EntityLayer.HUD;
  }
}

export enum EntityLayer {
  FAR_BACKGROUND = 5,
  BACKGROUND = 15,
  NEAR_BACKGROUND = 25,
  FAR_DISTANT = 35,
  DISTANT = 55,
  NEAR_DISTANT = 65,
  FAR_NPC = 71,
  NPC = 72,
  NEAR_NPC = 73,
  CHARACTER = 75,
  NEAR_FOREGROUND = 85,
  FOREGROUND = 95,
  HUD = 100,
}

const parallaxLevel = 0.14;
const charParallax = 7;

export function parallaxLayerByZIndex(zIndex: number): number {
  return Math.floor(zIndex / 10);
}

export function parallaxLayerFactor(paraLayer: number): number {
  if (paraLayer < charParallax) {
    return (charParallax - paraLayer) * parallaxLevel;
  } else if (paraLayer > charParallax) {
    return -1 * (paraLayer - charParallax) * parallaxLevel;
  }

  return 0;
}
