import {Component, Input} from '@angular/core';
import {ModuleData} from '@game/modules/modules';
import {ItemStackData} from '@game/characters/character';

@Component({
  selector: 'wg-inventory-item',
  styleUrls: ['./inventory-item.component.less'],
  templateUrl: './inventory-item.component.html',
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InventoryItemComponent {
  @Input() stack: ItemStackData | undefined;
  @Input() fallback: boolean | undefined;

  getRarityClass(): string {
    if (!this.stack) {
      return '';
    }

    if (this.fallback) {
      return 'text-fallback';
    }

    const mod = this.stack.data as ModuleData;

    if (mod.rarity) {
      return 'text-' + mod.rarity;
    }

    return '';
  }

  asModule(): null | ModuleData {
    if (!this.stack) {
      return null;
    }
    return this.stack.data as ModuleData;
  }

  getUpgradeClass(): string {
    if (!this.stack) {
      return '';
    }

    const mod = this.stack.data as ModuleData;
    return `upgrade-${mod.upgrade}`;
  }
}
