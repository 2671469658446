import * as PIXI from 'pixi.js';
import {DisplayObject} from 'pixi.js';
import {AppService} from '../../app.service';
import {ShipFactory} from '@game/ships/ship.factory';
import {Ship} from '@game/ships/ship';
import {HullSize, ShipGameObject} from '@game/ships/ship.game-object';
import {EffectManager} from '@game/vfx/effect.manager';

const SHIP_DISTANCE = 300;

export class ShipDebugGameObject extends PIXI.Container {

  private entity: DisplayObject = new PIXI.DisplayObject();
  private ship: Ship;
  private ships: ShipGameObject[] = [];

  private moveForward: boolean = false;
  private turnLeft: boolean = false;
  private turnRight: boolean = false;

  constructor(private appService: AppService) {
    super();

    this.ship = new Ship({
      structure: 1,
      maxStructure: 1,
    } as any);

    // Render all ships.
    Object.keys(ShipFactory.shipFactories).forEach((assetName, i) => {
      const factory = ShipFactory.shipFactories[assetName];

      const shipGameObject = factory(this.ship, {});
      shipGameObject.track(this.entity);

      // Place on a grid to see them all.
      shipGameObject.position.set(SHIP_DISTANCE * (i % 5), SHIP_DISTANCE * Math.floor(i / 5));
      shipGameObject.updateScale(HullSize.Small);

      // Place a name tag.
      const txt = new PIXI.Text(assetName, {fontSize: '20px', fill: 'red'});
      txt.position.x = shipGameObject.position.x;
      txt.position.y = shipGameObject.position.y + 50;
      txt.anchor.set(0.5, 0);
      this.addChild(txt);

      this.ships.push(shipGameObject);
      this.addChild(shipGameObject);
    });

    this.addChild(this);
  }

  handleKeys(evt: KeyboardEvent): void {
    if (evt.key === 'w') {
      this.moveForward = true;
    } else if (evt.key === 's') {
      this.moveForward = false;
    }
    if (evt.key === 'a') {
      this.turnLeft = true;
      this.turnRight = false;
    } else if (evt.key === 'd') {
      this.turnLeft = false;
      this.turnRight = true;
    } else {
      this.turnLeft = false;
      this.turnRight = false;
    }

    if (evt.key === 'k') {
      this.ship.updateStats({
        structure: 0,
      });
    }
  }

  update(delta: number): void {
    this.ships.forEach(s => s.update(delta));

    if (this.moveForward) {
      this.entity.position.y += 10;
    } else {
      this.entity.position.y = 0;
    }

    if (this.turnLeft) {
      this.entity.rotation += 10;
    } else if (this.turnRight) {
      this.entity.rotation -= 10;
    } else {
      this.entity.rotation = 0;
    }
  }
}
