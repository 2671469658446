import {Team3x3} from '@game/teams/team3x3';
import {Ship, ShipData} from '@game/ships/ship';
import {Vector2da} from '@shared/utils/vector2da';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';
import {ItemType} from '../../../projects/game/src/app/hud/hud.service';
import {Faction} from '@game/entities/entity';
import {Rarity} from '../../../projects/game/src/app/hud/shared/stats/rarity';
import {ModuleType} from '@game/modules/modules';
import {ShipChanges} from '@game/battles/events';
import {FlatModValue} from '@game/stats/stat-data';

export interface ItemData {
  name: string;
  identifier: string;
  stackSize: number;

  rarity: Rarity;
  description?: string;
  lore?: string;
  scrapValue?: number;
}

export interface ItemStackData {
  type: ItemType;
  data: ItemData;
  num: number;

  locked?: boolean;
}

export interface EnhancementCatalystData extends ItemData {
  ilvlMin: null | number;
  ilvlMax: null | number;
  upgradeMin: null | number;
  upgradeMax: null | number;
  moduleTypes: null | ModuleType[];
  moduleRarity: null | Rarity[];
  critChance: null | number;
  maxCrit: null | number;
  probability: null | number;
}

export interface CombinationCatalystData extends ItemData {
  ilvlMin: null | number;
  ilvlMax: null | number;
  upgradeMin: null | number;
  upgradeMax: null | number;
  moduleTypes: null | ModuleType[];
  moduleRarity: null | Rarity[];
  critChance: null | number;
  maxCrit: null | number;
  probability: null | number;

  offerModuleReq: null | 'sameType' | 'sameModule';
  offerModuleLevelReq: null | 'sameOrBetter';
  offerModuleUpgradeReq: null | 'sameOrBetter';
  offerModuleRarityReq: null | 'same';
}

export interface InventoryData {
  size: number;
  items: ItemStackData[];
}

export interface QuestObjectiveData {
  shortDescription: string;
  collected: number;
  required: number;
  marker?: Location;
}

export interface QuestData {
  name: string;
  objectives: QuestObjectiveData[];
  marker?: Location;
}

export interface QuestTrackerData { [key: string]: QuestData; }

export interface TeamData {
  teamType: 'team3x3'
  ships: { [pos: number]: ShipData }
  teamLevel: number;
}

export interface CombinedEntityStats {
  viewRange: FlatModValue;
  movementSpeed: FlatModValue;
  rotationSpeed: FlatModValue;
  shieldRecharge: FlatModValue;
}

export interface ReducedEntityStats {
  viewRange: number;
  movementSpeed: number;
  rotationSpeed: number;
  shieldRecharge: number;
}

export interface CharacterData {
  characterId: string;
  name: string;
  team: TeamData;
  faction: Faction;
  position: Vector2da;
  asset: string;
  moduleTier: number;
  inventory: InventoryData;

  scrap: number;
  crystals: number;

  combinedStats: CombinedEntityStats;
  reducedStats: ReducedEntityStats;

  quests: QuestTrackerData;
}


export class Character {

  private team!: Team3x3;
  private teamChanged$ = new Subject<Team3x3>();
  private visibleShip: null | Ship = null;

  private characterId: string;
  private name: string;
  private position$: BehaviorSubject<Vector2da>;

  private faction: Faction;

  private combinedStats: CombinedEntityStats;
  private reducedStats: ReducedEntityStats;

  constructor(cfg: CharacterData) {

    this.characterId = cfg.characterId;

    this.name = cfg.name;
    this.faction = cfg.faction;

    this.position$ = new BehaviorSubject<Vector2da>(cfg.position);

    this.visibleShip = new Ship({
      asset: cfg.asset,
      faction: cfg.faction,
      moduleTier: cfg.moduleTier,
    } as ShipData);

    this.combinedStats = cfg.combinedStats;
    this.reducedStats = cfg.reducedStats;

    // Restore this team.
    this.setTeam(cfg.team);
  }

  getIdentifier(): string {
    return this.characterId;
  }

  getName(): string {
    return this.name;
  }

  getVisibleShip(): null | Ship {
    return this.visibleShip;
  }

  getPosition(): Vector2da {
    return this.position$.getValue();
  }

  move(p: Vector2da): void {
    this.position$.next(p);
  }

  afterPositionChanged(): Observable<Vector2da> {
    return this.position$.pipe(
      distinctUntilChanged((v1, v2) => v1.x === v2.x && v1.y === v2.y && v1.angle === v2.angle),
    );
  }

  getTeam(): Team3x3 {
    return this.team;
  }

  getFaction(): Faction {
    return this.faction;
  }

  updateShip(shipId: string, changes: ShipChanges): void {
    this.team.updateShip(shipId, changes);
  }

  setTeam(team: TeamData): void {
    // Restore all ships.
    const ships: Ship[] = [];
    Object.keys(team.ships).forEach((pos: string) => {
      ships[+pos] = new Ship(team.ships[+pos]);
    });

    if (this.team) {
      this.team.destroy();
    }

    this.team = new Team3x3(this.characterId, ships, team.teamLevel);
    this.teamChanged$.next(this.team);
  }

  afterTeamChanged(): Observable<Team3x3> {
    return this.teamChanged$;
  }

  getMovementSpeed(): number {
    return this.reducedStats.movementSpeed;
  }

  getRotationSpeed(): number {
    return this.reducedStats.rotationSpeed;
  }

  getViewRange(): number {
    return this.reducedStats.viewRange;
  }

  updateStats(comb: CombinedEntityStats, red: ReducedEntityStats): void {
    this.combinedStats = comb;
    this.reducedStats = red;
  }
}
