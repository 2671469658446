import * as PIXI from 'pixi.js';
import {getNearestTierTexture, Ship} from '@game/ships/ship';
import {HullSize, ShipGameObject} from '@game/ships/ship.game-object';
import {ExhaustGameObject} from '@game/ships/parts/exhaust.game-object';
import {ShipCreateOptions} from '@game/ships/ship.factory';

export class CruiserCougarGameObject extends ShipGameObject {

  constructor(ship: Ship, opts: ShipCreateOptions) {
    super(ship, opts);

    this.loadStructure({
      type: 'body',
      config: {
        asset: 'body_03_variant_03',
        x: 0,
        y: -3,
      },
      children: [{
        type: 'exhaust',
        config: {
          track: this.ship,
          placement: -1,
          x: -10,
          y: 37,
          scale: 1.5,
        }
      }, {
        type: 'exhaust',
        config: {
          track: this.ship,
          placement: 1,
          x: 12,
          y: 37,
          scale: 1.5,
        }
      }, {
        type: 'drive',
        config: {
          asset: 'drive_06',
          x: 0,
          y: 21,
          scale: 0.90,
        }
      }, {
        type: 'wing',
        config: {
          asset: 'wing_r_06',
          x: 6,
          y: 0,
        },
      }, {
        type: 'wing',
        config: {
          asset: 'wing_r_06',
          x: -6,
          y: 0,
          scaleX: -1,
        }
      }]
    });

    // const shipTexture = getNearestTierTexture(ship.getHighestModuleTier(), 'ships', 'cruiser_cougar_t%.png')
    // if (shipTexture) {
    //   const sprite = new PIXI.Sprite(shipTexture);
    //   sprite.x -= 100;
    //   this.addChild(sprite);
    // }
  }

  getSize(): number {
    return HullSize.Small;
  }
}
