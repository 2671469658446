import {Component, OnDestroy, OnInit} from '@angular/core';
import {PartyService} from '../../../services/party.service';
import {Observable, Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {PartyInvite, PartyMember, PartyStatus} from '../../../services/galaxy.service';
import {AppService} from '../../app.service';
import {Character} from '@game/characters/character';
import {CharacterService} from '../../../services/character.service';
import {MapService} from '../../../services/map.service';

@Component({
  selector: 'wg-party-hud',
  templateUrl: './party-hud.component.html',
  styleUrls: ['./party-hud.component.less'],
})
export class PartyHudComponent implements OnInit, OnDestroy {

  invites: PartyInvite[] = [];
  party: null | PartyStatus = null;
  sortedMembers: PartyMember[] = [];

  private destroyed$ = new Subject<void>();
  ownId: string = '';

  constructor(private characterService: CharacterService,
              private partyService: PartyService,
              private mapService: MapService) {

  }

  ngOnInit(): void {
    this.partyService.afterInvitesChanged().pipe(
      takeUntil(this.destroyed$),
    ).subscribe((invites) => {
      this.invites = invites;
    });

    this.partyService.afterPartyChanged().pipe(
      takeUntil(this.destroyed$),
    ).subscribe((party) => {
      this.party = party;

      if (this.party) {
        this.sortMembers();
      } else {
        this.sortedMembers = [];
      }
    });

    this.characterService.watchCharacter().pipe(
      takeUntil(this.destroyed$),
    ).subscribe((char) => {
      this.ownId = char.getIdentifier();
      this.sortMembers();
    });

  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  hasInvites(): boolean {
    return this.invites.length > 0;
  }

  hasParty(): boolean {
    return this.party !== null;
  }

  getOwnMapId(): string {
    return this.mapService.getIdentifier();
  }

  acceptInvite(invite: PartyInvite): void {
    this.partyService.acceptInvite(invite);
  }

  denyInvite(invite: PartyInvite): void {
    this.partyService.denyInvite(invite);
  }

  sortMembers(): void {
    if (!this.party || !this.ownId) {
      this.sortedMembers = [];
      return;
    }

    console.log('[Party] Sort ...', this.ownId);
    this.sortedMembers = this.party.members.filter(m => {
      return m.characterId === this.ownId;
    });

    const others = this.party.members.filter(m => {
      return m.characterId !== this.ownId;
    });
    this.sortedMembers.push(...others);
  }
}
