import {Component, Input, OnChanges} from '@angular/core';
import {QuestGiverData} from '../interaction.service';
import {StarSystemService} from '../../../../services/star-system.service';

@Component({
  selector: 'wg-quest-intro',
  styleUrls: ['quest-intro.component.less'],
  templateUrl: 'quest-intro.component.html',
})
export class QuestIntroComponent implements OnChanges {

  @Input() questId: string = '';
  @Input() entityId: number = 0;

  data: null | QuestGiverData = null;
  loading: boolean = false;

  constructor(private starSystemService: StarSystemService) {
  }

  ngOnChanges(): void {
    this.loading = true;

    this.starSystemService.getQuestIntro(this.questId, this.entityId).then((resp: QuestGiverData) => {
      this.data = resp;
    }).catch((err) => {
      console.error('[Interaction] error: ', err);
    }).finally(() => {
      this.loading = false;
    });
  }

  nl2br(text: string): string {
    return text.replace('\n', '<br>');
  }
}
