import {SkillStats} from '@game/stats/stat-data';

export enum SkillSource {
  Weapons = 'weapons',
  Kinetic = 'kinetic',
  Energy = 'energy',
  Explosive = 'explosive',
  Shields = 'shields',
  MaxShields = 'maxShields',
}

export enum SkillTargeting {
  Front = 'front',
  All = 'all',
  Self = 'self',
}

export enum HitPattern {
  // Single hits a single target.
  //
  // ###
  // #X#
  // ###
  //
  Single = 'single',

  // Pierce2 hits 2 targets in a row.
  //
  // ###
  // X!#
  // ###
  //
  Pierce2 = 'pierce2',

  // Pierce3 hits 3 targets in a row.
  //
  // ###
  // X!!
  // ###
  //
  Pierce3 = 'pierce3',

  // Wide3 hits the to neighbors
  //
  // !##
  // X##
  // !##
  //
  Wide3 = 'wide3',

  // Cross hits all neighbours in a cross.
  //
  // #!#
  // !X!
  // #!#
  //
  Cross = 'cross',

  // All hits all enemies in a team.
  //
  // !!!
  // !X!
  // !!!
  //
  All = 'all'
}

export interface SkillData {
  identifier: string;
  description: any;
  name: string;
  harm: boolean;
  costs: number;
  asset: string;
  targeting: string;
  patterns: HitPattern[];
  level: number;
  maxLevel: number;
  category: string;

  stats: SkillStats;
}
