import {Component} from '@angular/core';
import {CombinationCatalystData, ItemStackData} from '@game/characters/character';
import {ModuleData, ModuleType} from '@game/modules/modules';
import {Rarity} from '../shared/stats/rarity';
import {StarSystemService} from '../../../services/star-system.service';
import {HudService, ItemType} from '../hud.service';

@Component({
  selector: 'wg-menu-enhancement',
  templateUrl: './enhancement-menu.component.html',
  styleUrls: ['./enhancement-menu.component.less'],
})
export class EnhancementMenuComponent {

  ItemType = ItemType;

  catalystStack: null | ItemStackData = null;
  catalystPos: null | number = null;

  sourceStack: null | ItemStackData = null;
  sourcePos: null | number = null;

  loading: boolean = false;

  get catalyst(): null | CombinationCatalystData {
    return this.catalystStack?.data as CombinationCatalystData;
  }

  get source(): ModuleData {
    return this.sourceStack?.data as ModuleData;
  }

  get allowedSourceRarity(): null | Rarity[] {
    return this.catalyst?.moduleRarity || null;
  }

  get allowedSourceModuleTypes(): null | ModuleType[] {
    return this.catalyst?.moduleTypes || null;
  }

  get allowedSourceItemLevelMin(): null | number {
    return this.catalyst?.ilvlMin || null;
  }

  get allowedSourceItemLevelMax(): null | number {
    return this.catalyst?.ilvlMax || null;
  }

  get allowedSourceUpgradeLevelMin(): null | number {
    return this.catalyst?.upgradeMin || null;
  }

  get allowedSourceUpgradeLevelMax(): null | number {
    return this.catalyst?.upgradeMax || null;
  }

  constructor(private starSystemService: StarSystemService,
              private hudService: HudService) {
  }

  enhance(): void {
    this.loading = true;

    this.starSystemService.rpc({
      method: 'EnhanceModule',
      params: {
        catalystPos: this.catalystPos,
        sourceId: this.source.moduleId,
      },
    }).then((resp) => {
      // TODO Show a window with the result?
      // TODO Update the underlying stacks.
      console.log('[Combination] Successful', resp);

    }).catch((err) => {
      console.error('combination failed:', err);
    }).finally(() => {
      this.loading = false;
    });
  }
}
