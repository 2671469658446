import {Component, Input} from '@angular/core';
import {StatData, StatGenerator} from '@game/stats/stat-data';
import {AffixData, ModuleData, ModuleType} from '@game/modules/modules';
import {Rarity} from './rarity';
import {HudService} from '../../hud.service';
import {CharacterService} from '../../../../services/character.service';

@Component({
  selector: 'wg-module-stats',
  styleUrls: [
    './stats.less',
    './module-stats.component.less'
  ],
  templateUrl: './module-stats.component.html',
})
export class ModuleStatsComponent {
  @Input() data: null | ModuleData = null;

  get name(): string {
    if (!this.data || !this.data.name) {
      return '---';
    }
    return this.data.name.replace('\n', '<br>');
  }

  get baseName(): string {
    if (!this.data?.baseName) {
      return '---';
    }
    return this.data?.baseName.replace('\n', '<br>');
  }

  get combinedShipStats(): StatData | null {
    return this.data?.combinedShipStats || null;
  }

  get combinedTeamStats(): StatData | null {
    return this.data?.combinedTeamStats || null;
  }

  get shipStats(): StatData | null {
    return this.data?.shipStats || null;
  }

  get shipStatsSource(): StatGenerator | null {
    return this.data?.shipStatsSource || null;
  }

  get teamStats(): StatData | null {
    return this.data?.teamStats || null;
  }

  get teamStatsSource(): StatGenerator | null {
    return this.data?.teamStatsSource || null;
  }

  get rarity(): Rarity {
    return this.data?.rarity || Rarity.Common;
  }

  get type(): ModuleType {
    return this.data?.type || ModuleType.None;
  }

  get gearScore(): number {
    return this.data?.gearScore || -1;
  }

  get ilvl(): number {
    return this.data?.ilvl || -1;
  }

  get upgrade(): number {
    return this.data?.upgrade || 0;
  }

  constructor(public hudService: HudService, private characterService: CharacterService) {
  }

  isAvailable(): boolean {
    return this.characterService.getCharacter().getTeam().getHighestShipLevel() >= this.ilvl;
  }

  getAffixes(type: 'prefix' | 'suffix' | 'legendary'): AffixData[] | null {
    if (!this.data || !this.data.affixes) {
      return null;
    }
    return this.data.affixes.filter((afx) => afx.type === type);
  }
}
