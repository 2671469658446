import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {QuestTrackerData} from '@game/characters/character';
import {QuestService} from '../../../../services/quest.service';

@Component({
  selector: 'wg-hud-quests',
  templateUrl: './quest-hud.component.html',
  styleUrls: ['./quest-hud.component.less']
})
export class QuestHudComponent implements OnInit {

  quests$: Observable<QuestTrackerData> | null = null;

  constructor(private questService: QuestService) {
  }

  ngOnInit(): void {
    this.quests$ = this.questService.watchQuests();
  }
}
