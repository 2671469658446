import * as PIXI from 'pixi.js';
import {gsap, Power1, Sine} from 'gsap';

import {screenHeight, screenWidth} from '../../../projects/game/src/app/screen';
import {BiomeGameObject} from '@game/biomes/biome.game-object';
import {EntityLayer} from '@game/entities/map';

export class DarknessBiomeGameObject implements BiomeGameObject {
  private lyrForeground: null | PIXI.Graphics;
  private txtDanger: null | PIXI.Text;

  private animText: gsap.core.Timeline;

  constructor() {
    console.log('[Biome:Darkness] creating...');

    // FIXME
    this.lyrForeground = new PIXI.Graphics();
    this.lyrForeground.beginFill(0x000000);
    this.lyrForeground.drawRect(0, 0, screenWidth(), screenHeight());
    this.lyrForeground.zIndex = EntityLayer.CHARACTER + 1;
    this.lyrForeground.alpha = 0.4;

    this.txtDanger = new PIXI.Text('DANGER', {fontSize: '30px', fill: 'red'});
    this.txtDanger.x = screenWidth() / 2;
    this.txtDanger.y = screenHeight() / 2;
    this.txtDanger.anchor.set(0.5, 0.5);
    this.txtDanger.zIndex = EntityLayer.HUD;

    this.animText = gsap.timeline({duration: 0.2, repeat: -1, repeatDelay: 0.2});
    this.animText.fromTo(this.txtDanger.scale, {
      x: 0.9,
      y: 0.9,
    }, {
      x: 1.1,
      y: 1.1,
      ease: Sine.easeOut,
    });
    this.animText.to(this.txtDanger.scale, {
      x: 0.9,
      y: 0.9,
      ease: Power1.easeInOut,
    });
  }

  load(scene: PIXI.Container): void {
    // Nothing to do
  }

  destroyAfter(ms: number = 0): void {
    console.log('[Biome:Darkness] destroying...');

    gsap.to(this.lyrForeground, {
      alpha: 0,
      onComplete: () => {
        if (this.lyrForeground) {
          this.lyrForeground.destroy();
          this.lyrForeground = null;
        }
      },
      duration: ms / 1000,
    });

    gsap.to(this.txtDanger, {
      alpha: 0,
      onComplete: () => {
        this.animText.kill();
        if (this.txtDanger) {
          this.txtDanger.destroy();
          this.txtDanger = null;
        }
      },
      duration: ms / 1000,
    });
  }

  update(delta: any): void {
    if (this.lyrForeground) {
      this.lyrForeground.x = -this.lyrForeground.parent.x - (screenWidth());
      this.lyrForeground.y = -this.lyrForeground.parent.y - (screenHeight());
    }

    if (this.txtDanger) {
      this.txtDanger.x = -this.txtDanger.parent.x + (screenWidth() / 2);
      this.txtDanger.y = -this.txtDanger.parent.y + (screenHeight() / 2);
    }
  }

  attachTo(parent: PIXI.Container): void {
    if (this.lyrForeground) {
      parent.addChild(this.lyrForeground);
    }

    if (this.txtDanger) {
      parent.addChild(this.txtDanger);
    }
  }
}
