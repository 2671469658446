import {Component, HostListener} from '@angular/core';
import {HudService, ItemType} from './hud.service';
import {StarSystemService} from '../../services/star-system.service';
import {DomSanitizer, SafeStyle} from '@angular/platform-browser';
import {ModuleData, ModuleType} from '@game/modules/modules';
import {AppState} from '../app.service';
import {ItemData} from '@game/characters/character';
import {HitPattern, SkillTargeting} from '@game/battles/skills';

const TOOLTIP_FLIP_THRESHOLD_X = 0.7;
const TOOLTIP_FLIP_THRESHOLD_Y = 0.6;
const TOOLTIP_OFFSET_X = 24;
const TOOLTIP_OFFSET_Y = 24;

@Component({
  selector: 'wg-hud-container',
  styleUrls: ['./hud.component.less'],
  templateUrl: './hud.component.html'
})
export class HudComponent {

  ItemType = ItemType;
  AppState = AppState;
  HitPattern = HitPattern;
  SkillTargeting = SkillTargeting;

  mousePos: null | {
    x: number;
    y: number;
  } = null;

  constructor(public hudService: HudService,
              public starSystemService: StarSystemService,
              private sanitizer: DomSanitizer) {
  }

  @HostListener('window:mousemove', ['$event'])
  trackMousePosition(evt: MouseEvent): void {
    this.mousePos = {
      x: evt.clientX,
      y: evt.clientY,
    };
  }

  getTooltipStyle(): undefined | SafeStyle {

    if (!this.mousePos) {
      return;
    }

    const mouseX = this.mousePos.x;
    const mouseY = this.mousePos.y;
    const winWidth = window.innerWidth;
    const winHeight = window.innerHeight;

    let style = '';

    if (mouseY > TOOLTIP_FLIP_THRESHOLD_Y * winHeight) {
      style += `bottom: ${winHeight - mouseY + TOOLTIP_OFFSET_Y}px;`;
    } else {
      style += `top: ${mouseY + TOOLTIP_OFFSET_Y}px;`;
    }

    if (mouseX > TOOLTIP_FLIP_THRESHOLD_X * winWidth) {
      style += `right: ${winWidth - mouseX + TOOLTIP_OFFSET_X}px;`;
    } else {
      style += `left: ${mouseX + TOOLTIP_OFFSET_X}px;`;
    }

    return this.sanitizer.bypassSecurityTrustStyle(style);
  }

  getUpgradeBorderClass(itemData: ItemData): string {
    const mod = itemData as ModuleData;
    if (mod) {
      return `upgrade-${mod.upgrade}`;
    }
    return '';
  }

  getItemName(item: ItemData): string {
    if (!item || !item.name) {
      return '---';
    }
    return item.name.replace('\n', '<br>');
  }

  getItemUpgrade(item: ItemData): number {
    if (!item) {
      return 0;
    }
    const mod = item as ModuleData;
    return mod.upgrade || 0;
  }

  getModuleType(item: ItemData): ModuleType {
    if (!item) {
      return ModuleType.None;
    }
    const mod = item as ModuleData;
    return mod.type || ModuleType.None;
  }
}
