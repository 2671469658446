import {Injectable} from '@angular/core';
import {GalaxyService, PartyInvite, PartyStatus} from './galaxy.service';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';


@Injectable()
export class PartyService {

  incomingInvites: PartyInvite[] = [];
  invitesChanged$: Subject<PartyInvite[]> = new Subject<PartyInvite[]>();

  party$ = new BehaviorSubject<null | PartyStatus>(null);

  constructor(private galaxyService: GalaxyService) {
    console.log('[PartyService] intialising...');

    galaxyService.afterPartyInviteReceived().subscribe((invite) => {
      this.incomingInvites.push(invite);
      this.invitesChanged$.next();
    });

    galaxyService.afterPartyStatusRefreshed().subscribe((status) => {
      this.party$.next(status);
    });

    galaxyService.afterPartyFormed().subscribe((status) => {
      this.party$.next(status);
    });

    galaxyService.afterJoinedParty().subscribe((status) => {
      this.party$.next(status);
    });

    galaxyService.afterCharacterJoined().subscribe((status) => {
      this.party$.next(status);
    });
  }

  getInvites(): PartyInvite[] {
    return this.incomingInvites;
  }

  afterInvitesChanged(): Observable<PartyInvite[]> {
    return this.invitesChanged$.pipe(
      map(_ => this.incomingInvites),
    );
  }

  afterPartyChanged(): Observable<null | PartyStatus> {
    return this.party$.asObservable();
  }

  acceptInvite(invite: PartyInvite): void {
    this.galaxyService.acceptPartyInvite(invite.characterId).then(() => {
      console.log('[Galaxy] Invite accepted');
    }).catch(err => {
      // FIXME Handle error
      console.error('Unable to accept invite');
    });
  }

  denyInvite(invite: PartyInvite): void {
    this.galaxyService.denyPartyInvite(invite.characterId).then(() => {
      console.log('[Galaxy] Invite denied');
    }).catch(err => {
      // FIXME Handle error
      console.error('Unable to deny invite');
    });
  }
}
