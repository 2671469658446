import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {InventoryData} from '@game/characters/character';
import {InventoryService} from '../../../../services/inventory.service';
import {HudService, ItemType} from '../../hud.service';
import {ModuleData} from '@game/modules/modules';
import {DragData, DragSource} from '../../drag';

@Component({
  selector: 'wg-inventory',
  templateUrl: 'inventory.component.html',
  styleUrls: ['inventory.component.less'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InventoryComponent implements OnChanges {

  @Input() inventory: null | InventoryData = null;

  @Output() shiftClicked = new EventEmitter();
  @Output() ctrlClicked = new EventEmitter();
  @Output() altClicked = new EventEmitter();
  @Output() clicked = new EventEmitter();

  inventoryGrid: number[] = [];
  loading: boolean = false;

  preview: null | {
    type: string;
    data: any;
  } = null;

  constructor(private inventoryService: InventoryService,
              private hudService: HudService) {
  }

  ngOnChanges(): void {
    if (this.inventory) {
      this.inventoryGrid = Array.from(Array(this.inventory.size).keys()).reverse();
    }
  }

  onItemDragStart(evt: DragEvent, index: number): void {
    // Must stop here to avoid issues with underlying ship drag systems;
    evt.stopImmediatePropagation();

    this.hudService.handleDragStart({
      source: DragSource.Inventory,
      type: 'item',
      pos: index,
      stack: this.inventory?.items[index],
    });
  }

  onDrop(drag: DragData, index: number): void {

    if (drag.pos === undefined && drag.slot === undefined) {
      console.log('unable to move - missing data', drag);
      return;
    }

    if (drag.source === DragSource.Inventory) {

      if (!drag.pos && drag.pos !== 0) {
        console.log('cannot move items - source position not set');
        return;
      }

      if (drag.pos === index) {
        return;
      }

      this.loading = true;
      this.inventoryService.moveItem(drag.pos, index).then((result) => {
        this.loading = false;
      }).catch((err) => {
        this.loading = false;
        console.error(`MOVE ITEM failed for ${drag.type} (to pos ${index}): `, err);
      });
    } else if (drag.source === DragSource.Team) {

      if (drag.pos === undefined || !drag.slot) {
        console.log('cannot equip module - shipID or slot missing');
        return;
      }

      this.loading = true;
      this.inventoryService.equipModule(drag.pos, drag.slot, index).then((result) => {
        this.loading = false;
      }).catch((err) => {
        this.loading = false;
        console.error(`MOVE ITEM failed for ${drag.type} (to pos ${index}): `, err);
      });
    }
  }

  getRarityClass(pos: number): string {
    if (!this.inventory) {
      return '';
    }

    const entry = this.inventory.items[pos];
    if (!entry) {
      return '';
    }

    if (entry.type !== ItemType.Module) {
      return '';
    }

    const mod = entry.data as ModuleData;
    return `color-${mod.rarity}`;
  }

  isDraggable(pos: number): boolean {
    return !!this.inventory &&
      !!this.inventory.items[pos] &&
      this.inventory.items[pos].locked !== true;
  }

  isLocked(pos: number): boolean {
    return !!this.inventory &&
      !!this.inventory.items[pos] &&
      this.inventory.items[pos].locked === true;
  }

  forwardClick(pos: number, evt: MouseEvent): void {
    if (!this.inventory) {
      return;
    }

    const entry = this.inventory.items[pos];
    if (!entry) {
      return;
    }

    if (!evt.ctrlKey && !evt.altKey && !evt.shiftKey) {
      this.clicked.next(pos);
    } else if (evt.ctrlKey && !evt.altKey && !evt.shiftKey) {
      this.ctrlClicked.next(pos);
    } else if (!evt.ctrlKey && evt.altKey && !evt.shiftKey) {
      this.altClicked.next(pos);
    } else if (!evt.ctrlKey && !evt.altKey && evt.shiftKey) {
      this.shiftClicked.next(pos);
    }
  }
}
