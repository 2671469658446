import * as PIXI from 'pixi.js';
import {getNearestTierTexture, Ship} from '@game/ships/ship';
import {HullSize, ShipGameObject} from '@game/ships/ship.game-object';
import {ExhaustGameObject} from '@game/ships/parts/exhaust.game-object';
import {ShipCreateOptions} from '@game/ships/ship.factory';

export class CruiserCheetahGameObject extends ShipGameObject {

  constructor(ship: Ship, opts: ShipCreateOptions) {
    super(ship, opts);

    this.loadStructure({
      type: 'body',
      config: {
        asset: 'body_02_variant_02',
        x: 0,
        y: -10,
        scale: 0.9,
      },
      children: [{
        type: 'wing',
        config: {
          asset: 'wing_r_02',
          x: 7,
          y: -3,
          scale: 0.8,
        },
      }, {
        type: 'wing',
        config: {
          asset: 'wing_r_02',
          x: -7,
          y: -3,
          flipX: true,
          scale: 0.8,
        }
      }, {
        type: 'drive',
        config: {
          asset: 'drive_03',
          x: 0,
          y: 12,
        },
        children: [{
          type: 'exhaust',
          config: {
            track: this.ship,
            placement: -1,
            x: -7,
            y: 33,
            scale: 2,
          }
        }, {
          type: 'exhaust',
          config: {
            track: this.ship,
            placement: 1,
            x: 10,
            y: 33,
            scale: 2,
          }
        }]
      }]
    });

    // const shipTexture = getNearestTierTexture(ship.getHighestModuleTier(), 'ships', 'cruiser_cheetah_t%.png')
    // if (shipTexture) {
    //   const sprite = new PIXI.Sprite(shipTexture);
    //   sprite.x -= 100;
    //   this.addChild(sprite);
    // }
  }

  getSize(): number {
    return HullSize.Small;
  }
}
