import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges} from '@angular/core';
import {Ship, StatModifier} from '@game/ships/ship';
import {Rarity} from './rarity';
import {SkillData} from '@game/battles/skills';
import {HudService} from '../../hud.service';
import {checkSkillStatsForContent} from '@game/stats/stat-data';

@Component({
  selector: 'wg-ship-stats',
  styleUrls: ['./stats.less'],
  templateUrl: './ship-stats.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShipStatsComponent implements OnChanges {
  @Input() ship: null | Ship = null;

  constructor(private hudService: HudService,
              private cdr: ChangeDetectorRef) {
  }

  get name(): string {
    return this.ship?.typeName || '---';
  }

  get stats(): any {
    return this.ship?.shipStats || '---';
  }

  get rarity(): Rarity {
    return this.ship?.rarity || Rarity.Common;
  }

  get statModifiers(): StatModifier[] {
    if (!this.ship) {
      return [];
    }
    const statMods = this.ship.statModifiers;
    const keys = Object.keys(statMods);
    keys.sort((a: string, b: string) => {
      return a > b ? 1 : -1;
    });

    return keys.map(key => {
      return statMods[key];
    });
  }

  hasDamageReduction(): boolean {
    return Object.keys(this.stats.damageReduction).length > 0;
  }

  hasDamagePierce(): boolean {
    return Object.keys(this.stats.damagePierce).length > 0;
  }

  ngOnChanges(): void {
    if (!this.ship) {
      return;
    }

    this.ship.afterShieldChanged().subscribe(() => {
      this.cdr.detectChanges();
    });
  }

  showStatModifierTooltip(statMod: StatModifier): void {
    this.hudService.showStatModifierTooltip(statMod);
  }

  hideTooltip(): void {
    this.hudService.hideTooltip();
  }

  showSkillTooltip(skill: SkillData): void {
    this.hudService.showSkillTooltip(skill);
  }

  hasVisibleSkillBonuses(): boolean {
    if (this.stats.allSkills) {
      if (checkSkillStatsForContent(this.stats.allSkills)) {
        return true;
      }
    }

    const hasBonus = Object.keys(this.stats.skills).some((ident: string) => {
      return checkSkillStatsForContent(this.stats.skills[ident], true);
    });

    if (hasBonus) {
      return true;
    }

    const hasCategory = Object.keys(this.stats.skillCategories).some((ident: string) => {
      return checkSkillStatsForContent(this.stats.skillCategories[ident]);
    });

    if (hasCategory) {
      return true;
    }

    return false;
  }
}
