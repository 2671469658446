import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ItemStackData} from '@game/characters/character';
import {InventoryService} from '../../../../services/inventory.service';
import {HudService, ItemType} from '../../hud.service';
import {ModuleData, ModuleType} from '@game/modules/modules';
import {Rarity} from '../stats/rarity';
import {DragData, DragSource} from '../../drag';
import {CharacterService} from '../../../../services/character.service';


@Component({
  selector: 'wg-item-slot',
  templateUrl: './item-slot.component.html',
  styleUrls: ['./item-slot.component.less'],
})
export class ItemSlotComponent implements OnInit, OnDestroy {

  @Input() disabled: boolean = false;

  @Input() method: 'link' | 'normal' = 'normal';

  @Input() stack: null | ItemStackData = null;
  @Output() stackChange = new EventEmitter<null | ItemStackData>();

  @Input() pos: null | number = null;
  @Output() posChange = new EventEmitter<null | number>();

  @Output() itemSelected = new EventEmitter<DragData>();
  @Input() fallback: null | ModuleData = null;

  loading: boolean = false;
  draggedOverState: null | boolean = null;

  @Input() allowedDragSources: null | DragSource[] = null;

  @Input() allowedTypes: null | ItemType[] = null;
  @Input() allowedModuleTypes: null | ModuleType[] = null;
  @Input() allowedModules: null | string[] = null;
  @Input() allowedItemLevelMin: null | number = null;
  @Input() allowedItemLevelMax: null | number = null;
  @Input() allowedRarity: null | Rarity[] = null;
  @Input() allowedUpgradeLevelMin: null | number = null;
  @Input() allowedUpgradeLevelMax: null | number = null;

  constructor(private characterService: CharacterService,
              private inventoryService: InventoryService,
              private hudService: HudService,
              private cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.hudService.afterDragChanged().subscribe((data) => {
      this.updateDragState();
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy(): void {
    if (this.method === 'link') {
      this.removeStack();
    }
  }

  isEquipable(): boolean {
    if (!this.stack) {
      return true;
    }

    if (this.stack.type === ItemType.Module) {
      const mod = this.stack.data as ModuleData;
      const maxShipLevel = this.characterService.getCharacter().getTeam().getHighestShipLevel();
      return maxShipLevel >= mod.ilvl;
    }

    return true;
  }

  isAllowed(stack: ItemStackData): boolean {

    if (this.allowedTypes && !this.allowedTypes.includes(stack.type)) {
      return false;
    }

    if (stack.type === ItemType.Module) {
      const mod = stack.data as ModuleData;

      // Allow all subtypes!
      if (this.allowedModuleTypes !== null && !this.allowedModuleTypes.some(t => mod.type.startsWith(t))) {
        return false;
      }

      if (this.allowedModules !== null && !this.allowedModules.includes(mod.identifier)) {
        return false;
      }

      if (this.allowedItemLevelMin !== null && mod.ilvl < this.allowedItemLevelMin) {
        return false;
      }

      if (this.allowedItemLevelMax !== null && mod.ilvl > this.allowedItemLevelMax) {
        return false;
      }

      if (this.allowedUpgradeLevelMin !== null && mod.upgrade < this.allowedUpgradeLevelMin) {
        return false;
      }

      if (this.allowedUpgradeLevelMax !== null && mod.upgrade > this.allowedUpgradeLevelMax) {
        return false;
      }

    }

    return true;
  }

  updateDragState(): void {
    this.draggedOverState = false;
    const drag = this.hudService.getDragData();

    if (drag === null) {
      this.draggedOverState = null;
      return;
    }

    if (drag.pos === undefined) {
      return;
    }

    // Only allow type module for now.
    const stack = drag.stack;

    if (!stack) {
      return;
    }

    // Check if the source is allowed.
    if (this.allowedDragSources) {
      if (!this.allowedDragSources.find(s => s === drag.source)) {
        return;
      }
    }

    if (this.isAllowed(stack)) {
      this.draggedOverState = true;
    }
  }

  onDragOver(evt: DragEvent): void {
    if (this.draggedOverState) {
      evt.preventDefault();
    }
  }

  onDrop(evt: DragEvent): void {
    const drag = this.hudService.getDragData();
    if (!drag) {
      return;
    }

    // Only allow items from the inventory.
    if (drag.type !== 'item') {
      return;
    }

    let stack = drag.stack;
    if (stack === undefined) {
      console.error('missing stack for drop check', drag);
      return;
    }

    // Only allow type module for now.
    if (this.draggedOverState) {
      if (this.method === 'link') {

        this.stack = stack;
        this.stackChange.next(stack);

        if (drag.pos === undefined) {
          console.error('missing inventory position');
          return;
        }

        this.pos = drag.pos;
        this.posChange.next(drag.pos);

        // Lock the slot until this element is removed or component is closed.
        this.inventoryService.lockSlot(drag.pos);
      } else {
        // Send an subevent to equip or swap the modules or etc.
        this.itemSelected.next(drag);
      }
    }

    evt.preventDefault();
  }

  showPreview(): void {
    const shownStack = this.getStackOrFallback();
    if (!shownStack) {
      return;
    }
    this.hudService.showItemTooltip(shownStack.data, shownStack.type, !this.stack);
  }

  onMouseOut(): void {
    this.hudService.hideTooltip();
    this.draggedOverState = null;
  }

  getRarityClass(): string {
    if (this.stack) {
      return `color-${this.stack.data.rarity}`;
    } else if (this.fallback) {
      return `color-${this.fallback.rarity}`;
    }

    return '';
  }

  onClick(): void {
    if (this.method === 'link') {
      this.removeStack();
    }
  }

  private removeStack(): void {
    if (this.method !== 'link') {
      return;
    }

    if (this.stack) {
      this.inventoryService.unlockSlot(this.pos);
      this.stackChange.next(null);
      this.posChange.next(null);
    }
  }

  getStackOrFallback(): null | ItemStackData {
    if (this.stack) {
      return this.stack;
    } else if (this.fallback) {
      return {
        type: ItemType.Module,
        data: this.fallback,
        num: 1,
      };
    }

    return null;
  }
}
