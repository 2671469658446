import {ItemData, ItemStackData} from '@game/characters/character';
import {StatData, StatGenerator} from '@game/stats/stat-data';

export enum ModuleType {
  None = '',

  WeaponTypeKineticGun = 'weapon_kinetic_gun',
  WeaponTypeKineticCannon = 'weapon_kinetic_cannon',
  WeaponTypeEnergyLaser = 'weapon_energy_laser',
  WeaponTypeEnergyPlasma = 'weapon_energy_plasma',
  WeaponTypeExplosiveRocket = 'weapon_explosive_rocket',
  WeaponTypeExplosiveBomb = 'weapon_explosive_bomb',

  DefensiveTypeShield = 'defensive_shield',
  AccessoryShieldGenerator = 'accessory_shield_generator',
  AccessoryShieldRegen = 'accessory_shield_regen'
}

export interface AffixData {
  name: string;
  type: 'prefix' | 'suffix' | 'legendary';
  ilvl: number;
  qlvl: number;
  shipStats: StatData;
  shipStatsSource: StatGenerator;
  teamStats: StatData;
  teamStatsSource: StatGenerator;
}

export interface ModuleData extends ItemData {
  moduleId: string;
  upgrade: number;
  upgradeMod: number;
  combinedShipStats: StatData;
  combinedTeamStats: StatData;

  baseName: string;
  shipStats: StatData;
  shipStatsSource: StatGenerator;
  teamStats: StatData;
  teamStatsSource: StatGenerator;
  gearScore: number;
  ilvl: number;
  qlvl: number;
  affixes: AffixData[];
  type: ModuleType;
  tier: number;
}

export interface ModuleStackData extends ItemStackData {
  data: ModuleData;
}
