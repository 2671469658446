import {StarSystemService} from '../../../services/star-system.service';
import {NebulaBiomeGameObject} from '@game/biomes/nebula-biome.game-object';
import * as PIXI from 'pixi.js';
import {Scene} from '../../app.component';

export class MainmenuScene extends PIXI.Container implements Scene {

  private biome: NebulaBiomeGameObject;
  private viewport: PIXI.Container;

  /**
   * Initialize this scene by providing the central game service a
   * and activation this scene immediately.
   */
  constructor(private gameService: StarSystemService) {
    super();

    this.viewport = new PIXI.Container();
    this.viewport.sortableChildren = true;
    this.viewport.zIndex = 0;

    // add the viewport to the stage
    this.addChild(this.viewport);

    this.biome = new NebulaBiomeGameObject();
    this.biome.attachTo(this.viewport);
  }

  destroy(options?: { children?: boolean; texture?: boolean; baseTexture?: boolean }): void {
    this.biome.destroyAfter(0);

    super.destroy(options);
  }

  update(delta: number): void {
    if (this.biome) {
      this.biome.update(delta);
    }
  }
}
