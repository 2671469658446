import {BiomeGameObject} from '@game/biomes/biome.game-object';
import {NebulaBiomeGameObject} from '@game/biomes/nebula-biome.game-object';
import {DarknessBiomeGameObject} from '@game/biomes/darkness-biome.game-object';

export abstract class BiomeFactory {

  public static RequiredAssets = [
    {
      name: 'biomes/nebula',
      src: 'biomes/nebula/zoltan-tasi-I4NmCkxUCkk-unsplash.png',
    }
  ];

  static create(biome: string): null | BiomeGameObject {
    switch (biome) {
      case 'darkness':
        return new DarknessBiomeGameObject();
      case 'nebula':
        return new NebulaBiomeGameObject();
      default:
        console.warn('undefined nebula type: ' + biome);
    }

    return null;
  }
}
