import {ChangeDetectionStrategy, Component} from '@angular/core';
import {HudService, ItemType} from '../hud.service';
import {Rarity} from '../shared/stats/rarity';
import {CombinationCatalystData, ItemStackData} from '@game/characters/character';
import {ModuleData, ModuleType} from '@game/modules/modules';
import {StarSystemService} from '../../../services/star-system.service';

@Component({
  selector: 'wg-menu-combination',
  templateUrl: './combination-menu.component.html',
  styleUrls: ['./combination-menu.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CombinationMenuComponent {

  // eslint-disable-next-line @typescript-eslint/typedef,@typescript-eslint/naming-convention
  ItemType = ItemType;

  catalystStack: null | ItemStackData = null;
  catalystPos: null | number = null;

  sourceStack: null | ItemStackData = null;
  sourcePos: null | number = null;

  offerStack: null | ItemStackData = null;
  offerPos: null | number = null;

  loading: boolean = false;

  get catalyst(): null | CombinationCatalystData {
    return this.catalystStack?.data as CombinationCatalystData;
  }

  get source(): ModuleData {
    return this.sourceStack?.data as ModuleData;
  }

  get offer(): ModuleData {
    return this.offerStack?.data as ModuleData;
  }

  get allowedSourceRarity(): null | Rarity[] {
    return this.catalyst?.moduleRarity || null;
  }

  get allowedSourceModuleTypes(): null | ModuleType[] {
    return this.catalyst?.moduleTypes || null;
  }

  get allowedSourceItemLevelMin(): null | number {
    return this.catalyst?.ilvlMin || null;
  }

  get allowedSourceItemLevelMax(): null | number {
    return this.catalyst?.ilvlMax || null;
  }

  get allowedSourceUpgradeLevelMin(): null | number {
    return this.catalyst?.upgradeMin || null;
  }

  get allowedSourceUpgradeLevelMax(): null | number {
    return this.catalyst?.upgradeMax || null;
  }

  get allowedOfferRarity(): null | Rarity[] {
    if (!this.catalyst) {
      return null;
    }

    if (!this.source) {
      return null;
    }

    switch (this.catalyst.offerModuleRarityReq) {
      case 'same':
        return [this.source.rarity];
    }

    return null;
  }

  get allowedOfferModuleTypes(): null | ModuleType[] {
    if (!this.catalyst) {
      return null;
    }

    if (!this.source) {
      return null;
    }

    switch (this.catalyst.offerModuleReq) {
      case 'sameType':
      case 'sameModule':
        return [this.source.type];
    }

    return null;
  }

  get allowedOfferItemLevelMin(): null | number {
    if (!this.catalyst) {
      return null;
    }

    if (!this.source) {
      return null;
    }

    switch (this.catalyst.offerModuleLevelReq) {
      case 'sameOrBetter':
        return this.source.ilvl;
    }

    return null;
  }

  get allowedOfferItemLevelMax(): null | number {
    if (!this.catalyst) {
      return null;
    }

    if (!this.source) {
      return null;
    }

    switch (this.catalyst.offerModuleLevelReq) {
    }

    return null;
  }

  get allowedOfferUpgradeLevelMin(): null | number {
    if (!this.catalyst) {
      return null;
    }

    if (!this.source) {
      return null;
    }

    switch (this.catalyst.offerModuleUpgradeReq) {
      case 'sameOrBetter':
        return this.source?.upgrade || null;
    }

    return null;
  }

  get allowedOfferUpgradeLevelMax(): null | number {
    // There is currently no case where we need it.
    return null;
  }

  constructor(private starSystemService: StarSystemService,
              private hudService: HudService) {
  }

  combine(): void {
    this.loading = true;

    this.starSystemService.rpc({
      method: 'CombineModules',
      params: {
        catalystPos: this.catalystPos,
        sourceId: this.source.moduleId,
        offerIds: [this.offer.moduleId],
      },
    }).then((resp) => {
      // TODO Show a window with the result?
      // TODO Update the underlying stacks.
      console.log('[Combination] Successful', resp);
      this.offerStack = null;

    }).catch((err) => {
      console.error('combination failed:', err);
    }).finally(() => {
      this.loading = false;
    });
  }
}
