import * as PIXI from 'pixi.js';
import {EntityGameObject} from '@game/entities/entity.game-object';
import {Bounce, gsap} from 'gsap';
import {EntityConfig} from '@game/entities/entity';
import {EntityOptions} from '@game/entities/entity.factory';

export class PlanetEntityGameObject extends EntityGameObject {
  private blurAnim: gsap.core.Tween;

  set angle(x: number) {
    this.child.angle = x;
  }

  get angle(): number {
    return this.child.angle;
  }

  private child: PIXI.Sprite;
  private background: PIXI.Sprite;
  private blurFilter: PIXI.Filter;

  constructor(entity: EntityConfig, options: EntityOptions) {
    super(entity, options);

    console.log('[PlanetEntity] load entity ', entity.asset);

    const segments = entity.asset.split('/');
    let asset = segments[segments.length - 1];

    const res = PIXI.Loader.shared.resources[asset] || PIXI.Loader.shared.resources[entity.asset];
    if (res) {
      this.child = new PIXI.Sprite(res.texture);
    } else {
      this.child = new PIXI.Sprite();
    }

    this.child.scale.set(3);
    this.child.anchor.set(0.5);

    if (res) {
      this.background = new PIXI.Sprite(res.texture);
    } else {
      this.background = new PIXI.Sprite();
    }
    this.background.anchor.set(0.5, 0.5);

    this.blurFilter = new PIXI.filters.BlurFilter();
    this.background.filters = [this.blurFilter];
    this.background.scale.set(this.child.scale.x * 1.03);

    this.addChild(this.background);
    this.addChild(this.child);

    this.blurAnim = gsap.fromTo(this.blurFilter, {blur: 3}, {
      blur: 20,
      duration: 5,
      ease: Bounce.easeOut,
      repeat: -1,
      yoyo: true
    });
    this.scale.set(1.5, 1.5);
  }

  destroy(options?: { children?: boolean; texture?: boolean; baseTexture?: boolean }): void {
    this.blurAnim.kill();
    super.destroy(options);
  }

}
