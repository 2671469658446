import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import {EntityConfig} from '@game/entities/entity';
import {Tick, TickPositionData} from './tick';
import {distinctUntilChanged, filter, skip} from 'rxjs/operators';
import {BattleEndedEvent} from '@game/battles/events';
import {gsap, Linear} from 'gsap';
import {MapState, SessionConnectedEvent, StarSystemService} from './star-system.service';
import {EffectManager} from '@game/vfx/effect.manager';
import {Environment} from '@game/environments/environment';

export interface EntityRemovedData {
  index: number;
  destroyed: boolean;
}

@Injectable()
export class MapService {

  // Map
  private biome$ = new BehaviorSubject<string>('');
  private environment$ = new BehaviorSubject<null | Environment>(null);
  private dangerLevel$ = new BehaviorSubject<number>(-1);
  private identifier$ = new BehaviorSubject<string>('');
  private name$ = new BehaviorSubject<string>('');
  private width$ = new BehaviorSubject<number>(-1);
  private height$ = new BehaviorSubject<number>(-1);

  lockedOn: null | EntityConfig = null;

  private entityMap: Map<number, EntityConfig> = new Map<number, EntityConfig>();
  private entityAdded$ = new Subject<EntityConfig>();
  private entityRemoved$ = new Subject<EntityRemovedData>();
  private entityMoved$ = new Subject<void>();

  private tickSub: Subscription;

  // Marks whether the new position update must be immediately ingested.
  private patchNow: any;

  private staggeredPositions: TickPositionData[][] = [];

  private serverTickRate: number = 1;
  private serverTickDuration: number = 1000;

  private selectedEntity$: BehaviorSubject<null | EntityConfig>;

  constructor(private starSystem: StarSystemService) {

    // Handle tick updates of other entities
    this.tickSub = this.starSystem.sub('tick').subscribe(this.onTick.bind(this));

    this.selectedEntity$ = new BehaviorSubject<null | EntityConfig>(null);

    this.starSystem.sub('battle.left').subscribe(this.handleBattleEnded.bind(this));

    // Map state
    this.starSystem.sub('biome.changed').subscribe((biome) => this.biome$.next(biome));
    this.starSystem.sub('environment.changed').subscribe((env) => this.environment$.next(env.environment));
    this.starSystem.sub('danger-level.changed').subscribe((dl) => this.dangerLevel$.next(dl));
    this.starSystem.afterConnectionClosed().subscribe(() => this.purge());
    this.starSystem.afterDeath().subscribe(() => this.purge());

    this.starSystem.afterSessionConnected().subscribe((evt: SessionConnectedEvent) => {

      // Transfer the server controlled tick rate to match the entity movement animations.
      this.serverTickRate = evt.server.tickRate;
      this.serverTickDuration = 1000 / this.serverTickRate;

      // Load initial map, biome params and entities
      this.loadMapState(evt.map);
    });
  }

  afterEntityMoved(): Observable<void> {
    return this.entityMoved$;
  }

  getEntities(): Map<number, EntityConfig> {
    return this.entityMap;
  }

  afterBiomeChanged(): Observable<string> {
    return this.biome$.pipe(
      skip(1),
      filter(b => b !== ''),
      distinctUntilChanged(),
    );
  }

  afterEnvironmentChanged(): Observable<Environment> {
    return this.environment$.pipe(
      skip(1),
      // @ts-ignore
      filter(b => b !== null),
      distinctUntilChanged(),
    );
  }

  afterEntityAdded(): Observable<EntityConfig> {
    return this.entityAdded$;
  }

  afterEntityRemoved(): Observable<EntityRemovedData> {
    return this.entityRemoved$;
  }

  afterDangerLevelChanged(): Observable<number> {
    return this.dangerLevel$.pipe(
      skip(1),
      filter(b => b !== -1),
      distinctUntilChanged(),
    );
  }

  getIdentifier(): string {
    return this.identifier$.getValue();
  }

  getName(): string {
    return this.name$.getValue();
  }

  getMapWidth(): number {
    return this.width$.getValue();
  }

  getMapHeight(): number {
    return this.height$.getValue();
  }

  getBiome(): string {
    return this.biome$.getValue();
  }

  getEnvironment(): null | Environment {
    return this.environment$.getValue();
  }

  /**
   * Clear all leftover data inside the system.
   */
  private purge(): void {
    this.entityMap.clear();
  }

  private handleBattleEnded(evt: BattleEndedEvent): void {
    this.patchNow = true;
  }

  private onTick(tick: Tick): void {

    if (!tick) {
      return;
    }

    if (tick.hide) {
      for (const entry of tick.hide) {

        setTimeout(() => {
          const entity = this.entityMap.get(entry.index);
          if (!entity) {
            console.error('unable to hide entity - entity not found',entry.index);
            return;
          }

          if (entity?.anim) {
            console.log('entity animation killed', entry.index);
            entity.anim.kill()
          }

          if (entry.reason === '') {
            this.onEntityRemoved(entry.index, false);
          } else {
            this.onEntityRemoved(entry.index, true);
          }

          // Remove the reference to this entity from the staggeredPosition updates.
          Object.keys(this.staggeredPositions).forEach((key) => {
            // console.log('clear ', entry.index, ' from staggered positions');
            delete (this.staggeredPositions[+key][entry.index]);
          });

          console.log('[Tick] entity removed', entry.index, this.entityMap);
        }, this.serverTickDuration * 2);
      }
    }

    if (tick.show) {
      for (const entry of tick.show) {
          setTimeout(() => {
          const newEntity: EntityConfig = {
            entityId: entry.index,
            name: entry.name,
            asset: entry.asset,
            tier: entry.tier,
            class: entry.class,
            position: entry.position,
            faction: entry.faction,
            layer: entry.layer,
            rarity: entry.rarity,
            teamLevel: entry.teamLevel,
            isCommander: entry.isCommander,
            characterRef: entry.characterRef,
            isQuestGiver: entry.isQuestGiver,
            questReceiverState: entry.questReceiverState,
            isLoreTeller: entry.isLoreTeller,
            isItemTrader: entry.isItemTrader,
            isHullTrader: entry.isHullTrader,
            isBridgePillar: entry.isBridgePillar,
            isHangarMechanic: entry.isHangarMechanic,
            isDepotManager: entry.isDepotManager,
            state: entry.state,
          };

            this.entityMap.set(entry.index, newEntity);
            this.entityAdded$.next(newEntity);

            console.log('[Tick] entity added', entry.index, this.entityMap);
          }, this.patchNow ? 0 : this.serverTickDuration * 2);
      }
    }

    if (tick.positions) {

      const positions = tick.positions;

      if (this.patchNow) {

        for (const entry of positions) {
          const entity = this.entityMap.get(entry.index);
          if (!entity) {
            console.error('[Tick] unable to update position: entity not found: ', entry.index);
            continue;
          }

          entity.position = entry.position;
        }
      } else {

        // Store a reference to these positions to ensure, that removed entities are removed and
        // will not cause any issues.
        const storedIndex = this.staggeredPositions.length;
        this.staggeredPositions[storedIndex] = positions;

        setTimeout(() => {

          // Clear the stored reference, as it is not needed anymore.
          delete (this.staggeredPositions[storedIndex]);

          for (const entry of positions) {

            // Skip deleted entries.
            if (!entry) {
              continue;
            }

            const entity = this.entityMap.get(entry.index);
            if (!entity) {
              console.log('[Tick] skipping update position: entity not found: ', entry.index);
              continue;
            }

            if (entity.anim) {
              entity.anim.kill();
            }

            // Normalize the angle to be safe and avoid eradicate rotations.
            entity.position.angle = entity.position.angle % 360;

            // Calculate the correct angle direction.
            const currentAngle = entity.position.angle;
            const targetAngle = entry.position.angle % 360;
            let diff = targetAngle - currentAngle;
            if (diff < -180) {
              diff += 360
            }

            let rot = Math.min(Math.abs(diff), 360 - Math.abs(diff));
            if (diff < 0) {
              // Move left by reducing the current angle.
              rot *= -1;
            }

            entity.anim = gsap.to(entity.position, {
              x: entry.position.x,
              y: entry.position.y,
              angle: currentAngle + rot,
              duration: this.serverTickDuration / 1000,
              ease: Linear.easeNone, // Power0.easeInOut,
            });

            entity.anim?.play();
          }

          this.entityMoved$.next();

        }, this.serverTickDuration * 2);
      }
    }

    if (tick.traits) {
      for (const entry of tick.traits) {
        const entity = this.entityMap.get(entry.index);
        if (!entity) {
          console.error('[Tick] unable to change traits: entity not found: ', entry.index);
          continue;
        }

        entity.isQuestGiver = entry.isQuestGiver;
        entity.questReceiverState = entry.questReceiverState;
        entity.isLoreTeller = entry.isLoreTeller;
        entity.isItemTrader = entry.isItemTrader;
        entity.isHullTrader = entry.isHullTrader;
        entity.isBridgePillar = entry.isBridgePillar;
        entity.isHangarMechanic = entry.isHangarMechanic;
        entity.isDepotManager = entry.isDepotManager;
      }
    }

    if (tick.states) {
      for (const entry of tick.states) {
        setTimeout(() => {
          const entity = this.entityMap.get(entry.index);
          if (!entity) {
            console.error('[Tick] unable to change state - entity not found: ', entry.index);
            return;
          }
          entity.state = entry.state;
        }, this.patchNow ? 0 : this.serverTickDuration * 2); // <-- only half the staggered diff
      }
    }

    this.patchNow = false;
  }

  private loadMapState(cfg: MapState): void {
    this.identifier$.next(cfg.identifier);
    this.name$.next(cfg.name);
    this.width$.next(cfg.width);
    this.height$.next(cfg.height);
    this.biome$.next(cfg.biome);
    this.dangerLevel$.next(cfg.dangerLevel);
    this.environment$.next(cfg.environment);
    this.patchNow = true;
    this.onTick(cfg.entities);
  }

  setSelectedEntity(entity: EntityConfig): void {
    console.log('[Map] Entity selected', entity.entityId);
    this.selectedEntity$.next(entity);
  }

  removeEntitySelection(): void {
    console.log('[Map] Entity selection removed');
    this.selectedEntity$.next(null);
  }

  afterSelectedEntityChanged(): Observable<null | EntityConfig> {
    return this.selectedEntity$.pipe(
      distinctUntilChanged(),
    );
  }

  getSelectedEntity(): null | EntityConfig {
    return this.selectedEntity$.getValue()
  }

  private onEntityRemoved(index: number, destroyed: boolean): void {
    const selEntity = this.getSelectedEntity();
    if (selEntity && selEntity.entityId === index) {
      this.removeEntitySelection();
    }

    this.entityMap.delete(index);
    this.entityRemoved$.next({
      index: index,
      destroyed: destroyed,
    });
  }
}
