import {Injectable, OnDestroy} from '@angular/core';
import {QuestData, QuestTrackerData} from '@game/characters/character';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {Location} from '@shared/utils/location';
import {
  QuestAbortedEvent,
  QuestAcceptedEvent,
  QuestCompletedEvent,
  QuestsChangedEvent,
  StarSystemService
} from './star-system.service';

@Injectable({
  providedIn: 'root',
})
export class QuestService implements OnDestroy {

  quests$: BehaviorSubject<QuestTrackerData | null>;
  highlighted$: BehaviorSubject<{ questId: string; objId: string } | null>;

  private questAccepted$ = new Subject<QuestAcceptedEvent>();
  private questAborted$ = new Subject<QuestAbortedEvent>();
  private questCompleted$ = new Subject<QuestCompletedEvent>();

  constructor(private starSystem: StarSystemService) {
    this.quests$ = new BehaviorSubject<QuestTrackerData | null>(null);
    this.highlighted$ = new BehaviorSubject<{ questId: string; objId: string } | null>(null);

    console.log('[Quests] Service started');

    this.starSystem.afterSessionConnected().subscribe(evt => {
      this.replaceQuestData(evt.character.quests);
    });

    this.starSystem.afterQuestAccepted().subscribe(this.handleQuestAccepted.bind(this))
    this.starSystem.afterQuestAborted().subscribe(this.handleQuestAborted.bind(this))
    this.starSystem.afterQuestCompleted().subscribe(this.handleQuestCompleted.bind(this))
    this.starSystem.afterQuestsChanged().subscribe(this.handleQuestsChanged.bind(this));

  }

  ngOnDestroy(): void {
    this.quests$.complete();
    this.questAborted$.complete();
    this.questAccepted$.complete();
    this.questCompleted$.complete();
  }

  handleQuestAccepted(evt: QuestAcceptedEvent): void {
    let quests = this.quests$.getValue();
    if (!quests) {
      quests = {};
    }

    quests[evt.questId] = {
      name: evt.name,
      marker: evt.marker,
      objectives: evt.objectives,
    };

    this.quests$.next(quests);
    this.questAccepted$.next(evt);
  }

  handleQuestAborted(evt: QuestAbortedEvent): void {
    const quests = this.quests$.getValue();
    if (!quests) {
      throw new Error('unable to handle quest.aborted as no quests are present');
    }
    delete (quests[evt.questId]);
    this.quests$.next(quests);
    this.questAborted$.next(evt);
  }

  handleQuestCompleted(evt: QuestCompletedEvent): void {
    const quests = this.quests$.getValue();
    if (!quests) {
      throw new Error('unable to handle quest.completed as no quests are present');
    }
    delete (quests[evt.questId]);
    this.quests$.next(quests);
    this.questCompleted$.next(evt);
  }

  handleQuestsChanged(evt: QuestsChangedEvent): void {
    this.replaceQuestData(evt.quests);
  }

  replaceQuestData(data: QuestTrackerData): void {
    this.quests$.next(data);
  }

  watchQuests(): Observable<QuestTrackerData> {
    return this.quests$.pipe(
      filter(quests => !!quests),
    ) as Observable<QuestTrackerData>;
  }

  watchHighlightedMarker(): Observable<{ questId: string; objId: string } | null> {
    return this.highlighted$;
  }

  watchMarkers(): Observable<{ [questId: string]: { [objId: number]: Location } }> {
    return this.quests$.pipe(
      filter(quests => !!quests),
      map(this.convertToMarker),
    );
  }

  getMarkers(): { [questId: string]: { [objId: number]: Location } } {
    return this.convertToMarker(this.quests$.getValue());
  }

  afterQuestAccepted(): Observable<QuestAcceptedEvent> {
    return this.questAccepted$.asObservable();
  }

  afterQuestAborted(): Observable<QuestAbortedEvent> {
    return this.questAborted$.asObservable();
  }

  afterQuestCompleted(): Observable<QuestCompletedEvent> {
    return this.questCompleted$.asObservable();
  }

  private convertToMarker(quests: null | { [key: string]: QuestData }): {} {
    const m: { [key: string]: any } = {};

    if (!quests) {
      return m;
    }

    Object.keys(quests).forEach(questId => {

      const q = quests[questId];

      if (q.objectives) {
        q.objectives.forEach((obj: any, i: number) => {
          if (!obj.marker) {
            return;
          }

          if (obj.collected >= obj.required) {
            return;
          }

          console.log(obj, obj.collected > obj.required);

          // FIXME Remove all markers that are not on this map!
          // if (obj.marker.mapId !==)

          if (!m[questId]) {
            m[questId] = {};
          }
          m[questId][i] = obj.marker;
        });
      }

      if (q.marker) {
        if (!m[questId]) {
          m[questId] = {};
        }
        m[questId][-1] = q.marker;
      }

    });

    return m;
  }

}
