import {Component, OnInit} from '@angular/core';
import {StarSystemService} from '../../../services/star-system.service';
import {Battle} from '@game/battles/battle';
import {HudService} from '../hud.service';
import {BattleSkillData} from '@game/battles/events';
import {BattleService} from '../../../services/battle.service';

@Component({
  selector: 'wg-battle-skills-hud',
  styleUrls: ['./battle-skills-hud.component.less'],
  templateUrl: './battle-skills-hud.component.html'
})
export class BattleSkillsHudComponent implements OnInit {

  skills: null | BattleSkillData[] = null;
  availableAP: number = 0;

  constructor(private starSystemService: StarSystemService,
              private battleService: BattleService,
              private hudService: HudService) {
  }

  ngOnInit(): void {
    this.battle.afterSkillUsed().subscribe(() => {
      this.skills = null;
      this.hidePreview();
    });

    this.battle.afterGroupEnded().subscribe(() => {
      this.skills = null;
      this.hidePreview();
    });

    this.battle.characterShip$.subscribe(ship => {
      if (ship) {
        this.availableAP = ship.ap;
      } else {
        this.availableAP = 0;
      }
    });

    this.battle.availableSkill$.subscribe(skills => {
      this.skills = skills;
    });
  }

  get battle(): Battle {
    const b = this.battleService.getBattle();

    if (!b) {
      throw new Error('battle not started');
    }

    return b;
  }

  showTooltip(skill: BattleSkillData): void {
    this.hudService.showSkillTooltip(skill);
  }

  hidePreview(): void {
    this.hudService.hideTooltip();
  }

  isSelected(skill: BattleSkillData): boolean {
    const s = this.battle.selectedSkill$.getValue();
    if (!s) {
      return false;
    }

    return skill.identifier === s.identifier;
  }

  selectSkill(skill: BattleSkillData): void {
    this.battle.selectedSkill$.next(skill);
  }

  skip(): void {
    const s = this.battle.characterShip$.getValue();
    if (!s) {
      console.error('cannot skip: no active ship');
      return;
    }
    this.battle.execute('_SKIP', s.shipId);
  }

  defend(): void {
    const s = this.battle.characterShip$.getValue();
    if (!s) {
      console.error('cannot defend: no active ship');
      return;
    }
    this.battle.execute('_DEFEND', s.shipId);
  }
}
