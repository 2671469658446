import {Component} from '@angular/core';

@Component({
  selector: 'wg-hud-menu',
  templateUrl: './menu-hud.component.html',
  styleUrls: ['./menu-hud.component.less'],
})
export class MenuHudComponent {

}
