import * as PIXI from 'pixi.js';
import {Scene} from '../../app.component';
import {AppService, AppState} from '../../app.service';
import {EffectManager} from '@game/vfx/effect.manager';
import {ShipFactory} from '@game/ships/ship.factory';
import {EntityFactory} from '@game/entities/entity.factory';
import {BiomeFactory} from '@game/biomes/biome.factory';
import {Mood, SoundManager} from '@game/sound/sound.manager';
import {EnvironmentFactory} from '@game/environments/environment.factory';
import {LogoScene} from '../logo/logo.scene';


export class PreloadScene extends PIXI.Container implements Scene {

  constructor(private appService: AppService) {
    super();

    console.log('[Scene:Preload] created');

    const txt = new PIXI.Text('loading assets', {fontSize: '30px', fill: 'white'});
    txt.position.x = 10;
    txt.position.y = 10;
    txt.anchor.set(0, 0)
    this.addChild(txt);

    const subtxt = new PIXI.Text('', {fontSize: '30px', fill: 'white'});
    subtxt.position.x = 10;
    subtxt.position.y = 100;
    subtxt.anchor.set(0, 0)
    this.addChild(subtxt);

    const sub = PIXI.Loader.shared.onProgress.add(() => {
      subtxt.text = `${PIXI.Loader.shared.progress}%`;
    });

    PIXI.Loader.shared.onComplete.once(() => {
      PIXI.Loader.shared.onProgress.detach(sub);
    });

    this.preloadAssets();
  }

  update(delta: number): void {
  }

  private preloadAssets(): void {
    const loader = PIXI.Loader.shared;
    loader.baseUrl = 'assets/';

    LogoScene.RequiredAssets.forEach(e => {
      loader.add(e.name, e.src);
    });

    BiomeFactory.RequiredAssets.forEach(e => {
      loader.add(e.name, e.src);
    });

    EnvironmentFactory.RequiredAssets.forEach(e => {
      loader.add(e.name, e.src);
    });

    EntityFactory.RequiredAssets.forEach(e => {
      loader.add(e.name, e.src);
    });

    ShipFactory.RequiredAssets.forEach(e => {
      loader.add(e.name, e.src);
    });

    SoundManager.RequiredAssets.forEach(e => {
      loader.add(e.name, e.src);
    });

    EffectManager.RequiredAssets.forEach(e => {
      loader.add(e.name, e.src);
    });

    loader.load(() => {

      SoundManager.instance.setMood(Mood.Calm);

      let next = AppState.Logo;

      if (!!localStorage.getItem('SKIP_INTRO')) {
        next = AppState.MainMenu;
      }

      if (!!localStorage.getItem('WG_DEBUG')) {
        next = AppState.Debug;
      }

      this.appService.changeState(next);
    });
  }
}
