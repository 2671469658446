import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {StarSystemService} from '../../../services/star-system.service';
import {Character} from '@game/characters/character';
import {CharacterService} from '../../../services/character.service';
import {EntityConfig} from '@game/entities/entity';
import {QuestService} from '../../../services/quest.service';
import {Vector2da} from '@shared/utils/vector2da';
import {Location} from '@shared/utils/location';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {MapService} from '../../../services/map.service';
import {HudService} from '../hud.service';

const MINIMAP_RESOLUTION = 1200;

@Component({
  selector: 'wg-hud-minimap',
  templateUrl: './minimap.component.html',
  styleUrls: ['./minimap.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MinimapComponent implements OnInit, OnDestroy {

  markers: { [p: string]: { [p: number]: Location } } = {};

  private char: Character | null = null;
  private deleted$ = new Subject();
  private selectedEntityId: number = -1;

  constructor(private starSystemService: StarSystemService,
              private mapService: MapService,
              private characterService: CharacterService,
              private questService: QuestService,
              private cdr: ChangeDetectorRef) {
    // Empty
  }

  get entities(): IterableIterator<EntityConfig> {
    return this.mapService.getEntities().values();
  }

  get position(): { x: any; y: any } {

    if (!this.char) {
      throw new Error('char not initialised');
    }

    const pos = this.char.getPosition();

    const width = this.mapService.getMapWidth();
    const height = this.mapService.getMapHeight();

    if (pos.x < 0 || pos.x > width || pos.y < 0 || pos.y > height) {
      return {
        x: '???',
        y: '???'
      };
    }

    pos.x = Math.round(pos.x);
    pos.y = Math.round(pos.y);

    return pos;
  }

  ngOnInit(): void {
    this.char = this.characterService.getCharacter();

    // Detach from cdr to reduce load and watch all incomming events.
    this.cdr.detach();

    this.mapService.afterEntityAdded().pipe(
      takeUntil(this.deleted$),
    ).subscribe(() => {
      this.cdr.detectChanges();
    });

    this.mapService.afterEntityMoved().pipe(
      takeUntil(this.deleted$),
    ).subscribe(() => {
      this.cdr.detectChanges();
    });

    this.mapService.afterEntityRemoved().pipe(
      takeUntil(this.deleted$),
    ).subscribe(() => {
      this.cdr.detectChanges();
    });

    this.mapService.afterSelectedEntityChanged().pipe(
      takeUntil(this.deleted$),
    ).subscribe((e) => {
      this.selectedEntityId = e?.entityId || -1;
      this.cdr.detectChanges();
    });

    this.char.afterPositionChanged().pipe(
      takeUntil(this.deleted$),
    ).subscribe(() => {
      this.cdr.detectChanges();
    });

    this.questService.watchMarkers().pipe(
      takeUntil(this.deleted$),
    ).subscribe(markers => {
      this.markers = markers;
    });
  }

  ngOnDestroy(): void {
    this.deleted$.next();
    this.deleted$.complete();
  }

  getMinimapPosition(entity: EntityConfig): { [key: string]: any } | undefined {

    if (!this.char) {
      console.warn('character is undefined');
      return;
    }

    if (!entity || !entity.position || !this.char || !this.char.getPosition()) {
      return;
    }

    const diffX = entity.position.x - this.char.getPosition().x;
    const diffY = entity.position.y - this.char.getPosition().y;

    const percTop = Math.max(-1, Math.min(1, diffY / MINIMAP_RESOLUTION));
    const percLeft = Math.max(-1, Math.min(1, diffX / MINIMAP_RESOLUTION));

    const posLeft = 50 + 50 * percLeft;
    const posTop = 50 + 50 * percTop;

    const res = 200; // TODO: What does this do?

    const style: any = {
      top: (res * posTop / 100).toFixed(1) + 'px',
      left: (res * posLeft / 100).toFixed(1) + 'px',
    };

    if (entity.entityId === this.selectedEntityId) {
      style.border = '1px solid white';
      style.zIndex = 10;
    }

    return style;
  }

  getEntityStyle(entity: EntityConfig): string {
    if (!entity.faction) {
      return '';
    }

    if (!this.char) {
      throw new Error('character not initialised');
    }

    if (this.char.getFaction() !== entity.faction) {
      return 'hostile';
    }

    return 'friendly';
  }
}
