import * as PIXI from 'pixi.js';
import {Scene} from '../../app.component';
import {AppService, AppState} from '../../app.service';
import {screenHeight, screenWidth} from '../../screen';


export class LogoScene extends PIXI.Container implements Scene {

  static RequiredAssets = [
    {
      name: 'logo/kugelfisch',
      src: 'logo/kugelfisch-logo-draft.png',
    }
  ];

  private timeout: any = null;

  constructor(private appService: AppService) {
    super();

    console.log('[Scene:Logo] created');

    const res = PIXI.Loader.shared.resources;

    const txt = new PIXI.Sprite(res['logo/kugelfisch'].texture);
    txt.position.x = screenWidth() / 2;
    txt.position.y = screenHeight() / 2;
    txt.anchor.set(0.5, 0.5);
    this.addChild(txt);

    window.addEventListener('keypress', this.handleKeys);
    window.addEventListener('click', this.handleMouse);

    this.timeout = setTimeout(() => {
      this.exit();
    }, 3000);
  }

  destroy(options?: { children?: boolean; texture?: boolean; baseTexture?: boolean }): void {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }

    window.removeEventListener('keypress', this.handleKeys);
    window.removeEventListener('click', this.handleMouse);

    super.destroy(options);
  }

  exit(): void {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
    // TODO Enable this when we have some sort of intro..
    // this.appService.changeState(AppState.Intro);
    this.appService.changeState(AppState.MainMenu);
  }

  update(delta: number): void {
  }

  private handleKeys = (evt: KeyboardEvent): boolean => {
    if (evt.code === 'Space' || evt.code === 'Escape' || evt.code === 'Enter') {
      evt.preventDefault();
      this.exit();
      return false;
    }

    return true;
  }

  private handleMouse = (evt: MouseEvent): boolean => {
    evt.preventDefault();
    this.exit();
    return false;
  }

}
