import * as PIXI from 'pixi.js';
import {convertLayer, EntityLayer} from '@game/entities/map';
import {OutlineFilter} from '@pixi/filter-outline';
import {GlowFilter} from '@pixi/filter-glow';
import {EntityConfig} from '@game/entities/entity';
import {EntityState} from '../../../projects/game/src/services/tick';
import {EffectManager} from '@game/vfx/effect.manager';
import {EntityOptions} from '@game/entities/entity.factory';
import {Vector2d} from '@shared/utils/vector2da';
import {colorForRarity, ColorLegendary, ColorRare, ColorUncommon} from '@game/colors/colors';
import {Rarity} from '../../../projects/game/src/app/hud/shared/stats/rarity';

const INFO_SIGN_OFFSET = {
  x: 0,
  y: -35
};

const NAME_TAG_OFFSET = {
  x: 0,
  y: 45
};

const SELECTION_CIRCLE_COLOR = 0xEEEEEE;
const SELECTION_CIRCLE_FACTOR = 0.6;
const SELECTION_CIRCLE_WIDTH = 4;

export enum Color {
  Deadly = '0xA50000',
  Hard = '0xEE0000',
  Medium = '0xFF4800',
  Easy = '0xED9E00',
  Trivial = '0xA0A0A0',
}

const outlineFilterSelected = new OutlineFilter(5, 0xFFFFFF);
const outlineFilterFoe = new OutlineFilter(2, 0xFF0000);
const outlineFilterNeutral = new OutlineFilter(2, 0xFFFA00);
const outlineFilterFriend = new OutlineFilter(2, 0x00FF00);

export abstract class EntityGameObject extends PIXI.Container {

  protected txtInfo: null | PIXI.Text = null;
  private selected: boolean = false;
  private childContainer: PIXI.Container;

  private circle: null | PIXI.Graphics = null;

  // @ts-ignore
  abstract get angle(): number;

  protected txtNameTag: null | PIXI.Text = null;

  parallaxFactor: number = 0;
  parallaxCenter: Vector2d = {x: 0, y: 0};

  protected constructor(protected cfg: EntityConfig, protected options: EntityOptions) {
    super();

    this.childContainer = new PIXI.Container();
    this.childContainer.zIndex = 10;
    this.addRootChild(this.childContainer);

    // Create the sprites
    this.x = this.cfg.position.x;
    this.y = this.cfg.position.y;

    this.sortableChildren = true;
    this.zIndex = convertLayer(this.cfg.layer);

    this.updateRarityGlow();
    this.updateNameTag();
    this.updateInfoText();
    this.updateInteractions();

    if (this.options.register) {
      EffectManager.instance.registerEntity(this.options.effectPrefix + ':' + cfg.entityId, this);
    }
  }

  destroy(options?: { children?: boolean; texture?: boolean; baseTexture?: boolean }): void {
    super.destroy(options);

    if (this.options.register) {
      EffectManager.instance.removeEntity(this.options.effectPrefix + ':' + this.cfg.entityId);
    }
  }

  addRootChild<TChildren extends PIXI.DisplayObject[]>(...children: TChildren): TChildren[0] {
    return super.addChild(...arguments);
  }

  addChild<TChildren extends PIXI.DisplayObject[]>(...children: TChildren): TChildren[0] {
    return this.childContainer.addChild(...arguments);
  }

  // @ts-ignore
  abstract set angle(x: number);

  update(delta: number): void {

    if (this.parallaxFactor !== 0) {
      // Calculate the updated parallax position if necessary.
      const offset = {
        x: this.parallaxCenter.x - this.cfg.position.x,
        y: this.parallaxCenter.y - this.cfg.position.y,
      }
      this.position.set(
        this.cfg.position.x + (offset.x * this.parallaxFactor),
        this.cfg.position.y + (offset.y * this.parallaxFactor),
      );
    } else {
      this.position.set(this.cfg.position.x, this.cfg.position.y);
    }

    this.angle = this.cfg.position.angle;

    this.updateInfoText();
    this.updateInteractions();
  }

  private onMouseHover = (): void => {
    if (this.cfg.faction === '') {
      this.childContainer.filters = [outlineFilterNeutral];
    } else if (this.cfg.faction === this.options.charRef.getFaction()) {
      this.childContainer.filters = [outlineFilterFriend];
    } else {
      this.childContainer.filters = [outlineFilterFoe];
    }
  }

  private onMouseLeave = (): void => {
    this.childContainer.filters = [];
  }

  private updateInteractions(): void {

    if (this.hasTraits() || this.cfg.isCommander) {

      if (!this.interactive) {
        this.on('pointerover', () => {
          this.onMouseHover();
          if (this.options.onMouseOver) {
            this.options.onMouseOver();
          }
        });
        this.on('pointerout', () => {
          this.onMouseLeave();
          if (this.options.onMouseLeave) {
            this.options.onMouseLeave();
          }
        });

        this.on('mouseup', (evt: any) => {
          if (this.options.onSelect) {
            this.options.onSelect();
          }
        });
        this.on('rightup', (evt: any) => {
          if (this.options.onInteraction) {
            this.options.onInteraction();
          }
        });
      }

      this.interactive = true;
      this.buttonMode = true;

      if (this.cfg.isCommander && this.cfg.faction !== 'ehe') {
        this.cursor = `url('assets/cursors/attack.png'), auto`;
      } else {
        this.cursor = `url('assets/cursors/talk.png'), auto`;
      }
    } else {
      this.off('pointerover');
      this.off('pointerout');
      this.off('mouseup');
      this.off('rightup');

      this.interactive = false;
      this.buttonMode = false;
    }
  }

  public hasTraits(): boolean {
    if (this.cfg.questReceiverState === 'r') {
      return true;
    } else if (this.cfg.questReceiverState === 'n') {
      return true;
    }
    if (this.cfg.isQuestGiver) {
      return true;
    }
    if (this.cfg.isLoreTeller) {
      return true;
    }
    if (this.cfg.isItemTrader) {
      return true;
    }
    if (this.cfg.isHullTrader) {
      return true;
    }
    if (this.cfg.isBridgePillar) {
      return true;
    }
    if (this.cfg.isHangarMechanic) {
      return true;
    }
    if (this.cfg.isDepotManager) {
      return true;
    }

    return false;
  }

  private updateRarityGlow(): void {

    if (this.cfg.rarity === Rarity.Common) {
      return;
    }

    let color = colorForRarity(this.cfg.rarity);

    const glowFilter = new GlowFilter({
      color: color,
      quality: 0.1,
      innerStrength: 0,
      outerStrength: 6,
    });

    this.filters = [glowFilter];
  }

  private updateNameTag(): void {
    if (!this.cfg.name) {
      this.hideNameTag();
      return;
    }

    // Ignore own faction for now.
    // EXTEND Add option to show ALL names.
    const sameFaction = this.cfg.faction === this.options.charRef.getFaction();
    if (!this.selected && sameFaction) {
      this.hideNameTag();
      return
    }

    // Ignore if the name tag already exists.
    if (this.txtNameTag) {
      return;
    }


    let color = '0xFFFFFF';
    if (!sameFaction && this.cfg.isCommander && this.cfg.teamLevel > 0) {
      color = Color.Hard;

      const charGS = this.options.charRef.getTeam().getTeamLevel();
      const gsDiff = this.cfg.teamLevel - charGS;

      if (gsDiff >= 10) {
        color = Color.Deadly;
      } else if (gsDiff >= 5) {
        color = Color.Hard;
      } else if (gsDiff >= -5) {
        color = Color.Medium;
      } else if (gsDiff >= -10) {
        color = Color.Easy;
      } else {
        color = Color.Trivial;
      }
    }

    this.txtNameTag = new PIXI.Text(`${this.cfg.name} (${this.cfg.teamLevel})`, {fontSize: '16px', fill: color});
    this.txtNameTag.x = NAME_TAG_OFFSET.x;
    this.txtNameTag.y = NAME_TAG_OFFSET.y;
    this.txtNameTag.anchor.set(0.5, 0.5);
    this.txtNameTag.zIndex = EntityLayer.HUD;
    this.addRootChild(this.txtNameTag);
  }

  hideNameTag(): void {
    if (!this.txtNameTag) {
      return;
    }
    this.txtNameTag.destroy();
    this.txtNameTag = null;
  }

  private updateInfoText(): void {
    let text = '';
    let color = 'yellow';

    if (this.cfg.questReceiverState === 'r') {
      text = '?';
    } else if (this.cfg.questReceiverState === 'n') {
      text = '?';
      color = '#CCCCCC';
    }
    if (this.cfg.isQuestGiver) {
      text += '!';
    }
    if (this.cfg.isLoreTeller) {
      text += '#';
    }
    if (this.cfg.isItemTrader) {
      text += 'I';
    }
    if (this.cfg.isHullTrader) {
      text += 'H';
    }
    if (this.cfg.isBridgePillar) {
      text += 'P';
    }
    if (this.cfg.isHangarMechanic) {
      text += 'M';
    }
    if (this.cfg.isDepotManager) {
      text += 'D';
    }

    if (this.cfg.state === EntityState.Fighting) {
      text += ' FIGHTING';
    }
    if (this.cfg.state === EntityState.Warping) {
      text += ' WARPING';
    }

    if (text) {

      // Only show this if any of the traits are present and lazy load if to
      // avoid overhead.
      if (!this.txtInfo) {
        this.txtInfo = new PIXI.Text('', {fontSize: '60px', fill: color});
        this.txtInfo.x = INFO_SIGN_OFFSET.x;
        this.txtInfo.y = INFO_SIGN_OFFSET.y;
        this.txtInfo.anchor.set(0.5, 0.5);
        this.txtInfo.zIndex = EntityLayer.HUD;
        this.addRootChild(this.txtInfo);

      } else {
        this.txtInfo.style.fill = color;
      }

      this.txtInfo.text = text;
    } else {
      if (this.txtInfo) {
        this.txtInfo.destroy();
        this.txtInfo = null;
      }
    }
  }

  markSelected(): void {
    if (this.selected) {
      return;
    }
    this.selected = true;

    // Draw a circle around the ship to highlight it.
    this.circle = new PIXI.Graphics();
    const b = this.childContainer.getLocalBounds();
    const r = Math.max(b.height, b.width) * 0.5 * SELECTION_CIRCLE_FACTOR;
    this.circle.beginFill(SELECTION_CIRCLE_COLOR);
    this.circle.zIndex = 0;
    this.circle.drawCircle(0, 0, r);
    this.circle.endFill();
    this.circle.beginHole();
    this.circle.drawCircle(0, 0, r - SELECTION_CIRCLE_WIDTH);
    this.circle.endHole();
    this.addRootChild(this.circle);

    // Update an show the name tag if a name exists.
    this.updateNameTag();
  }

  removeSelected(): void {
    if (!this.selected) {
      return;
    }
    this.selected = false;

    if (!this.circle) {
      return;
    }

    this.circle.destroy();
    this.circle = null;

    // Update and maybe hide the name tag if only added due to selection.
    this.updateNameTag();
  }
}
