import {ItemStackData} from '@game/characters/character';

export enum DragSource {
  Unknown = '',
  Inventory = 'inventory',
  Team = 'team',
  Hangar = 'hangar',
  Trader = 'trader',
}

export interface DragData {
  source: DragSource;
  type: 'ship' | 'item';
  pos?: number;
  slot?: string;
  identifier?: string;
  stack?: ItemStackData;
}
