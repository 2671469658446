import {Team3x3} from '@game/teams/team3x3';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output
} from '@angular/core';
import {Ship} from '@game/ships/ship';
import {InventoryService} from '../../../projects/game/src/services/inventory.service';
import {HudService} from '../../../projects/game/src/app/hud/hud.service';
import {CharacterService} from '../../../projects/game/src/services/character.service';
import {DragSource} from '../../../projects/game/src/app/hud/drag';

@Component({
  selector: 'wg-team3x3',
  templateUrl: './team3x3.component.html',
  styleUrls: ['./team3x3.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Team3x3Component implements OnInit, OnChanges {

  // eslint-disable-next-line @typescript-eslint/naming-convention
  DragSource = DragSource;

  @Input() team: null | Team3x3 = null;

  @Input() selectedPos: null | number = null;
  @Output() selectedPosChange = new EventEmitter<null | number>();

  positions = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  constructor(private inventoryService: InventoryService,
              private characterService: CharacterService,
              private hudService: HudService,
              private cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    if (!this.team) {
      throw new Error('missing team for component');
    }
  }

  ngOnChanges(): void {
    if (!this.team) {
      throw new Error('missing team for component');
    }

    if (this.selectedPos === null || !this.positions.includes(this.selectedPos)) {
      const mapped = this.team.getShips().map((ship, pos) => {
        return {
          ship: ship,
          position: pos + 1,
        };
      });
      const firstShip = mapped.find(s => !!s && !!s.ship);

      if (firstShip) {
        this.selectShip(firstShip.position, firstShip.ship);
      } else {
        console.warn('unable to find ship to select');
      }

      this.team.afterShipsChanged().subscribe(() => {
        setTimeout(() => {
          this.cdr.detectChanges();
        }, 20);
      });
    }
  }

  selectShip(pos: number, ship: Ship): void {
    if (ship) {
      this.selectedPosChange.next(pos);
    }
  }

  onDragOver(evt: DragEvent, to: number): void {
    const drag = this.hudService.getDragData();
    if (!drag) {
      return;
    }

    if (drag.type === 'ship') {

      const from = drag.pos;

      if (from !== to || drag.source !== DragSource.Team) {
        evt.preventDefault();
      }
    }
  }

  onDrop(evt: DragEvent, teamPos: number): void {
    const drag = this.hudService.getDragData();
    if (!drag) {
      return;
    }

    if (drag.type !== 'ship') {
      evt.preventDefault();
      return;
    }

    if (drag.source === DragSource.Team) {

      if (drag.pos === undefined || drag.pos === teamPos) {
        return;
      }

      this.characterService.moveShip(drag.pos, teamPos).then((result) => {}).catch((err) => {
        console.log(err);
      });

    } else if (drag.source === DragSource.Hangar) {

      if (drag.pos === undefined) {
        console.log('cannot exchange - identifier missing')
        return;
      }

      this.characterService.exchangeShip(teamPos, drag.pos).then((result) => {}).catch((err) => {
        console.log(err);
      });

    }
  }

  hidePreview(): void {
    this.hudService.hideTooltip();
  }
}
